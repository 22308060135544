import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { TrainingService } from '../../../services/training.service'
import { ConfirmationService } from 'primeng/api'
import { CaregiverService } from '../../../services/caregivers.service'
import { CaregiverDateService } from '../../../services/caregiver-date.service'
import { HelperService } from '../../../services/helper.service'
import { CaregiverDetailModel } from '../../../models/caregiver/caregiver-detail.model'
import { CaregiverTimeService } from '../../../services/caregiver-time.service'
import { CaregiverTimeDetailResponseModel } from '../../../models/caregiver/caregiver-time-detail-response.model'
import { CaregiverTimeModel } from '../../../models/caregiver/caregiver-time.model'
import { ZipcodeSearchResultModel } from '../../../models/search/zipcode-search-result.model'
import { SearchService } from '../../../services/search.service'
import { CaregiverDatesModel } from '../../../models/caregiver/caregiver-dates.model'

@Component({
  selector: 'app-add-caregiver-date-dialog',
  templateUrl: './add-caregiver-date-dialog.component.html',
})
export class AddCaregiverDateDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false
  private selectedFile: any = null
  private selectedFileFollowUps: any[] = []

  public appointments: any[] = []
  public displayName = ''
  public pastIlls = []

  public dateTypes = [
    { label: 'Urlaub', value: 'VACATION' },
    { label: 'Krankmeldung', value: 'ILL' },
    { label: 'Fortbildung', value: 'TRAINING' },
    { label: 'Wunschfrei', value: 'FREE_TIME_REQUEST' },
    { label: 'Intern', value: 'INTERN' },
    { label: 'KD-Planung folgt', value: 'PLANNING_FOLLOWS' },
    { label: 'Sonstiges', value: 'OTHER' },
  ]

  public options = [
    { label: 'Hilfe-Schulung', value: 'hilfe-schulung', years: 2 },
    { label: 'Hygienebelehrung', value: 'hygienebelehrung', years: 2 },
    {
      label: 'Hygienefolgebelehrung',
      value: 'hygienefolgebelehrung',
      years: 2,
    },
    { label: 'Demenzschulung', value: 'demenzschulung', years: 1 },
    { label: 'Ausbildungsnachweis', value: 'ausbildungsnachweis', years: null },
    {
      label: 'Betreuungskraft nach § 53 c des SGB XI',
      value: 'betreuungskraft-53',
      years: null,
    },
    { label: 'Fachberater für BihG', value: 'fachberater-bihg', years: null },
    { label: 'Sonstige Weiterbildungen', value: 'sonstige', years: null },
  ]

  public optionsIntern = [
    { label: 'Teamsitzung', value: 'teamsitzung' },
    { label: 'Übergabe', value: 'übergabe' },
    { label: 'MA-Gespräch', value: 'ma-gespräch' },
    { label: 'Reifenwechsel', value: 'reifenwechsel' },
    { label: 'Massage', value: 'massage' },
    { label: 'Event', value: 'event' },
    { label: 'Innendiensttätigkeit', value: 'innendiensttätigkeit' },
  ]

  public currentTime: CaregiverTimeModel = new CaregiverTimeModel()
  minDateFromFollowUpIll: any = null

  public caregivers: CaregiverDetailModel[] = []
  public data: any = {}
  public values = {
    caregiver_id: [] as any,
    date_type: '',
    with_time: false,
    follow_up_ill_from: null as any,
    from: '',
    to: '',
    from_time: '',
    to_time: '',
    valid_until: '',
    comment: '',
    type: '',
    valid_unlimited: false,
    child_ill: '',
    address_type: 'OFFICE',
    address_zipcode: '',
    address_city: '',
    address_street_house_number: '',
    follow_up_ills: [] as any,
  }
  public submitted = false
  public hasVacation = false
  public errors: string[] = []
  public hasInternEvent = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private searchService: SearchService,
    private helperService: HelperService,
    private caregiverTimeService: CaregiverTimeService,
    private trainingService: TrainingService,
    private caregiverDateService: CaregiverDateService,
    private eventbus: EventBusService,
    public caregiverService: CaregiverService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)

    this.data = this.config.data

    // Die aktuelle BK wird bereits vorausgewählt
    this.values.caregiver_id.push(+this.data.caregiver_id)

    this.loadCaregiverTimes()

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    // Falls dieser Datensatz ein Folge-AU ist, laden wir den originalen AU-Eintrag.
    if (this.data.date?.follow_up_ill_from) {
      this.data.date.id = this.data.date.follow_up_ill_from
      this.data.date.media = this.data.date.follow_up_ill_relation.media

      this.loadDateById(this.data.date.follow_up_ill_from)
    } else {
      if (this.data.single_type) {
        this.values.date_type = this.data.single_type
        this.values.from = this.data.start_date
        this.displayName = this.data.name
      }

      if (this.data.isEdit || this.data.from_planning) {
        this.values.from = dayjs(this.data.date.from).format('DD.MM.YYYY')
        this.values.to = this.data.date.to
          ? dayjs(this.data.date.to).format('DD.MM.YYYY')
          : ''

        this.values.valid_until = this.data.date.valid_until
          ? dayjs(this.data.date.valid_until).format('DD.MM.YYYY')
          : ''

        this.values.with_time = this.data.date.with_time
        this.values.child_ill = this.data.date.child_ill
        this.values.from_time = this.data.date.from_time
        this.values.to_time = this.data.date.to_time
        this.values.valid_unlimited = this.data.date.valid_unlimited
        this.values.type = this.data.date.type
        this.values.date_type = this.data.date.date_type
        this.values.comment = this.data.date.comment
        this.values.address_type = this.data.date.address_type
        this.values.address_street_house_number = this.data.date.address_street_house_number
        this.values.address_zipcode = this.data.date.address_zipcode
        this.values.address_city = this.data.date.address_city

        // Falls die AU Folge-AU hat.
        if (this.data.date.follow_up_ill_relations?.length) {
          this.values.follow_up_ills = []

          this.data.date.follow_up_ill_relations.forEach((relation: any) => {
            this.values.follow_up_ills.push({
              id: relation.id,
              from: dayjs(relation.from).format('DD.MM.YYYY'),
              to: dayjs(relation.to).format('DD.MM.YYYY'),
              media: relation.media,
            })
          })
        }

        this.loadCaregiverAppointments()
      }

      if (this.data.from_planning && !this.data.date.follow_up_ill_from) {
        this.loadDateById(this.data.date.id)
      }
    }

    this.loadCaregivers()
  }

  public setEndTime(): void {
    const weeks = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ]
    const day = dayjs(this.values.from, 'DD.MM.YYYY').day()

    const week = this.currentTime.days_with_key[weeks[day]]

    if (week) {
      this.values.to_time = week.has_second_block
        ? week.end_second
        : week.end_first
    }
  }

  public loadCaregiverTimes(): void {
    this.caregiverTimeService
      .load(this.data.caregiver_id)
      .subscribe((response: CaregiverTimeDetailResponseModel) => {
        this.currentTime = response.current_time
      })
  }

  public dayFromSelected(event: any): void {
    if (!this.values.to) {
      this.values.to = this.values.from
    }

    this.loadCaregiverAppointments()
  }

  public dayToSelected(event: any): void {
    this.loadCaregiverAppointments()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  private loadDateById(id: any): void {
    this.caregiverDateService.loadById(id).subscribe((response: any) => {
      this.values = response

      this.values.from = dayjs(response.from).format('DD.MM.YYYY')
      this.values.to = response.to
        ? dayjs(response.to).format('DD.MM.YYYY')
        : ''

      // Falls wir den Eintrag aus einer Kopie in der Planung erstellen.
      if (this.data.newDate) {
        this.values.from = this.data.newDate
        this.values.to = this.data.newDate
      }

      // Die Uhrzeit beim Verschieben soll korrekt für "bis" berechnet werden.
      if (this.data.newTime) {
        const from = dayjs('2000-01-01 ' + this.values.from_time)
        const to = dayjs('2000-01-01 ' + this.values.to_time)
        const newTime = dayjs('2000-01-01 ' + this.data.newTime)

        const currentDiff = to.diff(from, 'minutes')

        this.values.from_time = this.data.newTime
        this.values.to_time = newTime
          .add(currentDiff, 'minutes')
          .format('HH:mm')
      }

      // Falls die AU Folge-AU hat.
      if (response.follow_up_ill_relations?.length) {
        this.values.follow_up_ills = []

        response.follow_up_ill_relations.forEach((relation: any) => {
          this.values.follow_up_ills.push({
            id: relation.id,
            from: dayjs(relation.from).format('DD.MM.YYYY'),
            to: dayjs(relation.to).format('DD.MM.YYYY'),
            media: relation.media,
          })
        })
      }

      this.values.valid_until = response.valid_until
        ? dayjs(response.valid_until).format('DD.MM.YYYY')
        : ''
    })
  }

  private loadCaregivers(): void {
    this.helperService.dependencies$.subscribe((data: any) => {
      this.caregivers.push(...data['caregivers_active'])
    })
  }

  public checkForTimes(event: any, type: 'from_time' | 'to_time'): void {
    const value = event.target.value

    if (value.length === 1) {
      this.values[type] = `0${value}:00`
    } else if (value.length === 2) {
      this.values[type] = `${value}:00`
    } else if (value.length === 4) {
      this.values[type] = `${value[0]}${value[1]}:${value[2]}${value[3]}`
    }
  }

  public setSelectedFile(event: any): any {
    this.selectedFile = event.target.files[0]
  }

  public setSelectedFileFollowUps(event: any, index: number): any {
    this.selectedFileFollowUps[index] = event.target.files[0]
  }

  /**
   * Löscht die Datumseinträge, sobald Date-Type geändert wird.
   * Hat den Hintergrund, dass es zu Fehlern kommen kann, wenn
   * die Datumsfelder eine Uhrzeit haben (wenn z.B. von Urlaub auf Fortbildung gewechselt wird).
   */
  public dateTypeChanged(): void {
    this.values.with_time = false
    this.values.from = ''
    this.values.to = ''
    this.values.valid_until = ''

    // Für Intern und Fortbildung brauchen wir eine Uhrzeit als Pflicht.
    if (
      this.values.date_type === 'TRAINING' ||
      this.values.date_type === 'INTERN' ||
      this.values.date_type === 'PLANNING_FOLLOWS'
    ) {
      this.values.with_time = true
    }

    this.setTabIndexForIconButtons()
  }

  // Dadurch verhindern wir, dass die Icons in den
  // Kalender-Items über Tab ausgewählt werden.
  private setTabIndexForIconButtons(): void {
    setTimeout(() => {
      const elements = document.querySelectorAll('.p-button-icon-only')

      elements.forEach((element) => {
        element.setAttribute('tabindex', '-1')
      })
    }, 200)
  }

  /**
   * Setzt automatisch ein Gültigkeitsdatum für den Typ.
   */
  public setValidUntil(): void {
    if (this.values.date_type === 'TRAINING') {
      const option = this.options.find(
        (item: any) => item.value === this.values.type
      )

      if (!option) {
        return
      }

      if (option.years) {
        this.values.valid_unlimited = false

        // Ohne das Timeout kommt es irgendwie zu einem Fehler...
        // Uncaught (in promise): Missing number at position 0
        setTimeout(() => {
          this.values.valid_until = dayjs
            .utc(this.values.from, 'DD.MM.YYYY, HH:mm')
            .add(option.years, 'years')
            .format('DD.MM.YYYY')
        }, 100)

        return
      }

      this.values.valid_until = ''
      this.values.valid_unlimited = true
    }
  }

  public closeDialog(): void {
    this.ref.close()
  }

  private loadCaregiverAppointments2(): void {
    this.appointments = []

    if (this.values.from && this.values.to) {
      this.caregiverService
        .loadPatientsForDateRange(
          this.data.caregiver_id,
          this.values.from,
          this.values.to
        )
        .subscribe((response: any[]) => {
          this.appointments = response
        })
    }
  }

  private loadCaregiverAppointments(): void {
    this.appointments = []

    if (this.values.from && this.values.to) {
      this.caregiverService
        .loadPatientsForMultipleDateRanges(
          this.data.caregiver_id,
          this.collectAllDateRanges()
        )
        .subscribe((response: any[]) => {
          this.appointments = response
        })
    }
  }

  private collectAllDateRanges(): Array<{ from: string; to: string }> {
    const dateRanges: Array<{ from: string; to: string }> = []

    dateRanges.push({
      from: this.values.from,
      to: this.values.to,
    })

    // Zeitspannen der Folge-Krankmeldungen hinzufügen
    if (this.values.follow_up_ills?.length > 0) {
      for (const followUp of this.values.follow_up_ills) {
        if (followUp.from && followUp.to) {
          dateRanges.push({
            from: followUp.from,
            to: followUp.to,
          })
        }
      }
    }

    return dateRanges
  }

  /**
   * Lädt eine Datei hoch.
   */
  public upload(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    if (!this.validateDates()) {
      return
    }

    this.submitted = true

    const formData = new FormData()
    formData.append('file', this.selectedFile)
    formData.append('from', this.values.from)
    formData.append('to', this.values.to)
    formData.append('comment', this.values.comment || '')
    formData.append('type', this.values.type || '')
    formData.append('date_type', this.values.date_type)
    formData.append('valid_until', this.values.valid_until)
    formData.append('caregiver_id', this.values.caregiver_id)
    formData.append('valid_unlimited', this.values.valid_unlimited.toString())
    formData.append('with_time', this.values.with_time.toString())
    formData.append('from_time', this.values.from_time)
    formData.append('to_time', this.values.to_time)
    formData.append('child_ill', this.values.child_ill)
    formData.append('address_zipcode', this.values.address_zipcode)
    formData.append('address_city', this.values.address_city)
    formData.append('address_type', this.values.address_type)
    formData.append(
      'address_street_house_number',
      this.values.address_street_house_number
    )
    formData.append(
      'follow_up_ills',
      JSON.stringify(this.values.follow_up_ills)
    )

    this.selectedFileFollowUps.forEach((file: any, index: any) => {
      formData.append(`follow_up_ills_files_${index}`, file)
    })

    const subscription = this.data.isEdit
      ? this.caregiverDateService.update(this.data.date.id, formData)
      : this.caregiverDateService.upload(this.data.caregiver_id, formData)

    subscription.subscribe(
      (response: any) => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.CaregiverDateChanged)
        this.toastService.success(
          'Termin gespeichert',
          'Der Termin wurde erfolgreich gespeichert'
        )

        if (response.has_vacation || response.has_intern_event) {
          this.hasVacation = response.has_vacation
          this.hasInternEvent = response.has_intern_event
        } else {
          this.ref.close(response.date.id)
        }
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else if (error.status === StatusCodes.CONFLICT) {
          this.toastService.error(
            'AU bereits vorhanden',
            'Es ist bereits eine AU im angegebenen Zeitraum vorhanden'
          )
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public loadCity(): void {
    this.searchService.findCity(this.values.address_zipcode).subscribe(
      (result: ZipcodeSearchResultModel) => {
        if (result) {
          this.values.address_city = result.city
        }
      },
      () => {
        this.values.address_city = ''
      }
    )
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Termin wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        this.caregiverDateService.delete(this.data.date.id).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.CaregiverDateChanged)
            this.ref.close(this.data.date.id)
            this.toastService.success(
              'Termin gelöscht',
              'Der Termin wurde erfolgreich gelöscht'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Löschen fehlgeschlagen',
              'Der Eintrag konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }

  validateDates() {
    this.errors = []
    let isValid = true
    const dates = []

    // Hauptdaten hinzufügen
    if (this.values.from && this.values.to) {
      const fromDate = dayjs(this.values.from, 'DD.MM.YYYY')
      const toDate = dayjs(this.values.to, 'DD.MM.YYYY')

      // Prüfen, ob das Startdatum vor dem Enddatum liegt
      if (fromDate.isAfter(toDate)) {
        this.errors.push('Das Startdatum muss vor dem Enddatum liegen.')

        isValid = false
      }

      dates.push({ from: fromDate, to: toDate })
    }

    // Folge-Krankmeldungen überprüfen
    if (this.values.follow_up_ills?.length > 0) {
      for (let i = 0; i < this.values.follow_up_ills.length; i++) {
        const followUp = this.values.follow_up_ills[i]

        if (followUp.from && followUp.to) {
          const followFromDate = dayjs(followUp.from, 'DD.MM.YYYY')
          const followToDate = dayjs(followUp.to, 'DD.MM.YYYY')

          // Prüfen, ob das Startdatum vor dem Enddatum liegt
          if (followFromDate.isAfter(followToDate)) {
            this.errors.push(
              `Folge-Krankmeldung ${
                i + 1
              }: Das Startdatum muss vor dem Enddatum liegen.`
            )

            isValid = false
          }

          // Prüfen, ob die Folge-Krankmeldung nach der vorherigen Krankmeldung liegt
          if (dates.length > 0) {
            const prevDate = dates[dates.length - 1].to

            if (
              followFromDate.isSame(prevDate) ||
              followFromDate.isBefore(prevDate)
            ) {
              this.errors.push(
                `Folge-Krankmeldung ${
                  i + 1
                }: Das Startdatum muss nach dem Enddatum der vorherigen Krankmeldung liegen.`
              )

              isValid = false
            }
          }

          dates.push({ from: followFromDate, to: followToDate })
        }
      }
    }

    return isValid
  }

  removeIllPosition(ill: any, index: number): void {
    if (
      window.confirm(
        'Folge-AU wirklich löschen? Der Eintrag wird direkt entfernt!'
      )
    ) {
      delete this.selectedFileFollowUps[index]

      this.values.follow_up_ills = this.values.follow_up_ills.filter(
        (item: any) => item != ill
      )

      if (ill.id) {
        this.caregiverDateService.delete(ill.id).subscribe(
          () => {
            this.eventbus.emit(GlobalEvent.CaregiverDateChanged)
            this.toastService.success(
              'Termin gelöscht',
              'Der Termin wurde erfolgreich gelöscht'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Löschen fehlgeschlagen',
              'Der Eintrag konnte nicht gelöscht werden'
            )
          }
        )
      }

      this.loadCaregiverAppointments()
    }
  }

  addIllPosition() {
    // Falls bereits Einträge vorhanden sind, dass neue Startdatum auf den nächsten Tag nach dem letzten Enddatum setzen
    if (this.values.follow_up_ills?.length > 0) {
      const lastEntry = this.values.follow_up_ills[
        this.values.follow_up_ills.length - 1
      ]
      if (lastEntry.to) {
        const lastEndDate = dayjs(lastEntry.to, 'DD.MM.YYYY')
        const newStartDate = lastEndDate.add(1, 'day')

        this.values.follow_up_ills.push({
          from: newStartDate.format('DD.MM.YYYY'),
          to: newStartDate.format('DD.MM.YYYY'),
        })
      } else {
        this.values.follow_up_ills.push({
          from: '',
          to: '',
        })
      }
    } else {
      // Falls keine Folge-Krankmeldungen vorhanden sind, aber ein Hauptdatum
      const mainEndDate = dayjs(this.values.to, 'DD.MM.YYYY')

      const newStartDate = mainEndDate.add(1, 'day')

      this.values.follow_up_ills.push({
        from: newStartDate.format('DD.MM.YYYY'),
        to: newStartDate.format('DD.MM.YYYY'),
      })
    }

    this.loadCaregiverAppointments()
    this.setTabIndexForIconButtons()
  }
}
