import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { ToastService } from 'src/app/services/toast.service'
import { DirtyComponent } from 'src/app/guards/dirty-check.guard'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { NgForm } from '@angular/forms'
import { ConfirmationService } from 'primeng/api'
import { TitleService } from 'src/app/services/title.service'
import { UserSystemService } from '../../services/user-system.service'
import { RolePermissionService } from '../../services/role-permission.service'
import { HelperService } from '../../services/helper.service'
import { AuthService } from '../../services/auth.service'
import { AuthSettingModel } from '../../models/user/auth.model'
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit, DirtyComponent {
  @ViewChild('form', { static: true }) form!: NgForm
  @ViewChild('formPassword', { static: true }) formPassword!: NgForm

  public isDirty = false
  public submitted = false
  public loading = true
  public has2faEnabled = false
  public generated2faQrCode: any = ''
  public formOfAddress: any = []
  public errorMessage = ''
  public errorMessage2fa = ''
  public codeFor2fa = ''

  public data: AuthSettingModel = new AuthSettingModel()

  constructor(
    private userSystemService: UserSystemService,
    private router: Router,
    private rolePermissionService: RolePermissionService,
    private route: ActivatedRoute,
    private helperService: HelperService,
    public authService: AuthService,
    private sanitizer: DomSanitizer,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.setData()

    this.titleService.setTitle('Einstellungen')

    this.form.valueChanges?.subscribe(() => {
      // sonst wird isDirty durch das initiale rendern der form gesetzt
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.helperService.dependencies$.subscribe((data: any) => {
      this.formOfAddress = data.form_of_address
    })
  }

  canDeactivate(): boolean {
    return this.isDirty
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')

      return
    }

    this.submitted = true

    this.userSystemService.updateOwnData(this.data.user_system).subscribe(
      () => {
        this.isDirty = false
        this.submitted = false

        this.toastService.success('Daten erfolgreich gespeichert')
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.submitted = false
      }
    )
  }

  public savePassword(): void {
    if (!this.formPassword.form.valid) {
      this.submitted = false
      this.formPassword.form.markAllAsTouched()
      this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')

      return
    }

    if (this.data.password !== this.data.password_confirmation) {
      this.submitted = false
      this.formPassword.form.markAllAsTouched()
      this.errorMessage =
        'Das neue Passwort stimmt nicht mit der bestätigung überein'

      return
    }

    this.errorMessage = ''
    this.submitted = true

    this.userSystemService.updateOwnPassword(this.data).subscribe(
      () => {
        this.isDirty = false
        this.submitted = false

        this.toastService.success('Passwort erfolgreich gespeichert')

        location.reload()
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.errorMessage =
            'Bitte prüfen Sie ob ihr aktuelles Passwort korrekt ist und ob das neue Passwort die gewünschten Regeln erfüllt'
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.submitted = false
      }
    )
  }

  private setData(): void {
    const user = this.authService.getUser()

    if (user) {
      this.data.caregiver = user.caregiver
      this.data.user_system = user.user_system
      this.data.name = user.name
      this.data.email = user.email
      this.data.is_user_system = user.is_user_system
      this.data.is_caregiver = user.is_caregiver
      this.data.is_multiplier = user.is_multiplier

      // Bei einem Systembenutzer laden wir noch die Daten für 2FA.
      if (user.user_system) {
        this.loading = true

        this.authService.get2faEnabled().subscribe((response: any) => {
          this.has2faEnabled = response.has_2fa_enabled

          this.loading = false
        })
      } else {
        this.loading = false
      }
    }
  }

  disable2fa(): void {
    if (!window.confirm('2FA wirklich deaktivieren?')) {
      return
    }

    this.submitted = true

    this.authService.disable2fa().subscribe((response: any) => {
      location.reload()
    })
  }

  prepare2fa(): void {
    this.submitted = true

    this.authService.prepare2fa().subscribe((response: any) => {
      this.generated2faQrCode = this.sanitizer.bypassSecurityTrustHtml(
        response.qr_code
      )

      this.submitted = false
    })
  }

  confirm2fa(): void {
    this.submitted = true

    this.authService.confirm2fa(this.codeFor2fa).subscribe(
      (response: any) => {
        location.reload()
      },
      (error: any) => {
        this.submitted = false
        this.errorMessage2fa = 'Ungültiger Code.'
      }
    )
  }
}
