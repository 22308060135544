<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-6">
        <label for="since">Datum *</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          [maxDate]="currentDate"
          inputId="since"
          name="since"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.since"
          dataType="string"
          [showIcon]="true"
          [required]="true"
          #date="ngModel"
          [showButtonBar]="true"
          [class.p-invalid]="date.invalid && (date.dirty || date.touched)"
        ></p-calendar>
        <small *ngIf="car.current_mileage"
          >Letzter Stand:
          {{ car.current_mileage.since | date: "dd.MM.y" }}</small
        >
      </div>

      <div class="p-field p-col-6">
        <label for="mileage">Kilometerstand *</label>
        <p-inputNumber
          inputId="mileage"
          name="mileage"
          [min]="0"
          locale="de-DE"
          [(ngModel)]="values.mileage"
          [required]="true"
          #mileage="ngModel"
          [class.p-invalid]="
            mileage.invalid && (mileage.dirty || mileage.touched)
          "
        ></p-inputNumber>
        <small *ngIf="car.current_mileage"
          >Letzter Stand: {{ car.current_mileage.mileage | number }} KM</small
        >
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="!data.from_caregiver"
    >
      <div class="p-field p-col-12">
        <label for="comment">Bemerkung</label>
        <textarea
          id="comment"
          name="comment"
          [(ngModel)]="values.comment"
          [rows]="3"
          pInputTextarea
        ></textarea>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!data.isEdit"></i>

    <button
      (click)="store()"
      pButton
      [label]="data.isEdit ? 'Speichern' : 'Hinzufügen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
