import { Component, OnDestroy, OnInit } from '@angular/core'
import { ToastService } from '../../../services/toast.service'
import { TitleService } from '../../../services/title.service'
import { UserCaregiverService } from '../../../services/user-caregiver.service'
import { AddDamageDialogComponent } from '../../../components/dialogs/add-damage-dialog/add-damage-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { AddCarMileageDialogComponent } from '../../../components/dialogs/add-car-mileage-dialog/add-car-mileage-dialog.component'

@Component({
  selector: 'app-user-caregiver-cars',
  templateUrl: './user-caregiver-cars.component.html',
})
export class UserCaregiverCarsComponent implements OnInit, OnDestroy {
  public loading = true

  public data: any[] = []
  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    public userCaregiverService: UserCaregiverService,
    private toastService: ToastService,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    document.body.classList.remove('mobile-nav-visible')

    this.titleService.setTitle(`PKW`)

    this.loadData()

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.DamageChanged:
          case GlobalEvent.CarDetailReload:
            this.loadData(false)
        }
      }
    )
  }

  public openAddCarMileageDialog(): void {
    this.dialogService.open(AddCarMileageDialogComponent, {
      header: 'Kilometerstand ändern',
      width: '420px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        isEdit: false,
        from_caregiver: true,
      },
    })
  }

  public openDamageDialog(): void {
    this.dialogService.open(AddDamageDialogComponent, {
      header: 'Neuer PKW-Schaden',
      width: '450px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        isEdit: false,
        from_caregiver: true,
      },
    })
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.userCaregiverService.loadCarHistories().subscribe((data: any[]) => {
      this.data = data

      this.loading = false
    })
  }
}
