import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { ToastService } from 'src/app/services/toast.service'
import { DirtyComponent } from 'src/app/guards/dirty-check.guard'
import { SearchService } from 'src/app/services/search.service'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { NgForm } from '@angular/forms'
import { CarService } from '../../../services/car.service'
import { CarFormModel } from '../../../models/car/car-form.model'
import { TitleService } from 'src/app/services/title.service'
import { ConfirmationService } from 'primeng/api'
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'app-cars-form',
  templateUrl: './cars-form.component.html',
  styleUrls: ['./cars-form.component.scss'],
})
export class CarsFormComponent implements OnInit, DirtyComponent {
  @ViewChild('form', { static: true }) form!: NgForm

  public isDirty = false
  public isEditMode = false
  public carId: string | null = null
  public submitted = false
  public loading = false

  public data: CarFormModel = new CarFormModel()

  constructor(
    public authService: AuthService,
    private service: CarService,
    private searchService: SearchService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.loadDataForEditMode()
    this.titleService.setTitle(this.isEditMode ? 'PKW bearbeiten' : 'Neuer PKW')

    this.form.valueChanges?.subscribe(() => {
      // sonst wird isDirty durch das initiale rendern der form gesetzt
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  canDeactivate(): boolean {
    return this.isDirty
  }

  /**
   * Erstellt bzw. updated die Daten.
   */
  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')

      return
    }

    this.submitted = true

    const subscription = this.isEditMode
      ? this.service.update(this.data)
      : this.service.store(this.data)

    subscription.subscribe(
      (data: CarFormModel) => {
        this.isDirty = false
        this.router.navigate(['/cars', data.id])
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.submitted = false
      }
    )
  }

  /**
   * Deaktiviert einen PKW.
   */
  public deactivate(event: Event): void {
    // TODO: Hier muss überprüft werden, ob der PKW noch "aktiv" einer BK zugewiesen ist.
    // if (!this.data.employed_until_date_string) {
    //   return alert(
    //     'Bitte vorher "Angestellt bis" ausfüllen und Änderungen speichern! Erst dann kann die Betreuungskraft deaktiviert werden!'
    //   )
    // }

    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'PKW wirklich deaktivieren?',
      accept: () => {
        this.service.deactivate(this.carId).subscribe(
          () => {
            this.toastService.success(
              'PKW deaktiviert',
              'Der PKW wurde erfolgreich deaktiviert'
            )

            this.router.navigateByUrl(`/cars/${this.carId}`)
          },
          () => {
            this.toastService.error(
              'Deaktivieren fehlgeschlagen',
              'Der PKW konnte nicht deaktiviert werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }

  /**
   * Aktiviert einen PKW.
   */
  public activate(event: Event): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'PKW wirklich aktivieren?',
      accept: () => {
        this.service.activate(this.carId).subscribe(
          () => {
            this.toastService.success(
              'PKW aktiviert',
              'Der PKW wurde erfolgreich aktiviert'
            )

            this.router.navigateByUrl(`/cars/${this.carId}`)
          },
          () => {
            this.toastService.error(
              'Aktivieren fehlgeschlagen',
              'Der PKW konnte nicht aktiviert werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }

  /**
   * Lädt die PKW Daten.
   */
  private loadCar(): void {
    this.service.loadForEditMode(this.carId).subscribe((data: CarFormModel) => {
      this.data = data

      this.loading = false
    })
  }

  /**
   * Lädt die Daten falls wir im bearbeiten Modus sind.
   */
  private loadDataForEditMode(): void {
    this.loading = true

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.carId = params.get('id')
      this.isEditMode = params.has('id')

      if (this.isEditMode) {
        this.loadCar()
      } else {
        this.loading = false
      }
    })
  }
}
