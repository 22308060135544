import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../../services/auth.service'
import { Router } from '@angular/router'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { ToastService } from '../../../services/toast.service'
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  public isProd = true
  public isLocal = false
  public isStaging = false
  public isDemo = false
  public systemType = ''
  public show2faForm = false
  public challengeToken = ''

  public submitted = false
  public errorMessage = ''
  public token = ''
  public data = {
    email: '',
    password: '',
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.isProd = environment.env === 'production'
    this.isStaging = environment.env === 'staging'
    this.isDemo = environment.env === 'demo'
    this.isLocal = environment.env === 'local'

    if (this.isStaging) {
      this.systemType = 'Test'
    }

    if (this.isDemo) {
      this.systemType = 'Demo'
    }

    if (this.isLocal) {
      this.systemType = 'Lokal'
    }
  }

  public loginWith2fa(): void {
    this.submitted = true
    this.errorMessage = ''
    this.authService.loginWith2fa(this.token, this.challengeToken).subscribe(
      () => {
        location.reload()
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          this.errorMessage = error.error.message
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.submitted = false
      }
    )
  }

  public login(): void {
    this.submitted = true
    this.errorMessage = ''
    this.authService.login(this.data.email, this.data.password).subscribe(
      (response: any) => {
        if (response.requires_2fa) {
          this.submitted = false
          this.show2faForm = true
          this.challengeToken = response.challenge_token
        } else {
          location.reload()
        }
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          this.errorMessage = 'Falsche Kennung oder Passwort'
        } else if (error.status === StatusCodes.TOO_MANY_REQUESTS) {
          this.errorMessage =
            'Zu viele Anmeldeversuche. Bitte versuchen Sie es später erneut.'
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }

        this.submitted = false
      }
    )
  }
}
