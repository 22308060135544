import { HistoryListModel } from '../history/history-list.model'
import { PhoneCallsListItemModel } from '../phone-call/phone-calls-list.model'
import { PatientModel } from '../customer-patient/patient.model'
import { TodoModel } from '../todo/todo.model'
import { VacationIllModel } from '../vacation-ill/vacation-ill.model'
import { CaregiverDetailModel } from '../caregiver/caregiver-detail.model'
import { UserSystemModel } from '../user/user-system.model'

export class DashboardModel {
  important_histories: HistoryListModel[] = []
  phone_calls: PhoneCallsListItemModel[] = []
  birthdays: PatientModel[] | CaregiverDetailModel[] | UserSystemModel[] = []
  todos: TodoModel[] = []
  new_created: PatientModel[] = []
  pause_ending: PatientModel[] = []
  vacation_ill: VacationIllModel[] = []
  price_changes: any

  birthdays_weekly: any[] = []
  accounting_returns: any = {}
  budgets: any = {}
  car_without_assignment: any[] = []
  ills: any = {}
  billable_hours: any = {}
  utilisation: any = {}
  ills_table: any[] = []
  consultings: any = {}
  invoices: any = {}
}
