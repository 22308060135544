<p-confirmPopup></p-confirmPopup>
<app-loader [loading]="loading"></app-loader>

<div class="content-actions limit-width" *ngIf="!loading">
  <h2>
    <i class="tio">heart_outlined</i>
    {{
      isEditMode
        ? "Betreuungskraft bearbeiten"
        : "Neue Betreuungskraft (Bewerber)"
    }}
  </h2>
  <button
    *ngIf="isEditMode && data.from_campaign && authService.isSuperAdmin()"
    pButton
    (click)="delete($event)"
    label="Löschen"
    type="button"
    icon="pi pi-times"
    class="p-button-danger"
  ></button>
</div>

<form #form="ngForm" class="content-offer-container">
  <div class="content-offer-forms limit-width" *ngIf="!loading">
    <div class="content new-content-container limit-width">
      <header class="content-header">
        <span>
          Stammdaten

          <p-checkbox
            *ngIf="isEditMode && data.current_status.status === 'EMPLOYMENT'"
            label="Für Freie Kapazität berücksichtigen"
            name="consider_capacity"
            [binary]="true"
            [(ngModel)]="data.consider_capacity"
          ></p-checkbox>

          <p-checkbox
            *ngIf="isEditMode && data.current_status.status === 'EMPLOYMENT'"
            label="Als Referent für Events"
            name="for_lecturing"
            [binary]="true"
            [(ngModel)]="data.for_lecturing"
          ></p-checkbox>

          <p-checkbox
            *ngIf="isEditMode && data.current_status.status === 'EMPLOYMENT'"
            [label]="
              data.form_of_address.includes('Herr') ? 'Mentor' : 'Mentorin'
            "
            name="is_mentor"
            [binary]="true"
            [(ngModel)]="data.is_mentor"
          ></p-checkbox>
        </span>
      </header>

      <div class="content-body">
        <div
          class="p-fluid p-formgrid p-grid"
          *ngIf="isEditMode && data.current_status.status === 'EMPLOYMENT'"
        >
          <div class="p-field p-col-3">
            <label for="planner_id">Betreut durch</label>
            <p-dropdown
              [showClear]="true"
              name="planner_id"
              inputId="planner_id"
              placeholder="Bitte auswählen"
              [(ngModel)]="data.planner_id"
              [options]="plannerUsers"
              optionValue="id"
              optionLabel="full_name"
              scrollHeight="300px"
            ></p-dropdown>
          </div>

          <div class="p-field p-col-3">
            <label for="shortcut">Kürzel</label>
            <input
              id="shortcut"
              name="shortcut"
              [(ngModel)]="data.shortcut"
              type="text"
              pInputText
            />
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-3">
            <label for="form_of_address">Anrede *</label>
            <p-dropdown
              [(ngModel)]="data.form_of_address"
              [options]="formOfAddress"
              #form_of_address="ngModel"
              inputId="form_of_address"
              name="form-of-address"
              placeholder="Bitte auswählen"
              [required]="true"
            ></p-dropdown>
            <div
              *ngIf="
                form_of_address.invalid &&
                (form_of_address.dirty || form_of_address.touched)
              "
              class="p-invalid"
            >
              <div *ngIf="form_of_address.errors?.required">
                <small>Bitte wählen Sie eine Anrede</small>
              </div>
            </div>
          </div>

          <div class="p-field p-col-3">
            <label for="first-name">Vorname *</label>
            <input
              id="first-name"
              name="first-name"
              [(ngModel)]="data.first_name"
              type="text"
              pInputText
              #firstname="ngModel"
              required
              [class.p-invalid]="
                firstname.invalid && (firstname.dirty || firstname.touched)
              "
            />
            <div
              *ngIf="
                firstname.invalid && (firstname.dirty || firstname.touched)
              "
              class="p-invalid"
            >
              <div *ngIf="firstname.errors?.required">
                <small>Bitte geben Sie einen Vornamen ein</small>
              </div>
            </div>
          </div>

          <div class="p-field p-col-3">
            <label for="last-name">Nachname *</label>
            <input
              id="last-name"
              name="last-name"
              [(ngModel)]="data.last_name"
              type="text"
              pInputText
              #lastname="ngModel"
              required
              [class.p-invalid]="
                lastname.invalid && (lastname.dirty || lastname.touched)
              "
            />
            <div
              *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)"
              class="p-invalid"
            >
              <div *ngIf="lastname.errors?.required">
                <small>Bitte geben Sie einen Nachnamen ein</small>
              </div>
            </div>
          </div>
          <div class="p-field p-col-3">
            <label for="birth_name">Geburtsname</label>
            <input
              id="birth_name"
              name="birth_name"
              [(ngModel)]="data.birth_name"
              type="text"
              pInputText
            />
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-3">
            <label for="bday">Geburtsdatum</label>
            <p-calendar
              [firstDayOfWeek]="1"
              inputId="bday"
              [showButtonBar]="true"
              name="bday"
              [(ngModel)]="data.birthday_date_string"
              dateFormat="dd.mm.yy"
              [showIcon]="true"
              dataType="string"
            ></p-calendar>
          </div>

          <div class="p-field p-col-3">
            <label for="birthplace">Geburtsort</label>
            <input
              id="birthplace"
              name="birthplace"
              [(ngModel)]="data.birthplace"
              type="text"
              pInputText
            />
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-4">
            <label for="address">Straße / Hausnummer</label>
            <input
              id="address"
              name="address"
              [(ngModel)]="data.street_house_number"
              type="text"
              pInputText
            />
          </div>

          <div class="p-field p-col-4">
            <label for="zipcode">PLZ</label>
            <input
              id="zipcode"
              [(ngModel)]="data.zipcode"
              (change)="loadCity()"
              type="text"
              name="zipcode"
              pInputText
            />
          </div>

          <div class="p-field p-col-4">
            <label for="city">Ort</label>
            <span class="p-input-icon-right">
              <i *ngIf="cityLoading" class="pi pi-spin pi-spinner"></i>
              <input
                id="city"
                name="city"
                [(ngModel)]="data.city"
                type="text"
                pInputText
                readonly
              />
            </span>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-4">
            <label for="city_part">Ortsteil</label>
            <input
              id="city_part"
              name="city_part"
              [(ngModel)]="data.city_part"
              type="text"
              pInputText
            />
          </div>

          <div class="p-field p-col-4">
            <label for="county">Landkreis</label>
            <input
              id="county"
              name="county"
              [(ngModel)]="data.county"
              type="text"
              pInputText
              readonly
            />
          </div>

          <div class="p-field p-col-4">
            <label for="state">Bundesland</label>
            <input
              id="state"
              name="state"
              [(ngModel)]="data.state"
              type="text"
              pInputText
              readonly
            />
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-3">
            <div class="p-field">
              <label for="phone1">Telefon 1</label>

              <input
                id="phone1"
                name="phone1"
                [(ngModel)]="data.phone_1"
                type="text"
                pInputText
              />
            </div>
            <div class="p-field" style="margin-top: -10px; margin-bottom: 0">
              <input
                id="phone1_info"
                name="phone1_info"
                placeholder="Informationen zu Telefon 1"
                [(ngModel)]="data.phone_1_info"
                type="text"
                pInputText
              />
            </div>
          </div>

          <div class="p-field p-col-3">
            <div class="p-field">
              <label for="phone2">Telefon 2</label>
              <input
                id="phone2"
                name="phone2"
                [(ngModel)]="data.phone_2"
                type="text"
                pInputText
              />
            </div>
            <div class="p-field" style="margin-top: -10px; margin-bottom: 0">
              <input
                id="phone2_info"
                name="phone2_info"
                placeholder="Informationen zu Telefon 2"
                [(ngModel)]="data.phone_2_info"
                type="text"
                pInputText
              />
            </div>
          </div>

          <div class="p-field p-col-3">
            <div class="p-field">
              <label for="phone3">Telefon 3</label>
              <input
                id="phone3"
                name="phone3"
                [(ngModel)]="data.phone_3"
                type="text"
                pInputText
              />
            </div>
            <div class="p-field" style="margin-top: -10px; margin-bottom: 0">
              <input
                id="phone3_info"
                name="phone3_info"
                placeholder="Informationen zu Telefon 3"
                [(ngModel)]="data.phone_3_info"
                type="text"
                pInputText
              />
            </div>
          </div>

          <div class="p-field p-col-3">
            <div class="p-field">
              <label for="email">E-Mail</label>
              <input
                id="email"
                name="email"
                [(ngModel)]="data.email"
                type="text"
                pInputText
                #email="ngModel"
                email
              />
            </div>
            <div class="p-field" style="margin-top: -10px; margin-bottom: 0">
              <input
                id="email_info"
                name="email_info"
                placeholder="Informationen zur E-Mail"
                [(ngModel)]="data.email_info"
                type="text"
                pInputText
              />

              <div
                *ngIf="email.invalid && (email.dirty || email.touched)"
                class="p-invalid"
              >
                <div *ngIf="email.errors?.email">
                  <small>Keine richtige E-Mail</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-3">
            <label for="driving_license_since_string">Führerschein seit?</label>
            <p-calendar
              [firstDayOfWeek]="1"
              [showWeek]="true"
              inputId="driving_license_since_string"
              name="driving_license_since_string"
              [showButtonBar]="true"
              [(ngModel)]="data.driving_license_since_string"
              dateFormat="dd.mm.yy"
              [showIcon]="true"
              dataType="string"
              #driving_license_since_string="ngModel"
              [class.p-invalid]="
                driving_license_since_string.invalid &&
                (driving_license_since_string.dirty ||
                  driving_license_since_string.touched)
              "
            ></p-calendar>
            <div
              *ngIf="
                driving_license_since_string.invalid &&
                (driving_license_since_string.dirty ||
                  driving_license_since_string.touched)
              "
              class="p-invalid"
            >
              <div *ngIf="driving_license_since_string.errors?.required">
                <small>Bitte geben Sie ein Datum ein</small>
              </div>
            </div>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-4">
            <label>Aktuelle berufliche Situation</label>
            <div class="p-formgroup-inline">
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Beschäftigt"
                  name="current_job_situation"
                  [(ngModel)]="data.current_job_situation"
                  value="EMPLOYED"
                ></p-radioButton>
              </div>
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Arbeitssuchend"
                  name="current_job_situation"
                  [(ngModel)]="data.current_job_situation"
                  value="JOBSEEKER"
                ></p-radioButton>
              </div>
            </div>
          </div>

          <div
            class="p-field p-col-5"
            *ngIf="data.current_job_situation == 'JOBSEEKER'"
          >
            <label for="jobcenter_number"
              >Kundennummer bei der Bundesagentur für Arbeit</label
            >
            <input
              id="jobcenter_number"
              name="jobcenter_number"
              [(ngModel)]="data.jobcenter_number"
              type="text"
              pInputText
            />
          </div>

          <div
            class="p-field p-col-3"
            *ngIf="data.current_job_situation == 'EMPLOYED'"
          >
            <label for="old_employment_until_string">Beschäftigt bis</label>
            <p-calendar
              [firstDayOfWeek]="1"
              [showWeek]="true"
              inputId="old_employment_until_string"
              name="old_employment_until_string"
              [showButtonBar]="true"
              [(ngModel)]="data.old_employment_until_string"
              dateFormat="dd.mm.yy"
              [showIcon]="true"
              dataType="string"
              [disabled]="data.old_employment_unlimited"
            ></p-calendar>
          </div>
          <div
            class="p-field p-col-2 align-bottom"
            *ngIf="data.current_job_situation == 'EMPLOYED'"
          >
            <div class="p-formgroup-inline">
              <div class="p-field-checkbox">
                <p-checkbox
                  label="Unbefristet"
                  [binary]="true"
                  [(ngModel)]="data.old_employment_unlimited"
                  inputId="old_employment_unlimited"
                  name="old_employment_unlimited"
                ></p-checkbox>
              </div>
            </div>
          </div>

          <div class="p-field p-col-3" *ngIf="data.current_job_situation != ''">
            <label for="employment_possible_at_string">Beginn möglich am</label>
            <p-calendar
              [firstDayOfWeek]="1"
              [showWeek]="true"
              inputId="employment_possible_at_string"
              name="employment_possible_at_string"
              [showButtonBar]="true"
              [(ngModel)]="data.employment_possible_at_string"
              dateFormat="dd.mm.yy"
              [showIcon]="true"
              dataType="string"
            ></p-calendar>
          </div>
        </div>

        <div
          class="p-fluid p-formgrid p-grid"
          *ngIf="data.current_job_situation != ''"
        >
          <div class="p-field p-col-12">
            <label for="current_job_situation_notice"
              >Notiz zur aktuellen beruflichen Situation</label
            >
            <textarea
              id="current_job_situation_notice"
              name="current_job_situation_notice"
              [(ngModel)]="data.current_job_situation_notice"
              pInputTextarea
            ></textarea>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-6">
            <label for="tax_id">Steuer-ID</label>
            <input
              id="tax_id"
              name="tax_id"
              [(ngModel)]="data.tax_id"
              type="text"
              pInputText
            />
          </div>

          <div class="p-field p-col-6">
            <label for="pension_insurance_number"
              >Rentenversicherungsnummer</label
            >
            <input
              id="pension_insurance_number"
              name="pension_insurance_number"
              [(ngModel)]="data.pension_insurance_number"
              type="text"
              pInputText
            />
          </div>
        </div>
      </div>
    </div>

    <div class="content new-content-container limit-width">
      <header class="content-header">
        <span
          >Bewerbung als
          <!--          <p-checkbox-->
          <!--            *ngIf="!isEditMode"-->
          <!--            label="Befristet"-->
          <!--            (onChange)="employedTemporaryChanged($event)"-->
          <!--            [binary]="true"-->
          <!--            inputId="employed_temporary"-->
          <!--            [(ngModel)]="data.employed_temporary"-->
          <!--            name="employed_temporary"-->
          <!--          ></p-checkbox>-->
        </span>
      </header>
      <div class="content-body">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-3" *ngIf="!isEditMode">
            <label for="job_scope">Stellenumfang</label>
            <p-dropdown
              name="job_scope"
              inputId="job_scope"
              placeholder="Bitte auswählen"
              [(ngModel)]="data.job_scope"
              scrollHeight="300px"
              [options]="dependencies.job_scopes"
              #job_scope="ngModel"
              [class.p-invalid]="
                job_scope.invalid && (job_scope.dirty || job_scope.touched)
              "
            ></p-dropdown>
            <div
              *ngIf="
                job_scope.invalid && (job_scope.dirty || job_scope.touched)
              "
              class="p-invalid"
            >
              <div *ngIf="job_scope.errors?.required">
                <small>Bitte wählen Sie den Stellenumfang aus</small>
              </div>
            </div>
          </div>
          <div class="p-field p-col-3" *ngIf="!isEditMode">
            <label for="job_day">Arbeitszeit *</label>
            <p-dropdown
              name="job_day"
              inputId="job_day"
              placeholder="Bitte auswählen"
              [(ngModel)]="data.job_day"
              [options]="dependencies.job_days"
              #job_days="ngModel"
              scrollHeight="300px"
              [required]="true"
            ></p-dropdown>
            <div
              *ngIf="job_days.invalid && (job_days.dirty || job_days.touched)"
              class="p-invalid"
            >
              <div *ngIf="job_days.errors?.required">
                <small>Bitte wählen Sie eine Zeit aus</small>
              </div>
            </div>
          </div>
          <div class="p-field p-col-3">
            <label for="salary">Gehalt</label>
            <p-dropdown
              name="salary"
              inputId="salary"
              placeholder="Bitte auswählen"
              [(ngModel)]="data.salary"
              [options]="dependencies.salaries"
              scrollHeight="300px"
              #salary="ngModel"
              [class.p-invalid]="
                salary.invalid && (salary.dirty || salary.touched)
              "
            ></p-dropdown>
            <div
              *ngIf="salary.invalid && (salary.dirty || salary.touched)"
              class="p-invalid"
            >
              <div *ngIf="salary.errors?.required">
                <small>Bitte geben Sie ein Gehalt ein</small>
              </div>
            </div>
          </div>
          <div class="p-field p-col-3">
            <label for="vacation_days"
              >Urlaub {{ isEmployed ? "*" : "" }}</label
            >
            <p-dropdown
              name="vacation_days"
              inputId="vacation_days"
              placeholder="Bitte auswählen"
              [(ngModel)]="data.vacation_days"
              [options]="dependencies.vacation_days"
              scrollHeight="300px"
              #vacation_days="ngModel"
              [required]="isEmployed"
              [class.p-invalid]="
                vacation_days.invalid &&
                (vacation_days.dirty || vacation_days.touched)
              "
            ></p-dropdown>
          </div>

          <!-- Angestellt seit -->
          <!-- Wird erstmal nicht mehr benötigt -->
          <!--        <div class="p-field p-col-3" *ngIf="!isEditMode">-->
          <!--          <label for="employed-since">Angestellt seit *</label>-->
          <!--          <p-calendar-->
          <!--            [firstDayOfWeek]="1"-->
          <!--            inputId="employed-since"-->
          <!--            name="employed-since"-->
          <!--            [showButtonBar]="true"-->
          <!--            [(ngModel)]="data.employed_since_date_string"-->
          <!--            dateFormat="dd.mm.yy"-->
          <!--            [showIcon]="true"-->
          <!--            dataType="string"-->
          <!--            #employed_since="ngModel"-->
          <!--            [required]="true"-->
          <!--            [class.p-invalid]="-->
          <!--              employed_since.invalid &&-->
          <!--              (employed_since.dirty || employed_since.touched)-->
          <!--            "-->
          <!--          ></p-calendar>-->
          <!--          <div-->
          <!--            *ngIf="-->
          <!--              employed_since.invalid &&-->
          <!--              (employed_since.dirty || employed_since.touched)-->
          <!--            "-->
          <!--            class="p-invalid"-->
          <!--          >-->
          <!--            <div *ngIf="employed_since.errors?.required">-->
          <!--              <small>Bitte geben Sie ein Datum ein</small>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </div>-->

          <!-- Angestellt bis -->
          <!-- Wird erstmal nicht mehr benötigt -->
          <!--        <div class="p-field p-col-3" *ngIf="!isEditMode">-->
          <!--          <label for="employed-until"-->
          <!--            >Angestellt bis {{ data.employed_temporary ? "*" : "" }}</label-->
          <!--          >-->
          <!--          <p-calendar-->
          <!--            [firstDayOfWeek]="1"-->
          <!--            inputId="employed-until"-->
          <!--            name="employed-until"-->
          <!--            [(ngModel)]="data.employed_until_date_string"-->
          <!--            dateFormat="dd.mm.yy"-->
          <!--            [showButtonBar]="true"-->
          <!--            (onSelect)="untilDateChanged($event)"-->
          <!--            (onBlur)="untilDateChanged($event)"-->
          <!--            [showIcon]="true"-->
          <!--            [required]="data.employed_temporary"-->
          <!--            dataType="string"-->
          <!--            #employed_until="ngModel"-->
          <!--            [class.p-invalid]="-->
          <!--              employed_until.invalid &&-->
          <!--              (employed_until.dirty || employed_until.touched)-->
          <!--            "-->
          <!--          ></p-calendar>-->
          <!--          <div-->
          <!--            *ngIf="-->
          <!--              employed_until.invalid &&-->
          <!--              (employed_until.dirty || employed_until.touched)-->
          <!--            "-->
          <!--            class="p-invalid"-->
          <!--          >-->
          <!--            <div *ngIf="employed_until.errors?.required">-->
          <!--              <small>Bitte geben Sie ein Datum ein</small>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </div>-->
        </div>

        <!-- Angestellt als -->
        <!-- Wird nicht mehr benötigt -->
        <!--      <div class="p-fluid p-formgrid p-grid">-->
        <!--        <div class="p-field p-col-6">-->
        <!--          <label>Angestellt als</label>-->
        <!--          <div class="p-formgroup-inline">-->
        <!--            <div class="p-field-radiobutton">-->
        <!--              <p-radioButton-->
        <!--                [(ngModel)]="data.employed_as"-->
        <!--                label="Reine HW"-->
        <!--                name="employed-as"-->
        <!--                value="Reine HW"-->
        <!--              ></p-radioButton>-->
        <!--            </div>-->
        <!--            <div class="p-field-radiobutton">-->
        <!--              <p-radioButton-->
        <!--                [(ngModel)]="data.employed_as"-->
        <!--                label="Reine BK"-->
        <!--                name="employed-as"-->
        <!--                value="Reine BK"-->
        <!--              ></p-radioButton>-->
        <!--            </div>-->
        <!--            <div class="p-field-radiobutton">-->
        <!--              <p-radioButton-->
        <!--                [(ngModel)]="data.employed_as"-->
        <!--                label="HW und BK"-->
        <!--                name="employed-as"-->
        <!--                value="HW und BK"-->
        <!--              ></p-radioButton>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="p-field p-col-6">-->
        <!--          <label for="job_position">Position</label>-->
        <!--          <input-->
        <!--            id="job_position"-->
        <!--            name="job_position"-->
        <!--            [(ngModel)]="data.job_position"-->
        <!--            type="text"-->
        <!--            placeholder="z.B. Außendienst"-->
        <!--            pInputText-->
        <!--          />-->
        <!--        </div>-->
        <!--      </div>-->
      </div>
    </div>

    <div class="content new-content-container limit-width">
      <header class="content-header">
        <span>Weitere Informationen</span>
      </header>

      <div class="content-body">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <div class="p-formgroup-inline">
              <div class="p-field-checkbox">
                <p-checkbox
                  label="Kann drucken"
                  [binary]="true"
                  inputId="can_print"
                  [(ngModel)]="data.can_print"
                  name="can_print"
                ></p-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label>Rauchen?</label>
            <div class="p-formgroup-inline">
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Nein"
                  name="smoking"
                  [(ngModel)]="data.smoking"
                  value="Nein"
                ></p-radioButton>
              </div>
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Ja, E-Zigarette"
                  [(ngModel)]="data.smoking"
                  name="smoking"
                  value="Ja, E-Zigarette"
                ></p-radioButton>
              </div>
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Ja, draußen"
                  [(ngModel)]="data.smoking"
                  name="smoking"
                  value="Ja, draußen"
                ></p-radioButton>
              </div>
            </div>
          </div>
        </div>

        <!-- Gültiger Fürerschein -->
        <!--      <div class="p-fluid p-formgrid p-grid">-->
        <!--        <div class="p-field p-col-12">-->
        <!--          <label>Gültiger Führerschein?</label>-->
        <!--          <div class="p-formgroup-inline">-->
        <!--            <div class="p-field-radiobutton">-->
        <!--              <p-radioButton-->
        <!--                label="Nein"-->
        <!--                [(ngModel)]="data.driving"-->
        <!--                name="driving-licence"-->
        <!--                value="Nein"-->
        <!--              ></p-radioButton>-->
        <!--            </div>-->
        <!--            <div class="p-field-radiobutton">-->
        <!--              <p-radioButton-->
        <!--                label="Ja"-->
        <!--                [(ngModel)]="data.driving"-->
        <!--                name="driving-licence"-->
        <!--                value="Ja"-->
        <!--              ></p-radioButton>-->
        <!--            </div>-->
        <!--            <div class="p-field-radiobutton">-->
        <!--              <p-radioButton-->
        <!--                label="Ja, kleine Orte"-->
        <!--                [(ngModel)]="data.driving"-->
        <!--                name="driving-licence"-->
        <!--                value="Ja, kleine Orte"-->
        <!--              ></p-radioButton>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-3">
            <label>Allergien?</label>
            <div class="p-formgroup-inline">
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Nein"
                  name="allergies"
                  [(ngModel)]="data.allergies"
                  value="Nein"
                ></p-radioButton>
              </div>
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Ja"
                  name="allergies"
                  [(ngModel)]="data.allergies"
                  value="Ja"
                ></p-radioButton>
              </div>
            </div>
          </div>

          <div class="p-field p-col-9" *ngIf="data.allergies == 'Ja'">
            <div class="p-formgroup-inline" style="align-items: center">
              <div class="p-field-checkbox">
                <p-checkbox
                  styleClass="allergy-checkbox-container"
                  label="Haustierallergie"
                  [binary]="true"
                  inputId="animal_allergy"
                  [(ngModel)]="data.allergy.animal_allergy"
                  name="animal_allergy"
                ></p-checkbox>
              </div>

              <div class="p-field-checkbox" *ngIf="data.allergy.animal_allergy">
                <p-checkbox
                  label="Hundeallergie"
                  [binary]="true"
                  inputId="animal_allergy_dogs"
                  [(ngModel)]="data.allergy.animal_allergy_dogs"
                  name="animal_allergy_dogs"
                ></p-checkbox>
              </div>

              <div class="p-field-checkbox" *ngIf="data.allergy.animal_allergy">
                <p-checkbox
                  label="Katzenallergie"
                  [binary]="true"
                  inputId="animal_allergy_cats"
                  [(ngModel)]="data.allergy.animal_allergy_cats"
                  name="animal_allergy_cats"
                ></p-checkbox>
              </div>

              <div class="p-field-checkbox" *ngIf="data.allergy.animal_allergy">
                <input
                  placeholder="Andere..."
                  id="animal_allergy_other_info"
                  name="animal_allergy_other_info"
                  [(ngModel)]="data.allergy.animal_allergy_other_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>
            <div
              class="p-formgroup-inline"
              style="align-items: center; margin-top: 10px"
            >
              <div class="p-field-checkbox">
                <p-checkbox
                  styleClass="allergy-checkbox-container"
                  label="Stauballergie"
                  [binary]="true"
                  inputId="dust_allergy"
                  [(ngModel)]="data.allergy.dust_allergy"
                  name="dust_allergy"
                ></p-checkbox>
              </div>

              <div class="p-field-checkbox">
                <input
                  placeholder="Weitere Allergien..."
                  id="allergy_other_info"
                  name="allergy_other_info"
                  [(ngModel)]="data.allergy.other_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-3">
            <label>Phobien?</label>
            <div class="p-formgroup-inline">
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Nein"
                  name="phobias"
                  [(ngModel)]="data.phobias"
                  value="Nein"
                ></p-radioButton>
              </div>
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Ja"
                  name="phobias"
                  [(ngModel)]="data.phobias"
                  value="Ja"
                ></p-radioButton>
              </div>
            </div>
          </div>

          <div class="p-field p-col-9" *ngIf="data.phobias == 'Ja'">
            <div class="p-formgroup-inline" style="align-items: center">
              <div class="p-field-checkbox">
                <p-checkbox
                  styleClass="allergy-checkbox-container"
                  label="Hundephobie"
                  [binary]="true"
                  inputId="phobia_dogs"
                  [(ngModel)]="data.phobia.phobia_dogs"
                  name="phobia_dogs"
                ></p-checkbox>
              </div>

              <div class="p-field-checkbox">
                <p-checkbox
                  label="Spinnenphobie"
                  [binary]="true"
                  inputId="spider_phobia"
                  [(ngModel)]="data.phobia.spider_phobia"
                  name="spider_phobia"
                ></p-checkbox>
              </div>
            </div>

            <div
              class="p-formgroup-inline"
              style="align-items: center; margin-top: 10px"
            >
              <div class="p-field-checkbox">
                <p-checkbox
                  styleClass="allergy-checkbox-container"
                  label="Höhenangst"
                  [binary]="true"
                  inputId="fear_of_heights"
                  [(ngModel)]="data.phobia.fear_of_heights"
                  name="fear_of_heights"
                ></p-checkbox>
              </div>

              <div class="p-field-checkbox">
                <input
                  placeholder="Weitere Phobien..."
                  id="phobia_other_info"
                  name="phobia_other_info"
                  [(ngModel)]="data.phobia.other_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-5">
            <label>Impfung gegen COVID-19?</label>
            <div class="p-formgroup-inline">
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Nein"
                  name="vaccinated_covid_19"
                  [(ngModel)]="data.vaccinated_covid_19"
                  value="Nein"
                ></p-radioButton>
              </div>
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Geimpft"
                  [(ngModel)]="data.vaccinated_covid_19"
                  name="vaccinated_covid_19"
                  value="Geimpft"
                ></p-radioButton>
              </div>
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Genesen"
                  [(ngModel)]="data.vaccinated_covid_19"
                  name="vaccinated_covid_19"
                  value="Genesen"
                ></p-radioButton>
              </div>
            </div>
          </div>
          <div class="p-field p-col-3">
            <label for="vaccinated_covid_19_info">Weitere Infos</label>
            <input
              id="vaccinated_covid_19_info"
              [(ngModel)]="data.vaccinated_covid_19_info"
              type="text"
              name="vaccinated_covid_19_info"
              pInputText
            />
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-3">
            <label for="level_of_disability">Behindertengrad</label>
            <div class="p-inputgroup">
              <p-inputNumber
                [(ngModel)]="data.level_of_disability"
                inputId="level_of_disability"
                name="level_of_disability"
                [min]="0"
                [max]="100"
              ></p-inputNumber>
              <span class="p-inputgroup-addon">%</span>
            </div>
          </div>

          <div class="p-field p-col-3">
            <label for="level_of_disability_since">seit</label>
            <p-calendar
              [firstDayOfWeek]="1"
              [showWeek]="true"
              inputId="level_of_disability_since"
              name="level_of_disability_since"
              [showButtonBar]="true"
              [(ngModel)]="data.level_of_disability_since_string"
              dateFormat="dd.mm.yy"
              [showIcon]="true"
              dataType="string"
            ></p-calendar>
          </div>

          <div class="p-field p-col-3">
            <label for="level_of_disability_until">bis</label>
            <p-calendar
              [firstDayOfWeek]="1"
              [showWeek]="true"
              inputId="level_of_disability_until"
              name="level_of_disability_until"
              [showButtonBar]="true"
              [(ngModel)]="data.level_of_disability_until_string"
              dateFormat="dd.mm.yy"
              [showIcon]="true"
              dataType="string"
            ></p-calendar>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-3">
            <label for="glove_size">Handschuhgröße</label>
            <p-dropdown
              name="glove_size"
              inputId="glove_size"
              placeholder="Bitte auswählen"
              [(ngModel)]="data.glove_size"
              [options]="dependencies.glove_sizes"
              [showClear]="true"
            ></p-dropdown>
          </div>

          <div class="p-field p-col-3">
            <label for="clothes_size">Kleidergröße</label>
            <p-dropdown
              name="clothes_size"
              inputId="clothes_size"
              placeholder="Bitte auswählen"
              [(ngModel)]="data.clothes_size"
              [options]="dependencies.clothes_sizes"
              [showClear]="true"
            ></p-dropdown>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="about">Über die Betreuungskraft</label>
            <textarea
              id="about"
              name="about"
              [(ngModel)]="data.about"
              pInputTextarea
            ></textarea>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="current_family_situation"
              >Aktuelle familiäre Situation</label
            >
            <textarea
              id="current_family_situation"
              name="current_family_situation"
              [(ngModel)]="data.current_family_situation"
              pInputTextarea
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="content new-content-container limit-width">
      <header class="content-header">
        <span>Erfahrungen</span>
      </header>

      <div class="content-body">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <div class="p-formgroup-inline">
              <div
                class="p-field-checkbox"
                *ngFor="let experience of dependencies.experiences"
              >
                <p-checkbox
                  [label]="experience.name"
                  [value]="experience.id"
                  [inputId]="experience.name"
                  [(ngModel)]="data.experience_ids"
                  name="experiences"
                ></p-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="education-as">Grundausbildung als</label>
            <input
              id="education-as"
              name="education-as"
              [(ngModel)]="data.education_as"
              type="text"
              pInputText
            />
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="extra-information">Zusatzinfo</label>
            <textarea
              id="extra-information"
              name="extra-information"
              [(ngModel)]="data.experience_info"
              pInputTextarea
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="content new-content-container limit-width">
      <header class="content-header">
        <span>Tätigkeiten</span>
      </header>

      <div class="content-body">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <div class="p-formgroup-inline">
              <div
                class="p-field-checkbox"
                *ngFor="let activity of dependencies.activities"
              >
                <p-checkbox
                  [label]="activity.name"
                  [value]="activity.id"
                  [inputId]="activity.name"
                  [(ngModel)]="data.activity_ids"
                  name="activities"
                ></p-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="cities">Bezirke</label>
            <p-treeSelect
              [filter]="true"
              [filterInputAutoFocus]="true"
              selectionMode="checkbox"
              inputId="cities"
              name="cities"
              [(ngModel)]="data.city_ids"
              [options]="dependencies.counties"
              scrollHeight="500px"
            ></p-treeSelect>
          </div>
        </div>
      </div>
    </div>

    <div class="content new-content-container limit-width">
      <header class="content-header">
        <span>Qualifizierung</span>
      </header>

      <div class="content-body">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-3">
            <label>Erstschulung vollständig?</label>
            <div class="p-formgroup-inline">
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Ja"
                  name="initial-training"
                  value="Ja"
                  [(ngModel)]="data.initial_training"
                ></p-radioButton>
              </div>
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Nein"
                  name="initial-training"
                  value="Nein"
                  [(ngModel)]="data.initial_training"
                ></p-radioButton>
              </div>
            </div>
          </div>
          <div class="p-field p-col-3" *ngIf="data.initial_training == 'Ja'">
            <label for="last-training-date">Letzter Schulungstermin</label>
            <p-calendar
              [firstDayOfWeek]="1"
              [showWeek]="true"
              inputId="last-training-date"
              [(ngModel)]="data.last_training_date_string"
              dateFormat="dd.mm.yy"
              [showButtonBar]="true"
              name="last_training_date_string"
              [showIcon]="true"
              dataType="string"
            ></p-calendar>
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-3">
            <label>Erste Hilfe-Kurs?</label>
            <div class="p-formgroup-inline">
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Ja"
                  name="first_aid"
                  value="Ja"
                  [(ngModel)]="data.first_aid"
                ></p-radioButton>
              </div>
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Nein"
                  name="first_aid"
                  value="Nein"
                  [(ngModel)]="data.first_aid"
                ></p-radioButton>
              </div>
            </div>
          </div>
          <div class="p-field p-col-3" *ngIf="data.first_aid == 'Ja'">
            <label for="first_aid_date">Wann?</label>
            <p-calendar
              [firstDayOfWeek]="1"
              [showWeek]="true"
              [(ngModel)]="data.first_aid_date_string"
              inputId="first_aid_date"
              name="first_aid_date_string"
              dateFormat="dd.mm.yy"
              [showButtonBar]="true"
              [showIcon]="true"
              dataType="string"
            ></p-calendar>
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-3">
            <label>Einarbeitung Administration?</label>
            <div class="p-formgroup-inline">
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Ja"
                  name="training_administration"
                  value="Ja"
                  [(ngModel)]="data.training_administration"
                ></p-radioButton>
              </div>
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Nein"
                  name="training_administration"
                  value="Nein"
                  [(ngModel)]="data.training_administration"
                ></p-radioButton>
              </div>
            </div>
          </div>
          <div
            class="p-field p-col-3"
            *ngIf="data.training_administration == 'Ja'"
          >
            <label for="training_administration_date">Wann?</label>
            <p-calendar
              [firstDayOfWeek]="1"
              [showWeek]="true"
              [(ngModel)]="data.training_administration_date_string"
              inputId="training_administration_date"
              dateFormat="dd.mm.yy"
              [showButtonBar]="true"
              name="training_administration_date_string"
              [showIcon]="true"
              dataType="string"
            ></p-calendar>
          </div>
          <div
            class="p-field p-col-3"
            *ngIf="data.training_administration == 'Ja'"
          >
            <label for="training_administration_from">Eingearbeitet von</label>
            <input
              id="training_administration_from"
              [(ngModel)]="data.training_administration_from"
              type="text"
              name="training_administration_from"
              pInputText
            />
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-3">
            <label>Belehrung gemäß § 43 Infektionsschutzgesetz?</label>
            <div class="p-formgroup-inline">
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Ja"
                  name="instruction_infection_act"
                  value="Ja"
                  [(ngModel)]="data.instruction_infection_act"
                ></p-radioButton>
              </div>
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Nein"
                  name="instruction_infection_act"
                  value="Nein"
                  [(ngModel)]="data.instruction_infection_act"
                ></p-radioButton>
              </div>
            </div>
          </div>
          <div
            class="p-field p-col-3"
            *ngIf="data.instruction_infection_act == 'Ja'"
          >
            <label for="instruction_infection_act_date">Wann?</label>
            <p-calendar
              [firstDayOfWeek]="1"
              [showWeek]="true"
              [(ngModel)]="data.instruction_infection_act_date_string"
              inputId="instruction_infection_act_date"
              dateFormat="dd.mm.yy"
              [showButtonBar]="true"
              name="instruction_infection_act_date_string"
              [showIcon]="true"
              dataType="string"
            ></p-calendar>
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-3">
            <label>Erweitertes Führungszeugnis vorhanden?</label>
            <div class="p-formgroup-inline">
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Ja"
                  name="extended_certificate_of_good_conduct"
                  value="Ja"
                  [(ngModel)]="data.extended_certificate_of_good_conduct"
                ></p-radioButton>
              </div>
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Nein"
                  name="extended_certificate_of_good_conduct"
                  value="Nein"
                  [(ngModel)]="data.extended_certificate_of_good_conduct"
                ></p-radioButton>
              </div>
            </div>
          </div>
          <div
            class="p-field p-col-3"
            *ngIf="data.extended_certificate_of_good_conduct == 'Ja'"
          >
            <label for="extended_certificate_of_good_conduct">Datum</label>
            <p-calendar
              [firstDayOfWeek]="1"
              [showWeek]="true"
              [(ngModel)]="
                data.extended_certificate_of_good_conduct_date_string
              "
              inputId="extended_certificate_of_good_conduct"
              dateFormat="dd.mm.yy"
              [showButtonBar]="true"
              name="extended_certificate_of_good_conduct_date_string"
              [showIcon]="true"
              dataType="string"
            ></p-calendar>
          </div>
        </div>
      </div>
    </div>

    <div class="content-footer limit-width sticky">
      <span></span>

      <button
        (click)="save()"
        pButton
        [label]="
          isEditMode ? 'Änderungen speichern' : 'Betreuungskraft erstellen'
        "
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>
    </div>
  </div>
  <div class="content-offer-budget" *ngIf="!loading && !isEmployed">
    <div class="budgets">
      <div class="budget-container light-container">
        <h3>Checkliste Bewerber</h3>

        <div class="p-fluid p-formgrid p-grid without-border">
          <div class="p-field p-col-12">
            <label>Kontaktiert per</label>
            <div class="p-formgroup-inline">
              <div class="p-field-checkbox">
                <p-checkbox
                  label="E-Mail"
                  value="E-Mail"
                  [(ngModel)]="data.application_contact_via"
                  name="application_contact_via"
                ></p-checkbox>
              </div>
              <div class="p-field-checkbox">
                <p-checkbox
                  label="Telefon"
                  value="Telefon"
                  [(ngModel)]="data.application_contact_via"
                  name="application_contact_via"
                ></p-checkbox>
              </div>

              <div
                class="p-field-checkbox"
                *ngIf="data.application_contact_via?.includes('Telefon')"
              >
                <p-checkbox
                  label="n.E."
                  [binary]="true"
                  [(ngModel)]="data.application_ne"
                  name="application_ne"
                ></p-checkbox>
              </div>
              <div
                class="p-field-checkbox"
                *ngIf="data.application_contact_via?.includes('Telefon')"
              >
                <p-checkbox
                  label="n.E. + AB"
                  [binary]="true"
                  [(ngModel)]="data.application_ne_ab"
                  name="application_ne_ab"
                ></p-checkbox>
              </div>
            </div>
          </div>
        </div>

        <!--        <div class="p-fluid p-formgrid p-grid without-border">-->
        <!--          <div class="p-field p-col-12">-->
        <!--            <label for="job_interview_at_string">Bewerbungsgespräch am</label>-->
        <!--            <p-calendar-->
        <!--              [firstDayOfWeek]="1"-->
        <!--              [showWeek]="true"-->
        <!--              inputId="job_interview_at_string"-->
        <!--              name="job_interview_at_string"-->
        <!--              [(ngModel)]="data.job_interview_at_string"-->
        <!--              dateFormat="dd.mm.yy,"-->
        <!--              [showIcon]="true"-->
        <!--              [showTime]="true"-->
        <!--              hourFormat="24"-->
        <!--              dataType="string"-->
        <!--            ></p-calendar>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <div class="p-fluid p-formgrid p-grid without-border">-->
        <!--          <div class="p-field p-col-12">-->
        <!--            <label for="job_interview_with">Bewerbungsgespräch mit</label>-->
        <!--            <input-->
        <!--              id="job_interview_with"-->
        <!--              name="job_interview_with"-->
        <!--              [(ngModel)]="data.job_interview_with"-->
        <!--              type="text"-->
        <!--              pInputText-->
        <!--            />-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="p-fluid p-formgrid p-grid without-border">
          <div class="p-field p-col-12">
            <label>Bewerbungsunterlagen vollständig?</label>
            <div class="p-formgroup-inline">
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Ja"
                  name="application_documents_complete"
                  [(ngModel)]="data.application_documents_complete"
                  value="Ja"
                ></p-radioButton>
              </div>
              <div class="p-field-radiobutton">
                <p-radioButton
                  label="Nein"
                  name="application_documents_complete"
                  [(ngModel)]="data.application_documents_complete"
                  value="Nein"
                ></p-radioButton>
              </div>
            </div>
          </div>
        </div>

        <div
          class="p-fluid p-formgrid p-grid without-border"
          *ngIf="data.application_documents_complete === 'Nein'"
        >
          <div class="p-field p-col-12">
            <label for="missed_application_documents"
              >Welche Unterlagen fehlen?</label
            >
            <textarea
              id="missed_application_documents"
              name="missed_application_documents"
              [(ngModel)]="data.missed_application_documents"
              pInputTextarea
            ></textarea>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid without-border">
          <div class="p-field p-col-12">
            <label for="application_information"
              >Weitere Informationen zur Bewerbung</label
            >
            <textarea
              id="application_information"
              name="application_information"
              [(ngModel)]="data.application_information"
              pInputTextarea
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
