import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { CarDamageModel } from '../models/car/car-damage.model'
import { TodoModel } from '../models/todo/todo.model'

@Injectable({
  providedIn: 'root',
})
export class CarDamageService {
  constructor(private http: HttpClient) {}

  public load(carDamageId: string | number): Observable<CarDamageModel> {
    return this.http.get<CarDamageModel>(
      `${environment.api}/cars-damage/${carDamageId}`
    )
  }

  public store(carId: string, values: any): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/cars-damage/${carId}`, values)
  }

  public changeStatus(carDamageId: any, status: null | string): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/cars-damage/${carDamageId}/status`,
      {
        status,
      }
    )
  }

  public update(carDamageId: number | string, data: {}): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/cars-damage/${carDamageId}`,
      data
    )
  }

  public storeComment(carDamageId: number | string, data: {}): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/cars-damage/${carDamageId}/comment`,
      data
    )
  }

  public updateComment(
    carDamageCommentId: number | string,
    data: {}
  ): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/cars-damage/${carDamageCommentId}/comment`,
      data
    )
  }

  public list(carId: string | null): Observable<CarDamageModel[]> {
    return this.http.get<CarDamageModel[]>(
      `${environment.api}/cars-damage/${carId}/list`
    )
  }

  public delete(carDamageId: number | string): Observable<[]> {
    return this.http.delete<[]>(`${environment.api}/cars-damage/${carDamageId}`)
  }
}
