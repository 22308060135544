import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { UserSystemDetailModel } from '../models/user/user-system-detail.model'
import { HistoryListModel } from '../models/history/history-list.model'
import { CarFormModel } from '../models/car/car-form.model'
import { CarListModel } from '../models/car/car-list.model'
import { CarsDetailModel } from '../models/car/cars-detail.model'
import { CarsDetailGeneralModel } from '../models/car/cars-detail-general.model'
import { CaregiverCarModel } from '../models/car/caregiver-car.model'
import { CarDamageModel } from '../models/car/car-damage.model'
import { CarDetailDocumentsModel } from '../models/car/car-detail-documents.model'
import { CaregiverDetailModel } from '../models/caregiver/caregiver-detail.model'

@Injectable({
  providedIn: 'root',
})
export class CarService {
  constructor(private http: HttpClient) {}

  public list(withoutOthers = false): Observable<CarListModel[]> {
    return this.http.get<CarListModel[]>(
      `${environment.api}/cars?without_others=${withoutOthers}`
    )
  }

  public listDeactivated(): Observable<CarListModel[]> {
    return this.http.get<CarListModel[]>(`${environment.api}/cars/deactivated`)
  }

  public deactivate(id: string | null): Observable<CarsDetailModel> {
    return this.http.put<CarsDetailModel>(
      `${environment.api}/cars/${id}/deactivate`,
      {}
    )
  }

  public activate(id: string | null): Observable<CarsDetailModel> {
    return this.http.put<CarsDetailModel>(
      `${environment.api}/cars/${id}/activate`,
      {}
    )
  }

  public load(id: string | null): Observable<CarsDetailGeneralModel> {
    return this.http.get<CarsDetailGeneralModel>(
      `${environment.api}/cars/${id}`
    )
  }

  public loadForEditMode(id: string | null): Observable<CarFormModel> {
    return this.http.get<CarFormModel>(`${environment.api}/cars/${id}/edit`)
  }

  public history(id: string | null): Observable<HistoryListModel[]> {
    return this.http.get<HistoryListModel[]>(
      `${environment.api}/cars/${id}/history`
    )
  }

  public storeMileage(id: number | null, values: any): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/cars/${id}/mileage`, values)
  }

  public storeStatus(id: string | null, values: any): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/cars/${id}/status`, values)
  }

  public details(id: string | null): Observable<CarsDetailModel> {
    return this.http.get<CarsDetailModel>(
      `${environment.api}/cars/${id}/details`
    )
  }

  public update(data: CarFormModel): Observable<CarFormModel> {
    return this.http.put<CarFormModel>(
      `${environment.api}/cars/${data.id}`,
      data
    )
  }

  public store(data: CarFormModel): Observable<CarFormModel> {
    return this.http.post<CarFormModel>(`${environment.api}/cars`, data)
  }

  public storeForCaregiver(
    data: CaregiverCarModel,
    from_type: 'caregiver' | 'car'
  ): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/cars/caregiver`, {
      ...data,
      from_type,
    })
  }

  public updateForCaregiver(data: CaregiverCarModel): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/cars/caregiver/${data.id}`,
      data
    )
  }

  public deleteForCaregiver(id: number | null): Observable<[]> {
    return this.http.delete<[]>(`${environment.api}/cars/caregiver/${id}`)
  }

  public documents(
    id: string | null,
    onlyArchived: boolean = false
  ): Observable<CarDetailDocumentsModel> {
    return this.http.get<CarDetailDocumentsModel>(
      `${environment.api}/cars/${id}/documents?only_archived=${onlyArchived}`
    )
  }

  public getDocumentDownloadLink(uuid: string): string {
    return `${environment.api}/documents/${uuid}`
  }
}
