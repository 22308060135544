<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <h2>
    <i class="tio">time</i>
    Dienstplan
  </h2>

  <div class="content-actions-buttons">
    <button
      *ngIf="!loading && listData.length > 0 && !isReleased"
      pButton
      (click)="openApproveOverviewDialog()"
      label="Dienstplan freigeben"
      type="button"
      icon="pi pi-check"
      iconPos="left"
    ></button>

    <button
      *ngIf="!loading && listData.length > 0 && isReleased"
      pButton
      (click)="disapproveOverview()"
      label="Dienstplan-Freigabe zurückziehen"
      type="button"
      class="p-button-info"
      icon="pi pi-check"
      iconPos="left"
    ></button>
  </div>
</div>

<p-overlayPanel
  styleClass="dark-overlay"
  #op
  [showTransitionOptions]="'0ms'"
  [hideTransitionOptions]="'0ms'"
>
  <ng-template pTemplate>
    <div style="white-space: pre-wrap" [innerHTML]="currentOverlayValue"></div>
  </ng-template>
</p-overlayPanel>

<div class="content" *ngIf="!loading">
  <header class="content-header scrollable-horizontal">
    <span>
      <i
        style="padding: 10px 15px; margin: 0 0 0 -20px"
        (click)="goToPreviousMonth()"
        class="pi pi-chevron-left"
      ></i>

      <p-dropdown
        inputId="month"
        name="month"
        scrollHeight="500px"
        (onChange)="loadData(false)"
        [options]="monthOptions"
        appendTo="body"
        [(ngModel)]="month"
        [style]="{ width: '120px', zIndex: '10' }"
      ></p-dropdown>

      <p-dropdown
        inputId="year"
        name="year"
        (onChange)="loadData(false)"
        [options]="yearOptions"
        appendTo="body"
        [(ngModel)]="year"
        [style]="{ margin: ' 0 0 0 -1px' }"
      ></p-dropdown>

      <i
        (click)="goToNextMonth()"
        class="pi pi-chevron-right"
        style="padding: 10px 15px"
      ></i>
    </span>

    <div class="content-header-buttons">
      <div class="table-search-container" *ngIf="listData.length > 0">
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [value]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>

  <div
    class="content-body without-padding smaller-size-table scrollable-horizontal not-scrollable-on-mobile"
    [class.content-loading]="contentLoading"
  >
    <button
      *ngIf="isMobileView"
      (click)="showReleasedAppointments = !showReleasedAppointments"
      class="btn-full-width-confirm"
    >
      {{
        showReleasedAppointments
          ? "Bestätigte Termine verstecken"
          : "Bestätigte Termine anzeigen"
      }}
    </button>
    <p-table
      #dt
      responsiveLayout="stack"
      [value]="listData"
      [rowHover]="true"
      [globalFilterFields]="[
            'date',
            'data.real_from_h',
            'data.real_to_h',
            'data.patient.first_name',
            'data.patient.last_name',
            'address_full',
            'data.persplan_function_complete',
            'data.task',
          ]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 60px; text-align: center">
            <!--            <i-->
            <!--              pTooltip="Extra KM"-->
            <!--              class="pi pi-car"-->
            <!--              style="font-size: 12px"-->
            <!--            ></i>-->
          </th>
          <th style="width: 60px; text-align: center">
            <!--            <i-->
            <!--              pTooltip="Feedback"-->
            <!--              class="pi pi-envelope"-->
            <!--              style="font-size: 12px"-->
            <!--            ></i>-->
          </th>
          <th style="min-width: 140px; text-align: center">Einsätze</th>
          <th style="min-width: 140px" pSortableColumn="date">
            Datum <p-sortIcon field="date"></p-sortIcon>
          </th>
          <th
            style="min-width: 200px"
            pSortableColumn="data.patient.first_name"
          >
            Patient <p-sortIcon field="data.patient.first_name"></p-sortIcon>
          </th>
          <th style="min-width: 120px" pSortableColumn="data.real_from_h">
            Uhrzeit <p-sortIcon field="data.real_from_h"></p-sortIcon>
          </th>
          <th
            style="min-width: 140px; text-align: center"
            pSortableColumn="data.real_hours"
          >
            Dauer
          </th>
          <th
            style="min-width: 140px; text-align: center"
            pSortableColumn="google_drive_time_seconds_for_pdf"
          >
            Fahrzeit
          </th>
          <th style="min-width: 200px" pSortableColumn="address_full">
            Ort <p-sortIcon field="address_full"></p-sortIcon>
          </th>
          <th style="min-width: 60px"></th>
          <th style="min-width: 60px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
        <tr
          [hidden]="
            isMobileView &&
            data.data?.caregiver_appointment_release &&
            !showReleasedAppointments
          "
          [class.background-hightlight-yellow]="data.type === 'TYPE_HOLIDAY'"
          [class.background-hightlight-orange]="data.data?.has_storno_options"
          [class.background-hightlight-blue]="data.type === 'TYPE_VACATION'"
          [class.background-hightlight-main2]="
            data.type === 'TYPE_FREE_TIME_REQUEST' ||
            data.type === 'TYPE_OTHER' ||
            data.type === 'TYPE_TRAINING'
          "
          [class.background-hightlight-paused]="
            data.type === 'TYPE_PLANNING_FOLLOWS'
          "
          [class.background-hightlight-alert]="data.type === 'TYPE_ILL'"
          [class.background-hightlight-gray]="
            data.is_weekend || data.type === 'TYPE_INTERN'
          "
          [class.background-hightlight-hover]="currentHover === data.date"
          (mouseleave)="setHover('')"
          (mouseenter)="setHover(data.date)"
        >
          <td
            class="hide-row-on-mobile"
            style="position: relative; width: 60px; text-align: center"
          >
            <i
              (click)="openTripsDialog(data)"
              [pTooltip]="isReleased ? 'KM ansehen' : 'KM eintragen'"
              *ngIf="data.data && !data.data.has_storno_options"
              class="pi pi-car color-gray caregiver-appointment-icon-container"
              style="font-size: 17px"
            >
              <p-badge
                *ngIf="
                  data.data.caregiver_appointment_trip?.comments.length > 0
                "
                [value]="data.data.caregiver_appointment_trip?.comments.length"
              ></p-badge>
            </i>
            <i class="table-current-date" *ngIf="currentDay === data.date"></i>
          </td>

          <td
            class="hide-row-on-mobile"
            style="width: 60px; text-align: center"
          >
            <i
              (click)="openFeedbackDialog(data)"
              [pTooltip]="
                isReleased ? 'Feedback ansehen' : 'Feedback eintragen'
              "
              *ngIf="data.data"
              class="pi pi-envelope color-gray caregiver-appointment-icon-container"
              style="font-size: 17px"
            >
              <p-badge
                *ngIf="data.data.caregiver_appointment_feedback.length > 0"
                [value]="data.data.caregiver_appointment_feedback.length"
              ></p-badge>
            </i>
          </td>

          <td style="min-width: 140px">
            <i
              class="table-current-date show-on-mobile"
              *ngIf="currentDay === data.date"
            ></i>
            <!-- Feiertag -->
            <span
              style="display: flex; align-items: center"
              *ngIf="
                data.type === 'TYPE_HOLIDAY' ||
                data.type === 'TYPE_VACATION' ||
                data.type === 'TYPE_FREE_TIME_REQUEST' ||
                data.type === 'TYPE_OTHER' ||
                data.type === 'TYPE_PLANNING_FOLLOWS' ||
                data.type === 'TYPE_INTERN' ||
                data.type === 'TYPE_TRAINING' ||
                data.type === 'TYPE_ILL'
              "
              >{{ data.value }}
              <i
                style="font-size: 18px; margin: 0 0 0 5px"
                class="pi pi-info-circle"
                *ngIf="data.tooltip"
                (click)="toggleOverlay($event, data.tooltip)"
              ></i
            ></span>
            <!-- Keine Planung -->
            <span style="display: flex; align-items: center" *ngIf="!data.type"
              >Keine Planung</span
            >

            <!-- Stornierung -->
            <span
              style="display: flex; align-items: center"
              *ngIf="data.type && data.data && data.data.has_storno_options"
              >Stornierung</span
            >

            <!-- Alle Einsätze -->
            <i
              style="font-size: 18px; display: block; text-align: center"
              class="pi pi-user hide-row-on-mobile"
              *ngIf="data.type && data.data && !data.data.has_storno_options"
              (click)="openAnonymousAppointments(data)"
            ></i>
          </td>
          <!-- Datum -->
          <td
            style="vertical-align: center; min-width: 140px"
            class="td-with-multiple-items"
          >
            <div style="display: flex; flex-direction: column">
              <span style="width: 100px" class="p-column-title">Datum</span>
              {{ data.date }} ({{ data.day }})
              <span
                style="width: 100px"
                class="color-blue"
                *ngIf="data.data?.doctor_appointment"
                >Arzttermin</span
              >

              <span
                style="width: 100px"
                class="color-blue"
                *ngIf="data.data?.shopping_appointment"
                >Einkaufsfahrt</span
              >

              <span
                style="width: 100px"
                class="color-orange hide-row-on-mobile"
                *ngIf="data.data?.is_last_appointment_of_month"
                >Letzter Einsatz</span
              >
            </div>

            <div
              class="show-on-mobile color-orange"
              *ngIf="data.data?.is_last_appointment_of_month"
            >
              Letzter Einsatz des Patienten diesen Monat
            </div>

            <div class="show-on-mobile" *ngIf="data.data">
              <i
                class="pi pi-info-circle"
                (click)="toggleOverlay($event, data.details_with_address)"
              ></i>
            </div>
          </td>
          <!-- Patient -->
          <td
            style="vertical-align: center; min-width: 200px"
            *ngIf="data.data"
          >
            <span style="width: 100px" class="p-column-title">Patient</span>

            <span class="table-data-highlight"
              >{{ data.data.patient.first_name }}
              {{ data.data.patient.last_name }}</span
            >
          </td>
          <td
            class="hide-row-on-mobile"
            style="min-width: 200px"
            *ngIf="!data.data"
          ></td>

          <!-- Uhrzeit wenn der Einsatz NICHT gelöscht wurde -->
          <td
            style="vertical-align: center; min-width: 120px"
            *ngIf="data.data && !data.data.deleted_at"
          >
            <span style="width: 100px" class="p-column-title">Uhrzeit</span>
            {{ data.data.real_from_h }} - {{ data.data.real_to_h }}
          </td>
          <!-- Uhrzeit wenn der Einsatz gelöscht wurde, aber keine Arbeitszeit angerechnet wurde -->
          <td
            style="vertical-align: center; min-width: 120px"
            *ngIf="
              data.data &&
              data.data.deleted_at &&
              !data.data.consider_working_time
            "
          >
            <span style="width: 100px" class="p-column-title">Uhrzeit</span>
            ({{ data.data.real_from_h }} - {{ data.data.real_to_h }})
          </td>
          <!-- Uhrzeit wenn der Einsatz gelöscht wurde, ABER Arbeitszeit angerechnet wurde -->
          <td
            style="vertical-align: center; min-width: 120px"
            *ngIf="
              data.data &&
              data.data.deleted_at &&
              data.data.consider_working_time
            "
          >
            <span style="width: 100px" class="p-column-title">Uhrzeit</span>
            {{ data.data.consider_working_time_from }} -
            {{ data.data.consider_working_time_to }}
          </td>

          <!-- Uhrzeit für intern/fortbildung -->
          <td
            style="vertical-align: center"
            *ngIf="
              !data.data &&
              (data.type === 'TYPE_TRAINING' ||
                data.type === 'TYPE_INTERN' ||
                data.type === 'TYPE_OTHER' ||
                data.type === 'TYPE_PLANNING_FOLLOWS')
            "
          >
            <span style="width: 100px" class="p-column-title">Uhrzeit</span>
            {{ data.from_time }} - {{ data.to_time }}
          </td>
          <td
            class="hide-row-on-mobile"
            style="min-width: 120px"
            *ngIf="
              !data.data &&
              data.type !== 'TYPE_TRAINING' &&
              data.type !== 'TYPE_INTERN' &&
              data.type !== 'TYPE_OTHER' &&
              data.type !== 'TYPE_PLANNING_FOLLOWS'
            "
          ></td>
          <!-- Dauer -->
          <td
            style="vertical-align: center; min-width: 140px"
            *ngIf="data.data"
          >
            <div class="td-with-action-buttons td-align-left">
              <span style="width: 90px" class="p-column-title">Dauer</span>
              <i
                *ngIf="!isReleased && !data.data.has_storno_options"
                (click)="
                  decrementAppointmentChange(data, 'time');
                  appointmentChange(data)
                "
                class="pi pi-minus-circle"
              ></i>

              <!-- Die Dauer, wenn der Einsatz NICHT gelöscht ist -->
              <div *ngIf="!data.data.deleted_at">
                {{ data.data.real_hours_as_float }} Std
                <span
                  class="color-gray"
                  *ngIf="data.data.caregiver_appointment_change.time != 0"
                >
                  <span *ngIf="data.data.caregiver_appointment_change.time > 0"
                    >+</span
                  >{{
                    data.data.caregiver_appointment_change.time
                      .toString()
                      .replace(".", ",")
                  }}
                  Std</span
                >
              </div>

              <!-- Die Dauer, wenn der Einsatz gelöscht ist UND Arbeitszeit angerechnet wird -->
              <div
                *ngIf="data.data.deleted_at && data.data.consider_working_time"
              >
                {{ data.data.consider_working_time_hours_as_float }} Std
              </div>

              <!-- Die Dauer, wenn der Einsatz gelöscht ist UND KEINE Arbeitszeit angerechnet wird -->
              <div
                *ngIf="data.data.deleted_at && !data.data.consider_working_time"
              >
                0 Std
              </div>

              <i
                *ngIf="!isReleased && !data.data.has_storno_options"
                (click)="
                  incrementAppointmentChange(data, 'time');
                  appointmentChange(data)
                "
                class="pi pi-plus-circle"
              ></i>

              <i
                (click)="openChangeFeedbackDialog(data, 'time')"
                pTooltip="Kommentar"
                class="pi pi-envelope color-gray caregiver-appointment-icon-container"
                style="font-size: 17px; margin: 0 0 0 10px"
                *ngIf="
                  data.data.caregiver_appointment_change.time != 0 ||
                  data.data.caregiver_appointment_change.time_feedback
                "
              >
                <p-badge
                  *ngIf="data.data.caregiver_appointment_change.time_feedback"
                  value="1"
                ></p-badge>
              </i>
            </div>
          </td>
          <!-- Dauer für intern/fortbildung -->
          <td
            style="vertical-align: center"
            *ngIf="
              !data.data &&
              (data.type === 'TYPE_TRAINING' ||
                data.type === 'TYPE_INTERN' ||
                data.type === 'TYPE_OTHER' ||
                data.type === 'TYPE_PLANNING_FOLLOWS')
            "
          >
            <span style="width: 100px" class="p-column-title">Dauer</span>
            {{ data.hours }} Std
          </td>
          <td
            class="hide-row-on-mobile"
            style="min-width: 110px"
            *ngIf="
              !data.data &&
              data.type !== 'TYPE_TRAINING' &&
              data.type !== 'TYPE_INTERN' &&
              data.type !== 'TYPE_OTHER' &&
              data.type !== 'TYPE_PLANNING_FOLLOWS'
            "
          ></td>
          <!-- Fahrzeit -->
          <td
            style="vertical-align: center; min-width: 140px"
            *ngIf="
              data.show_drive_time && data.calculate_google_drive_time_for_pdf
            "
          >
            <div class="td-with-action-buttons td-align-left">
              <span style="width: 90px" class="p-column-title">Fahrzeit</span>
              <i
                *ngIf="!isReleased && data.data"
                (click)="
                  decrementAppointmentChange(data, 'drive_time');
                  appointmentChange(data)
                "
                class="pi pi-minus-circle"
              ></i>
              <div>
                {{ data.google_drive_time_formatted_for_pdf }}
                <span
                  class="color-gray"
                  *ngIf="data.data?.caregiver_appointment_change?.drive_time"
                >
                  <span
                    *ngIf="
                      data.data.caregiver_appointment_change.drive_time > 0
                    "
                    >+</span
                  >{{
                    data.data.caregiver_appointment_change.drive_time
                  }}
                  min</span
                >
              </div>
              <i
                *ngIf="!isReleased && data.data"
                (click)="
                  incrementAppointmentChange(data, 'drive_time');
                  appointmentChange(data)
                "
                class="pi pi-plus-circle"
              ></i>

              <i
                (click)="openChangeFeedbackDialog(data, 'drive_time')"
                pTooltip="Kommentar"
                class="pi pi-envelope color-gray caregiver-appointment-icon-container"
                style="font-size: 17px; margin: 0 0 0 10px"
                *ngIf="
                  data.data?.caregiver_appointment_change?.drive_time ||
                  data.data?.caregiver_appointment_change?.drive_time_feedback
                "
              >
                <p-badge
                  *ngIf="
                    data.data.caregiver_appointment_change.drive_time_feedback
                  "
                  value="1"
                ></p-badge>
              </i>
            </div>
          </td>
          <td
            class="hide-row-on-mobile"
            style="min-width: 110px"
            *ngIf="
              !data.show_drive_time || !data.calculate_google_drive_time_for_pdf
            "
          ></td>
          <!-- Ort -->
          <td
            class="hide-row-on-mobile"
            style="vertical-align: center; min-width: 200px"
            *ngIf="data.data"
          >
            <a
              [href]="
                'https://www.google.de/maps/dir/?api=1&travelmode=driving&destination=' +
                data.address_full
              "
              target="_blank"
              class="pi pi-map-marker icon-link"
              pTooltip="In Google Maps ansehen"
            ></a>
            {{ data.address_full }}
          </td>
          <td
            class="hide-row-on-mobile"
            style="min-width: 200px"
            *ngIf="
              !data.data &&
              data.type !== 'TYPE_TRAINING' &&
              data.type !== 'TYPE_INTERN'
            "
          ></td>
          <!-- Ort für intern/fortbildung NICHT online -->
          <td
            style="vertical-align: center"
            *ngIf="
              !data.data &&
              (data.type === 'TYPE_TRAINING' || data.type === 'TYPE_INTERN') &&
              data.address_type !== 'ONLINE'
            "
          >
            <span style="width: 100px" class="p-column-title">Ort</span>
            <a
              [href]="
                'https://www.google.de/maps/dir/?api=1&travelmode=driving&destination=' +
                data.address_full
              "
              *ngIf="data.address_full"
              target="_blank"
              class="pi pi-map-marker icon-link"
              pTooltip="In Google Maps ansehen"
            ></a>
            {{ data.address_full }}
          </td>
          <!-- Ort für intern/fortbildung IST online -->
          <td
            style="vertical-align: center"
            *ngIf="
              !data.data &&
              (data.type === 'TYPE_TRAINING' || data.type === 'TYPE_INTERN') &&
              data.address_type === 'ONLINE'
            "
          >
            <span style="width: 100px" class="p-column-title">Ort</span>
            Online
          </td>
          <!-- Details -->
          <td
            class="hide-row-on-mobile table-action"
            style="min-width: 100px; text-align: center !important"
            *ngIf="data.data"
          >
            <i
              class="pi pi-info-circle"
              (click)="toggleOverlay($event, data.details)"
            ></i>
          </td>
          <td
            class="hide-row-on-mobile"
            style="min-width: 100px"
            *ngIf="!data.data"
          ></td>
          <td
            class="hide-row-on-mobile table-action"
            style="min-width: 60px; text-align: center !important"
          >
            <i
              class="pi"
              [class.pi-check]="
                !releaseSubmitted[data.data.id] &&
                !data.data.caregiver_appointment_release
              "
              [class.pi-check-circle]="
                !releaseSubmitted[data.data.id] &&
                data.data.caregiver_appointment_release
              "
              [class.pi-spin]="releaseSubmitted[data.data.id]"
              [class.pi-spinner]="releaseSubmitted[data.data.id]"
              [class.color-main1]="data.data.caregiver_appointment_release"
              [class.pointer-events-none]="releaseClicked"
              [pTooltip]="
                data.data.caregiver_appointment_release
                  ? 'Freigabe zurückziehen'
                  : 'Einsatz freigeben'
              "
              tooltipPosition="left"
              *ngIf="
                data.data &&
                !isReleased &&
                !data.data.is_in_future &&
                !data.data.has_storno_options
              "
              (click)="
                data.data.caregiver_appointment_release
                  ? unreleaseAppointment(data)
                  : releaseAppointment(data)
              "
            ></i>
          </td>

          <td class="show-on-mobile" *ngIf="data.data">
            <div
              style="
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                gap: 10px;
              "
            >
              <button
                (click)="openFeedbackDialog(data)"
                pButton
                type="button"
                icon="pi pi-envelope"
                class="p-button-outlined"
                [label]="
                  data.data.caregiver_appointment_feedback.length > 0
                    ? 'Feedback (' +
                      data.data.caregiver_appointment_feedback.length +
                      ')'
                    : 'Feedback'
                "
              ></button>

              <button
                *ngIf="!data.data.has_storno_options"
                (click)="openTripsDialog(data)"
                pButton
                [label]="
                  data.data.caregiver_appointment_trip?.comments.length > 0
                    ? 'Kilometer (' +
                      data.data.caregiver_appointment_trip?.comments.length +
                      ')'
                    : 'Kilometer'
                "
                type="button"
                icon="pi pi-car"
                class="p-button-outlined"
              ></button>

              <button
                *ngIf="
                  !isReleased &&
                  !data.data.is_in_future &&
                  !data.data.has_storno_options
                "
                (click)="
                  data.data.caregiver_appointment_release
                    ? unreleaseAppointment(data)
                    : releaseAppointment(data)
                "
                pButton
                label=""
                type="button"
                [icon]="
                  releaseSubmitted[data.data.id]
                    ? 'pi pi-spin pi-spinner'
                    : 'pi pi-check'
                "
                [class]="
                  data.data.caregiver_appointment_release
                    ? ''
                    : 'p-button-outlined'
                "
              ></button>
            </div>

            <!-- Alle Einsätze -->
            <div
              style="display: flex; margin: 10px 0 0 0"
              *ngIf="!data.data.has_storno_options"
            >
              <button
                (click)="openAnonymousAppointments(data)"
                pButton
                label="Einsätze für den Patienten"
                type="button"
                icon="pi pi-user"
                class="p-button-outlined"
              ></button>
            </div>
          </td>

          <!-- Wird benötigt um Border zwischen den Zeilen anzuzeigen -->
          <td class="show-on-mobile"></td>
          <td class="show-on-mobile"></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="table-footer">
          <div>
            <span class="table-footer-number"
              ><b>{{ hoursTotal }} Std</b></span
            >
            <span class="table-footer-info"
              ><b>Gesamt ({{ totalPlannings }} Schichten)</b></span
            >
          </div>
          <div>
            <span class="table-footer-number color-gray"
              >{{ hoursGoogle }} Std</span
            >
            <span class="table-footer-info color-gray"
              >Fahrzeiten von Google</span
            >
          </div>
          <div>
            <span class="table-footer-number color-gray"
              >{{ driveMeters }} KM</span
            >
            <span class="table-footer-info color-gray">Gefahrene KM</span>
          </div>
        </div>
      </ng-template>
    </p-table>
  </div>
</div>
