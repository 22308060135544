<div class="content-actions">
  <i class="tio icon-large">clear</i>

  <h2>
    <i class="tio">clear</i>
    Inaktive Kunden
  </h2>
</div>

<app-loader [loading]="loading"></app-loader>

<div class="content new-content-container" style="margin: 0" *ngIf="!loading">
  <header class="content-header">
    <span>Alle Patienten die Storno oder KI sind</span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
      *ngIf="patients.length > 0"
    >
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div class="content-body" *ngIf="patients.length == 0">
    <span class="content-no-message">Keine Inaktiven Kunden vorhanden</span>
  </div>

  <div
    class="content-body content-body-almost-full-height without-padding"
    *ngIf="patients.length > 0"
  >
    <p-table
      #dt
      [value]="patients"
      [globalFilterFields]="[
        'id',
        'first_name',
        'last_name',
        'last_first_name',
        'last_first_name_comma',
        'first_last_name_comma',
        'full_name',
        'status_string',
        'city'
      ]"
      [rowHover]="true"
      [paginator]="true"
      scrollHeight="100%"
      [scrollable]="true"
      [(first)]="firstPage"
      [rows]="50"
      stateStorage="local"
      stateKey="state-patients-inactive-3"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Patienten"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="max-width: 120px" pSortableColumn="status_string">
            Status
            <p-sortIcon field="status_string"></p-sortIcon>
          </th>
          <th style="max-width: 100px" pSortableColumn="id">
            ID <p-sortIcon field="id"></p-sortIcon>
          </th>
          <th pSortableColumn="last_name">
            Name <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="city">
            Wohnort <p-sortIcon field="city"></p-sortIcon>
          </th>
          <th pSortableColumn="history_since_storno_or_ki.occurred_at">
            Status geändert am
            <p-sortIcon
              field="history_since_storno_or_ki.occurred_at"
            ></p-sortIcon>
          </th>
          <th pSortableColumn="last_name">
            Status geändert von <p-sortIcon field="last_name"></p-sortIcon>
          </th>
          <th style="min-width: 300px">Grund</th>
          <th
            style="max-width: 50px"
            pTooltip="Kunde bei Pflegeherzen"
            tooltipPosition="left"
            pSortableColumn="is_pflegeherzen_customer"
          >
            PH <p-sortIcon field="is_pflegeherzen_customer"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-patient>
        <tr>
          <td style="max-width: 120px">
            <span class="p-tag p-tag-rounded p-tag-{{ patient.status }}">{{
              patient.status_string
            }}</span>
          </td>
          <td style="max-width: 100px">{{ patient.id }}</td>
          <td>
            <a class="color-main1" routerLink="/patients/{{ patient.id }}">
              {{ patient.last_name }}, {{ patient.first_name }}
            </a>
          </td>
          <td style="width: 200px">
            <div class="text-center-container">
              <i
                style="font-size: 14px"
                class="pi pi-info-circle color-gray"
                [pTooltip]="patient.full_address"
              ></i>
              {{ patient.city }}
            </div>
          </td>

          <td *ngIf="patient.history_since_storno_or_ki">
            {{
              patient.history_since_storno_or_ki.occurred_at
                | date: "dd.MM.y, H:mm"
            }}
            Uhr
          </td>
          <td *ngIf="patient.history_since_storno_or_ki">
            {{ patient.history_since_storno_or_ki.created_from_name }}
          </td>

          <td *ngIf="!patient.history_since_storno_or_ki">-</td>
          <td *ngIf="!patient.history_since_storno_or_ki">-</td>

          <td
            style="min-width: 300px"
            *ngIf="patient.history_since_storno_or_ki"
          >
            {{ patient.history_since_storno_or_ki.manual_description }}
          </td>

          <td style="max-width: 50px">
            <i
              class="pflegeherzen-logo-icon"
              *ngIf="patient.is_pflegeherzen_customer"
              tooltipPosition="left"
              pTooltip="Kunde bei Pflegeherzen"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
