import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { CarCostModel } from '../models/car/car-cost.model'
import { CarGarageAppointmentModel } from '../models/car/car-garage-appointment.model'

@Injectable({
  providedIn: 'root',
})
export class CarGarageAppointmentService {
  constructor(private http: HttpClient) {}

  public load(
    carGarageAppointmentId: string | number
  ): Observable<CarGarageAppointmentModel> {
    return this.http.get<CarGarageAppointmentModel>(
      `${environment.api}/cars-garage-appointments/${carGarageAppointmentId}`
    )
  }

  public store(carId: string, values: any): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/cars-garage-appointments/${carId}`,
      values
    )
  }

  public update(
    carGarageAppointmentId: number | string,
    data: {}
  ): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/cars-garage-appointments/${carGarageAppointmentId}`,
      data
    )
  }

  public list(carId: string | null): Observable<CarGarageAppointmentModel[]> {
    return this.http.get<CarGarageAppointmentModel[]>(
      `${environment.api}/cars-garage-appointments/${carId}/list`
    )
  }

  public delete(carGarageAppointmentId: number | string): Observable<[]> {
    return this.http.delete<[]>(
      `${environment.api}/cars-garage-appointments/${carGarageAppointmentId}`
    )
  }
}
