import { CarMileageModel } from './car-mileage.model'
import { CarStatusModel } from './car-status.model'

export class CarFormModel {
  id: number | null = null
  brand = ''
  model = ''
  license_plate = ''
  mileage = ''
  mileage_at: Date | null = null
  mileage_at_string = ''
  initial_registration: Date | null = null
  initial_registration_string = ''
  private_use_allowed = ''
  group = ''
  group_string = ''
  notice = ''
  navigation = ''
  portable_navigation = ''
  navigation_number = ''
  fuel_card = ''
  fuel_card_number = ''
  summer_tires_warehouse = ''
  current_place = ''
  winter_tires_warehouse = ''
  created_at: Date | null = null
  updated_at: Date | null = null
  deleted_at: Date | null = null
  tuv_status: any = {}
  inspection_status: any = {}
  current_mileage: CarMileageModel = new CarMileageModel()
  current_status: CarStatusModel = new CarStatusModel()
  mileages: CarMileageModel[] = []
  status: CarStatusModel[] = []
}
