<form
  #form="ngForm"
  class="dialog-form"
  style="height: 100%; display: flex; flex-direction: column"
>
  <div class="dialog-loader white-background" *ngIf="loading">
    <i class="loading-logo"></i>
    WIRD GELADEN
  </div>

  <div class="dialog-form-footer on-top" *ngIf="!loading">
    <i></i>

    <div class="dialog-footer-action-container">
      <a
        *ngIf="data.sent_type === 'email'"
        target="_blank"
        (click)="clickedOnPreviewMail = true"
        [href]="mailPreviewSrc"
      >
        <button
          pButton
          label="Mail Vorschau"
          type="button"
          [pTooltip]="isMailPreviewDisabled() ? 'Keine E-Mail ausgewählt' : ''"
          [disabled]="isMailPreviewDisabled()"
          icon="pi pi-eye"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <a target="_blank" (click)="clickedOnPreviewPdf = true" [href]="pdfSrc">
        <button
          pButton
          label="PDF Vorschau"
          type="button"
          icon="pi pi-eye"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <p-splitButton
        *ngIf="data.id && data.status === 'READY'"
        label="Zwischenspeichern"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        (onClick)="store()"
        [model]="items"
        appendTo="body"
        [disabled]="submitted"
      >
      </p-splitButton>
    </div>
  </div>

  <div
    class="dialog-form-body without-padding"
    style="height: calc(100% - 54px)"
    *ngIf="!loading"
  >
    <div class="proof-check-container">
      <!-- Links -->
      <div class="proof-check-image-container">
        <div [class.is-rotating]="rotating" class="proof-check-image-item">
          <embed
            [src]="pdfSrcSafe"
            type="application/pdf"
            width="100%"
            height="100%"
          />
        </div>
      </div>

      <!-- Rechts -->
      <div class="proof-check-actions-container">
        <!-- Patient Daten -->
        <div
          class="sidebar-inline-calendar with-border"
          style="padding: 20px; gap: 30px"
        >
          <div style="display: flex; flex-direction: column">
            <label>Patient</label>
            <a
              target="_blank"
              style="color: currentColor"
              routerLink="/patients/{{ patient.id }}"
            >
              <strong>{{ patient.first_name }} {{ patient.last_name }}</strong
              ><i
                style="font-size: 12px; margin: 0 0 0 5px"
                class="color-gray pi pi-arrow-up-right"
              ></i>
            </a>
          </div>

          <div style="display: flex; flex-direction: column">
            <label>Abrechnungsstatus</label>
            <strong
              >{{ patient.insured_via_string || "-" }}
              {{
                patient.insured_via_other_text
                  ? "(" + patient.insured_via_other_text + ")"
                  : ""
              }}</strong
            >
            <strong
              class="color-gray"
              *ngIf="
                patient.insured_via.includes('Selbstzahler') &&
                patient.selbstzahler_budget_type
              "
            >
              Selbstzahler
              {{ patient.selbstzahler_budget_type }}
              {{
                patient.selbstzahler_budget_type === "Max"
                  ? patient.selbstzahler_budget_max + " Std"
                  : ""
              }}</strong
            >
          </div>

          <div style="display: flex; flex-direction: column">
            <label>SEPA</label>
            <strong style="display: flex; align-items: center; gap: 5px">
              {{ patient.first_sepa ? "Ja" : "Nein" }}
            </strong>
          </div>
        </div>

        <!-- Patient Daten 2 -->
        <div
          class="sidebar-inline-calendar with-border"
          style="padding: 20px; gap: 30px"
        >
          <div style="display: flex; flex-direction: column">
            <label>Notiz Buchhaltung</label>

            <strong
              *ngIf="patient.customer.notice_accounting"
              style="display: flex; align-items: center; gap: 10px"
              >Eintrag
              <i
                class="pi pi-info-circle"
                [pTooltip]="patient.customer.notice_accounting"
              ></i
            ></strong>

            <strong *ngIf="!patient.customer.notice_accounting"
              >Keine Angabe</strong
            >
          </div>

          <div style="display: flex; flex-direction: column">
            <label>Rechnungsversand</label>
            <strong style="display: flex; align-items: center; gap: 10px">
              {{ patient.invoice_types_string || "Keine Angabe" }}
              <i
                class="pi pi-info-circle"
                [pTooltip]="patient.invoice_type_tooltip"
                *ngIf="patient.invoice_type_tooltip"
              ></i>
            </strong>
          </div>

          <div style="display: flex; flex-direction: column">
            <label>AP für Rechnung</label>
            <strong style="display: flex; align-items: center; gap: 10px">
              {{ contactPersonsForInvoice || "Keine Angabe" }}
            </strong>
          </div>
        </div>

        <!-- Rechnungsdaten -->
        <div class="sidebar-inline-calendar" style="padding: 20px">
          <div class="proof-shipping-items">
            <!-- Rechnungsempfänger -->
            <div class="proof-shipping-item">
              <div class="p-fluid p-formgrid p-grid without-border">
                <div class="p-field p-col-9">
                  <label for="invoice_receiver"
                    ><i class="pi pi-user"></i>
                    <strong>Rechnungsempfänger</strong></label
                  >
                  <div class="p-formgroup-inline">
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        label="Leistungsnehmer"
                        name="invoice_receiver"
                        (onClick)="invoiceReceiverChanged()"
                        [(ngModel)]="data.invoice_receiver"
                        value="patient"
                      ></p-radioButton>
                    </div>
                    <div
                      class="p-field-radiobutton"
                      *ngIf="patient.care_insurance.id"
                    >
                      <p-radioButton
                        label="Pflegekasse"
                        name="invoice_receiver"
                        (onClick)="invoiceReceiverChanged()"
                        [(ngModel)]="data.invoice_receiver"
                        value="care_insurance"
                      ></p-radioButton>
                    </div>
                    <!--                    <div-->
                    <!--                      class="p-field-radiobutton"-->
                    <!--                      *ngIf="patient.cost_unit.id"-->
                    <!--                    >-->
                    <!--                      <p-radioButton-->
                    <!--                        label="Kostenträger"-->
                    <!--                        name="invoice_receiver"-->
                    <!--                        (onClick)="invoiceReceiverChanged()"-->
                    <!--                        [(ngModel)]="data.invoice_receiver"-->
                    <!--                        value="cost_unit"-->
                    <!--                      ></p-radioButton>-->
                    <!--                    </div>-->
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        label="Individuell"
                        name="invoice_receiver"
                        (onClick)="invoiceReceiverChanged()"
                        [(ngModel)]="data.invoice_receiver"
                        value="other"
                      ></p-radioButton>
                    </div>
                  </div>
                </div>

                <!-- Auswahl Kostenträger wenn kein AP vorhanden -->
                <div
                  class="p-field p-col-3"
                  *ngIf="
                    data.invoice_receiver === 'cost_unit' &&
                    costUnitHasNoContactPersons
                  "
                >
                  <label style="font-size: 15px"
                    ><strong>Kostenträger</strong></label
                  >
                  <span>{{ patient.cost_unit.name }}</span>
                </div>

                <!-- Auswahl Kostenträger wenn AP vorhanden sind -->
                <div
                  class="p-field p-col-3"
                  *ngIf="
                    data.invoice_receiver === 'cost_unit' &&
                    !costUnitHasNoContactPersons
                  "
                >
                  <label
                    for="invoice-receiver-cost-unit"
                    style="font-size: 15px; margin: 0 0 8px 0"
                    ><strong>Auswahl</strong></label
                  >
                  <p-dropdown
                    inputId="invoice-receiver-cost-unit"
                    name="invoice-receiver-cost-unit"
                    [options]="costUnitReceiverOptions"
                    [(ngModel)]="data.cost_unit_receiver"
                    (onChange)="costUnitReceiverSelected()"
                    scrollHeight="500px"
                    appendTo="body"
                  >
                    <ng-template let-receiver pTemplate="item">
                      <div>{{ receiver.label }}</div>
                    </ng-template>
                  </p-dropdown>
                </div>

                <!-- Auswahl Pflegekasse -->
                <div
                  class="p-field p-col-3"
                  *ngIf="data.invoice_receiver === 'care_insurance'"
                >
                  <label><strong>Pflegekasse</strong></label>
                  <a
                    target="_blank"
                    style="color: currentColor"
                    routerLink="/multipliers/{{ patient.care_insurance.id }}"
                  >
                    <span
                      >{{ patient.care_insurance.name }}
                      <i
                        style="font-size: 12px; margin: 0 0 0 5px"
                        class="color-gray pi pi-arrow-up-right"
                      ></i>
                    </span>
                  </a>
                </div>

                <!-- Auswahl Patient -->
                <div
                  class="p-field p-col-3"
                  *ngIf="data.invoice_receiver === 'patient'"
                >
                  <label style="margin: 0 0 8px 0"
                    ><strong>Auswahl</strong></label
                  >
                  <p-dropdown
                    inputId="patient_receiver"
                    name="patient_receiver"
                    [options]="patientReceiverOptions"
                    [(ngModel)]="data.patient_receiver"
                    (onChange)="patientReceiverSelected()"
                    scrollHeight="500px"
                    appendTo="body"
                  >
                    <ng-template let-receiver pTemplate="item">
                      <div>{{ receiver.label }}</div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>

              <!-- Auswahl Individuell -->
              <div
                class="p-fluid p-formgrid p-grid without-border border-top"
                style="padding-top: 10px"
                *ngIf="data.invoice_receiver === 'other'"
              >
                <div class="p-field p-col-4">
                  <label
                    for="individual_form_of_address"
                    style="margin: 0 0 8px 0"
                    ><strong>Anrede</strong></label
                  >
                  <p-dropdown
                    [(ngModel)]="data.individual_form_of_address"
                    [options]="formOfAddress"
                    inputId="individual_form_of_address"
                    name="individual_form_of_address"
                    placeholder="Bitte auswählen"
                    (onChange)="getPreviewLink()"
                  ></p-dropdown>
                </div>

                <div class="p-field p-col-4">
                  <label for="individual_first_name" style="margin: 0 0 8px 0"
                    ><strong>Vorname</strong></label
                  >
                  <input
                    id="individual_first_name"
                    name="individual_first_name"
                    [(ngModel)]="data.individual_first_name"
                    (change)="getPreviewLink()"
                    type="text"
                    pInputText
                  />
                </div>

                <div class="p-field p-col-4">
                  <label for="individual_last_name" style="margin: 0 0 8px 0"
                    ><strong>Nachname</strong></label
                  >
                  <input
                    id="individual_last_name"
                    name="individual_last_name"
                    [(ngModel)]="data.individual_last_name"
                    type="text"
                    (change)="getPreviewLink()"
                    pInputText
                  />
                </div>
              </div>

              <!-- Auswahl Individuell -->
              <div
                class="p-fluid p-formgrid p-grid without-border"
                style="padding: 0"
                *ngIf="data.invoice_receiver === 'other'"
              >
                <div class="p-field p-col-4">
                  <label
                    for="individual_street_house_number"
                    style="margin: 0 0 8px 0"
                    ><strong>Straße / Hausnummer</strong></label
                  >
                  <input
                    id="individual_street_house_number"
                    name="individual_street_house_number"
                    [(ngModel)]="data.individual_street_house_number"
                    type="text"
                    (change)="getPreviewLink()"
                    pInputText
                  />
                </div>

                <div class="p-field p-col-4">
                  <label for="individual_zipcode" style="margin: 0 0 8px 0"
                    ><strong>PLZ</strong></label
                  >
                  <input
                    id="individual_zipcode"
                    name="individual_zipcode"
                    [(ngModel)]="data.individual_zipcode"
                    type="text"
                    (change)="getPreviewLink()"
                    pInputText
                  />
                </div>

                <div class="p-field p-col-4">
                  <label for="individual_city" style="margin: 0 0 8px 0"
                    ><strong>Ort</strong></label
                  >
                  <span class="p-input-icon-right">
                    <!--                <i *ngIf="cityLoading" class="pi pi-spin pi-spinner"></i>-->
                    <input
                      id="individual_city"
                      name="individual_city"
                      [(ngModel)]="data.individual_city"
                      type="text"
                      pInputText
                      (change)="getPreviewLink()"
                    />
                  </span>
                </div>
              </div>

              <!-- Auswahl Individuell -->
              <div
                class="p-fluid p-formgrid p-grid without-border"
                style="padding: 0"
                *ngIf="
                  data.invoice_receiver === 'other' &&
                  data.sent_type === 'email'
                "
              >
                <div class="p-field p-col-4">
                  <label for="individual_email" style="margin: 0 0 8px 0"
                    ><strong>E-Mail</strong></label
                  >
                  <input
                    id="individual_email"
                    name="individual_email"
                    [(ngModel)]="data.individual_email"
                    type="text"
                    pInputText
                    (change)="getPreviewLink()"
                    #individual_email="ngModel"
                    email
                    [class.p-invalid]="
                      individual_email.invalid &&
                      (individual_email.dirty || individual_email.touched)
                    "
                  />
                  <div
                    *ngIf="
                      individual_email.invalid &&
                      (individual_email.dirty || individual_email.touched)
                    "
                    class="p-invalid"
                  >
                    <div *ngIf="individual_email.errors?.email">
                      <small>Bitte geben Sie eine E-Mail ein</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Abrechnungsstelle -->
            <div class="proof-shipping-item">
              <div class="p-fluid p-formgrid p-grid without-border">
                <div class="p-field p-col-8">
                  <label
                    ><i class="pi pi-home"></i>
                    <strong>Abrechnungsstelle</strong></label
                  >
                  <div class="p-formgroup-inline">
                    <div
                      class="p-field-radiobutton"
                      *ngIf="
                        (data.invoice_receiver === 'patient' ||
                          data.invoice_receiver === 'other') &&
                        patient.first_sepa
                      "
                    >
                      <p-radioButton
                        label="SEPA"
                        name="accounting_type"
                        [(ngModel)]="data.accounting_type"
                        value="SEPA"
                        (onClick)="getPreviewLink()"
                      ></p-radioButton>
                    </div>

                    <div class="p-field-radiobutton" style="gap: 5px">
                      <p-radioButton
                        label="Leistungsnehmer"
                        name="accounting_type"
                        [(ngModel)]="data.accounting_type"
                        value="patient"
                        (onClick)="getPreviewLink()"
                      ></p-radioButton>

                      <i
                        class="pi pi-exclamation-triangle color-alert"
                        pTooltip="SEPA"
                        *ngIf="
                          (data.invoice_receiver === 'patient' ||
                            data.invoice_receiver === 'other') &&
                          patient.first_sepa
                        "
                      ></i>
                    </div>
                    <div class="p-field-radiobutton" style="gap: 5px">
                      <p-radioButton
                        label="Abrechnungszentrum"
                        name="accounting_type"
                        [(ngModel)]="data.accounting_type"
                        value="accounting_center"
                        (onClick)="getPreviewLink()"
                      ></p-radioButton>

                      <i
                        class="pi pi-exclamation-triangle color-alert"
                        pTooltip="SEPA"
                        *ngIf="
                          (data.invoice_receiver === 'patient' ||
                            data.invoice_receiver === 'other') &&
                          patient.first_sepa
                        "
                      ></i>
                    </div>
                    <div
                      class="p-field-radiobutton"
                      *ngIf="data.invoice_receiver === 'cost_unit'"
                    >
                      <p-radioButton
                        label="Kostenträger"
                        name="accounting_type"
                        [(ngModel)]="data.accounting_type"
                        value="cost_unit"
                        (onClick)="getPreviewLink()"
                      ></p-radioButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Versandoptionen -->
            <div class="proof-shipping-item">
              <div class="p-fluid p-formgrid p-grid without-border">
                <div class="p-field p-col-12">
                  <label>
                    <i class="pi pi-send"></i> <strong>Versandoptionen</strong>
                  </label>
                </div>

                <div class="p-field p-col-6">
                  <label><strong>Versand per</strong></label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        (onClick)="buildAddressReceiverOptions()"
                        label="E-Mail"
                        name="sent_type"
                        value="email"
                        [(ngModel)]="data.sent_type"
                      ></p-radioButton>
                    </div>
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        (onClick)="buildAddressReceiverOptions()"
                        label="Post"
                        name="sent_type"
                        value="post"
                        [(ngModel)]="data.sent_type"
                      ></p-radioButton>
                    </div>
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        (onClick)="buildAddressReceiverOptions()"
                        label="Kein Versand"
                        name="sent_type"
                        value="no_sending"
                        [(ngModel)]="data.sent_type"
                      ></p-radioButton>
                    </div>
                  </div>
                </div>

                <!-- Mail Empfänger bei Pflegekasse -->
                <div
                  class="p-field p-col-3"
                  *ngIf="
                    data.invoice_receiver === 'care_insurance' &&
                    data.sent_type === 'email'
                  "
                >
                  <label><strong>E-Mail</strong></label>
                  <span *ngIf="patient.care_insurance.email">{{
                    patient.care_insurance.email
                  }}</span>
                  <span
                    class="color-alert"
                    *ngIf="!patient.care_insurance.email"
                    >Keine E-Mail</span
                  >
                </div>

                <!-- Mail Empfänger bei Individuell -->
                <div
                  class="p-field p-col-3"
                  *ngIf="
                    data.invoice_receiver === 'other' &&
                    data.sent_type === 'email'
                  "
                >
                  <label><strong>E-Mail</strong></label>
                  <span *ngIf="data.individual_email">{{
                    data.individual_email
                  }}</span>
                  <span class="color-alert" *ngIf="!data.individual_email"
                    >Bitte E-Mail eintragen</span
                  >
                </div>

                <!-- Mail Empfänger bei Kostenträger wenn kein AP vorhanden ist -->
                <div
                  class="p-field p-col-3"
                  *ngIf="
                    data.invoice_receiver === 'cost_unit' &&
                    data.sent_type === 'email' &&
                    costUnitHasNoContactPersons
                  "
                >
                  <label><strong>E-Mail</strong></label>
                  <span *ngIf="patient.cost_unit.email">{{
                    patient.cost_unit.email
                  }}</span>
                  <span class="color-alert" *ngIf="!patient.cost_unit.email"
                    >Keine E-Mail</span
                  >
                </div>

                <!-- Mail Empfänger bei Kostenträger wenn AP vorhanden sind -->
                <div
                  class="p-field p-col-3"
                  *ngIf="
                    data.invoice_receiver === 'cost_unit' &&
                    data.sent_type === 'email' &&
                    !costUnitHasNoContactPersons
                  "
                >
                  <label
                    style="margin: 0 0 8px 0"
                    for="cost-unit-email-receiver"
                    ><strong>E-Mail</strong></label
                  >
                  <p-dropdown
                    inputId="cost-unit-email-receiver"
                    name="cost-unit-email-receiver"
                    [options]="costUnitEmailReceiverOptions"
                    [(ngModel)]="data.cost_unit_email_receiver"
                    scrollHeight="500px"
                    appendTo="body"
                    (onChange)="getPreviewLink()"
                  >
                    <ng-template let-receiver pTemplate="item">
                      <div>{{ receiver.label }}</div>
                      <span>{{ receiver.email }}</span>
                    </ng-template>
                  </p-dropdown>
                </div>

                <!-- Empfänger wenn Leistungsnehmer ausgewählt ist -->
                <div
                  class="p-field p-col-3"
                  *ngIf="
                    data.invoice_receiver === 'patient' &&
                    (data.sent_type === 'email' || data.sent_type === 'post')
                  "
                >
                  <label for="address-receiver" style="margin: 0 0 8px 0"
                    ><strong>Empfänger</strong></label
                  >
                  <p-dropdown
                    inputId="address-receiver"
                    name="address-receiver"
                    (onChange)="addressReceiverSelected()"
                    [options]="addressReceiverOptions"
                    [(ngModel)]="data.receiver"
                    scrollHeight="500px"
                    appendTo="body"
                  >
                    <ng-template let-receiver pTemplate="item">
                      <div>{{ receiver.label }}</div>
                      <span *ngIf="data.sent_type === 'email'">{{
                        receiver.email
                      }}</span>
                    </ng-template>
                  </p-dropdown>
                </div>

                <!-- CC -->
                <div class="p-field p-col-3" *ngIf="data.sent_type === 'email'">
                  <label style="margin: 0 0 8px 0"
                    ><strong>CC an</strong></label
                  >
                  <p-multiSelect
                    inputId="cc"
                    placeholder="Optional"
                    [options]="ccOptions"
                    [filter]="false"
                    [showHeader]="false"
                    name="cc"
                    (onChange)="getPreviewLink()"
                    appendTo="body"
                    scrollHeight="400px"
                    [(ngModel)]="data.cc"
                  ></p-multiSelect>
                </div>
              </div>
            </div>

            <!-- Nachweis an Kunde -->
            <div
              class="proof-shipping-item"
              *ngIf="
                data.sent_type === 'email' &&
                data.type === 'CONSULTING' &&
                data.invoice_receiver !== 'patient'
              "
            >
              <div class="p-fluid p-formgrid p-grid without-border">
                <div class="p-field p-col-6">
                  <label>
                    <i class="pi pi-send"></i> <strong>Nachweis</strong>
                  </label>

                  <div class="p-field-checkbox">
                    <p-checkbox
                      name="send_be_to_customer"
                      [binary]="true"
                      label="Nachweis an Kunden senden"
                      [(ngModel)]="data.send_be_to_customer"
                    ></p-checkbox>
                  </div>
                </div>

                <div class="p-field p-col-3" *ngIf="data.send_be_to_customer">
                  <label for="address-receiver" style="margin: 0 0 8px 0"
                    ><strong>Nachweis Empfänger *</strong></label
                  >
                  <p-dropdown
                    inputId="address-receiver"
                    name="address-receiver-nachweis"
                    [options]="addressReceiverOptions"
                    [(ngModel)]="data.receiver_nachweis"
                    scrollHeight="500px"
                    [required]="true"
                    appendTo="body"
                  >
                    <ng-template let-receiver pTemplate="item">
                      <div>{{ receiver.label }}</div>
                      <span>{{ receiver.email }}</span>
                    </ng-template>
                  </p-dropdown>
                </div>

                <!-- CC -->
                <div *ngIf="data.send_be_to_customer" class="p-field p-col-3">
                  <label style="margin: 0 0 8px 0"
                    ><strong>Nachweis CC an</strong></label
                  >
                  <p-multiSelect
                    inputId="cc"
                    placeholder="Optional"
                    [options]="ccOptions"
                    [filter]="false"
                    [showHeader]="false"
                    name="cc_nachweis"
                    appendTo="body"
                    scrollHeight="400px"
                    [(ngModel)]="data.cc_nachweis"
                  ></p-multiSelect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-footer" *ngIf="!loading">
    <i></i>

    <div class="dialog-footer-action-container">
      <a
        *ngIf="data.sent_type === 'email'"
        target="_blank"
        (click)="clickedOnPreviewMail = true"
        [href]="mailPreviewSrc"
      >
        <button
          pButton
          label="Mail Vorschau"
          type="button"
          [pTooltip]="isMailPreviewDisabled() ? 'Keine E-Mail ausgewählt' : ''"
          [disabled]="isMailPreviewDisabled()"
          icon="pi pi-eye"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <a target="_blank" (click)="clickedOnPreviewPdf = true" [href]="pdfSrc">
        <button
          pButton
          label="PDF Vorschau"
          type="button"
          icon="pi pi-eye"
          iconPos="left"
          class="p-button p-button-info"
        ></button>
      </a>

      <p-splitButton
        *ngIf="data.id && data.status === 'READY'"
        label="Zwischenspeichern"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        (onClick)="store()"
        [model]="items"
        appendTo="body"
        [disabled]="submitted"
      >
      </p-splitButton>
    </div>
  </div>
</form>
