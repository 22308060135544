import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { BehaviorSubject, Observable } from 'rxjs'
import { AuthModel } from '../models/user/auth.model'
import { HelperService } from './helper.service'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isLoggedIn = new BehaviorSubject<null | boolean>(null)
  public isLoggedIn$ = this.isLoggedIn.asObservable()

  private user: AuthModel | null = null

  private permissions: string[] = []

  constructor(private http: HttpClient, private helperService: HelperService) {
    // Holt sich beim reload der Seite einen CSRF Cookie.
    this.http.get(`${environment.api}/csrf-cookie`).subscribe()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.permissions = data.permissions
    })
  }

  /**
   * Prüft ob der Benutzer ein Super Admin ist.
   */
  public isSuperAdmin(): boolean {
    const currentUser = this.getUser()

    if (currentUser) {
      return currentUser.super_admin
    }

    return false
  }

  /**
   * Prüft ob der Benutzer die erforderlichen Rechte hat.
   */
  public can(value: string): boolean {
    if (this.isSuperAdmin()) {
      return true
    }

    const [namespace, permission] = value.split('.')

    return this.permissions[namespace]?.includes(permission)
  }

  /**
   * Prüft ob der Benutzer eins der erforderlichen Rechte hat.
   */
  public canAny(...values: string[]): boolean {
    for (const value of values) {
      if (this.can(value)) {
        return true
      }
    }

    return false
  }

  /**
   * Prüft ob der Benutzer alle der erforderlichen Rechte hat.
   */
  public canAll(...values: string[]): boolean {
    for (const value of values) {
      if (!this.can(value)) {
        return false
      }
    }

    return false
  }

  public loginWith2fa(code: string, challenge_token: string): Observable<any> {
    return this.http.post<any>(`${environment.api}/login-2fa`, {
      challenge_token,
      code,
    })
  }

  public login(email: string, password: string): Observable<AuthModel> {
    return this.http.post<AuthModel>(`${environment.api}/login`, {
      email,
      password,
    })
  }

  public updateCaregiverAccount(
    caregiverId: string | number,
    values: any
  ): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/account/caregiver/${caregiverId}`,
      {
        ...values,
      }
    )
  }

  public updateMultiplierAccount(
    multiplierId: string | number,
    values: any
  ): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/account/multiplier/${multiplierId}`,
      {
        ...values,
      }
    )
  }

  public updateCustomerAccount(
    customerId: string | number,
    values: any
  ): Observable<[]> {
    return this.http.put<[]>(
      `${environment.api}/account/customer/${customerId}`,
      {
        ...values,
      }
    )
  }

  public loginUsingId(id: any): Observable<AuthModel> {
    return this.http.post<AuthModel>(`${environment.api}/login/${id}`, {})
  }

  public resetPassword(email: string): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/reset-password`, {
      email,
    })
  }

  public confirmResetPassword(data: any): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/confirm-reset-password`, {
      ...data,
    })
  }

  public changeInitialPassword(data: any): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/change-initial-password`, {
      ...data,
    })
  }

  public logout(impersonatedFrom: AuthModel | null = null): Observable<[]> {
    return this.http.delete<[]>(`${environment.api}/logout`, {
      body: {
        impersonatedFrom,
      },
    })
  }

  public authenticate(): Observable<AuthModel> {
    return this.http.get<AuthModel>(`${environment.api}/authenticate`)
  }

  public get2faEnabled(): Observable<any> {
    return this.http.get<any>(`${environment.api}/2fa-enabled`)
  }

  public prepare2fa(): Observable<any> {
    return this.http.get<any>(`${environment.api}/2fa-prepare`)
  }

  public confirm2fa(code: string): Observable<any> {
    return this.http.post<any>(`${environment.api}/2fa-confirm`, { code })
  }

  public disable2fa(): Observable<any> {
    return this.http.delete<any>(`${environment.api}/2fa`)
  }

  public setUser(user: AuthModel | null): void {
    this.user = user
  }

  public getUser(): AuthModel | null {
    return this.user
  }

  public getImpersonatedFrom(): AuthModel | null {
    const impersonatedFrom = localStorage.getItem('impersonated_from')

    if (impersonatedFrom) {
      return JSON.parse(impersonatedFrom || '{}')
    }

    return null
  }

  public setImpersonation(): void {
    localStorage.setItem('impersonated_from', JSON.stringify(this.getUser()))
  }

  public removeImpersonation(): void {
    localStorage.removeItem('impersonated_from')
  }

  public setIsLoggedIn(isLoggedIn: null | boolean): void {
    this.isLoggedIn.next(isLoggedIn)
  }

  public isAuthenticated(): boolean {
    return this.isLoggedIn.value === true
  }

  public loginAlreadyChecked(): boolean {
    return this.isLoggedIn.value !== null
  }
}
