import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import * as dayjs from 'dayjs'
import { ConfirmationService } from 'primeng/api'
import { HelperService } from '../../../services/helper.service'
import { CarDamageService } from '../../../services/car-damage.service'
import {
  UserSystemModel,
  UserSystemSimpleModel,
} from '../../../models/user/user-system.model'
import { UserSystemService } from '../../../services/user-system.service'
import { AuthService } from '../../../services/auth.service'
import { CarDamageModel } from '../../../models/car/car-damage.model'
import { DocumentService } from '../../../services/document.service'
import { AddDocumentDialogComponent } from '../add-document-dialog/add-document-dialog.component'
import { MediaModel } from '../../../models/document/media.model'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { SearchService } from '../../../services/search.service'
import { CarService } from '../../../services/car.service'
import { CarsDetailGeneralModel } from '../../../models/car/cars-detail-general.model'
import { CarGarageAppointmentModel } from '../../../models/car/car-garage-appointment.model'
import { CarGarageAppointmentService } from '../../../services/car-garage-appointment.service'

@Component({
  selector: 'app-add-car-garage-appointment-dialog',
  templateUrl: './add-car-garage-appointment-dialog.component.html',
})
export class AddCarGarageAppointmentDialogComponent
  implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null

  public data: any = {}
  public currentMileage: any = null
  public values: CarGarageAppointmentModel = new CarGarageAppointmentModel()

  public submitted = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private authService: AuthService,
    private dialogService: DialogService,
    private searchService: SearchService,
    private config: DynamicDialogConfig,
    private carDamageService: CarDamageService,
    private carGarageAppointmentService: CarGarageAppointmentService,
    private carService: CarService,
    private userSystemService: UserSystemService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    public documentService: DocumentService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    if (this.data.isEdit) {
      this.loadData()
    } else {
      this.loadCarDetails()
    }

    this.setTabIndexForIconButtons()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    if (this.values.mileage) {
      if (this.values.mileage < this.currentMileage) {
        alert('Der KM-Stand ist kleiner als vorher!')

        return
      }
    }

    this.submitted = true

    const subscription = this.data.isEdit
      ? this.carGarageAppointmentService.update(
          this.data.garage_appointment_id,
          this.values
        )
      : this.carGarageAppointmentService.store(this.data.car_id, this.values)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.GarageAppointmentChanged)
        this.ref.close()
        this.toastService.success(
          'Termin gespeichert',
          'Der Termin wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  private setTabIndexForIconButtons(): void {
    setTimeout(() => {
      const elements = document.querySelectorAll('.p-button-icon-only')

      elements.forEach((element) => {
        element.setAttribute('tabindex', '-1')
      })
    }, 200)
  }

  private loadData(): void {
    this.carGarageAppointmentService
      .load(this.data.garage_appointment_id)
      .subscribe((response: CarGarageAppointmentModel) => {
        this.values = response
        this.values.date = dayjs(response.date).format('DD.MM.YYYY')
      })
  }

  private loadCarDetails(): void {
    this.carService
      .load(this.data.car_id)
      .subscribe((response: CarsDetailGeneralModel) => {
        if (response.current_mileage) {
          this.currentMileage = response.current_mileage.mileage
        }
      })
  }

  public openAddDocumentDialog(): void {
    const ref = this.dialogService.open(AddDocumentDialogComponent, {
      data: {
        // Standardmäßig wird das Dokument auf die ID 1 gebucht.
        type_id: this.values.id ?? 1,
        car_garage_appointment: this.values,
        type: 'car_garage_appointment',
        default_document_type: 'misc',
      },
      header: 'Dokument hochladen',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
    })

    ref.onClose.subscribe((document: any) => {
      if (document) {
        this.values.media.push(document.media)
      }
    })
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Termin wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        this.carGarageAppointmentService
          .delete(this.data.garage_appointment_id)
          .subscribe(
            () => {
              this.submittedDelete = false
              this.eventbus.emit(GlobalEvent.GarageAppointmentChanged)
              this.ref.close()
              this.toastService.success(
                'Termin gelöscht',
                'Der Termin wurde erfolgreich gelöscht'
              )
            },
            () => {
              this.submittedDelete = false
              this.toastService.error(
                'Löschen fehlgeschlagen',
                'Der Eintrag konnte nicht gelöscht werden'
              )
            }
          )
      },
      reject: () => {},
    })
  }
}
