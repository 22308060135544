<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-8">
        <label for="name">Name *</label>
        <input
          id="name"
          name="name"
          type="text"
          [(ngModel)]="values.name"
          pInputText
          [required]="true"
          #name="ngModel"
          [class.p-invalid]="name.invalid && (name.dirty || name.touched)"
        />
      </div>

      <div class="p-field p-col-4">
        <label for="cost">Kosten *</label>
        <p-inputNumber
          mode="currency"
          currency="EUR"
          locale="de-DE"
          inputId="cost"
          name="cost"
          [(ngModel)]="values.cost"
          [required]="true"
          #cost="ngModel"
          [class.p-invalid]="cost.invalid && (cost.dirty || cost.touched)"
        ></p-inputNumber>
      </div>

      <div class="p-field p-col-6">
        <label for="from">von</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="from"
          name="from"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.from_string"
          dataType="string"
          [showIcon]="true"
          [showButtonBar]="true"
        ></p-calendar>
      </div>

      <div class="p-field p-col-6">
        <label for="to">bis</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="to"
          name="to"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.to_string"
          dataType="string"
          [showIcon]="true"
          [showButtonBar]="true"
        ></p-calendar>
      </div>

      <div class="p-field p-col-12">
        <label for="comment">Bemerkung</label>
        <textarea
          id="comment"
          name="comment"
          [(ngModel)]="values.comment"
          [rows]="4"
          pInputTextarea
        ></textarea>
      </div>
    </div>
  </div>

  <div class="dialog-form-footer">
    <i *ngIf="!data.isEdit"></i>
    <i
      class="pi pi-trash"
      *ngIf="data.isEdit"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Faktor löschen'"
      (click)="remove($event)"
    ></i>

    <button
      (click)="save()"
      pButton
      [label]="data.isEdit ? 'Speichern' : 'Erstellen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
