<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>Alle aktiven PKW</span>

    <div class="content-header-buttons">
      <p-checkbox
        label="ohne Sonstige"
        [binary]="true"
        (onChange)="loadCars(false)"
        [(ngModel)]="withoutOthers"
        name="withoutOthers"
      ></p-checkbox>

      <a routerLink="/cars/new" *ngIf="authService.can('PKW.PKW erstellen')">
        <button
          pButton
          label="Neuer PKW"
          type="button"
          icon="pi pi-plus"
          iconPos="left"
          class="p-button-outlined"
        ></button>
      </a>

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
        *ngIf="cars.length > 0"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div
    class="content-body"
    *ngIf="cars.length == 0"
    [class.content-loading]="contentLoading"
  >
    <span class="content-no-message">Keine PKW vorhanden</span>
  </div>

  <div
    class="content-body content-body-full-height-with-tabs without-padding"
    [class.content-loading]="contentLoading"
    *ngIf="cars.length > 0"
  >
    <p-table
      #dt
      [value]="cars"
      [globalFilterFields]="[
        'id',
        'brand',
        'model',
        'license_plate',
        'current_assignment.assignable.first_name',
        'current_assignment.assignable.last_name'
      ]"
      scrollHeight="100%"
      [scrollable]="true"
      [(first)]="firstPage"
      [rowHover]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} PKW"
      [rows]="50"
      [paginator]="true"
      stateStorage="local"
      stateKey="state-cars-list-2"
      sortMode="multiple"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="max-width: 100px" pSortableColumn="tuv_status.status">
            TÜV <p-sortIcon field="tuv_status.status"></p-sortIcon>
          </th>
          <th
            style="max-width: 100px"
            pSortableColumn="inspection_status.status"
          >
            Inspektion
            <p-sortIcon field="inspection_status.status"></p-sortIcon>
          </th>
          <th style="min-width: 300px" pSortableColumn="brand">
            Auto <p-sortIcon field="brand"></p-sortIcon>
          </th>
          <th pSortableColumn="license_plate">
            Kennzeichen <p-sortIcon field="license_plate"></p-sortIcon>
          </th>
          <th pSortableColumn="initial_registration">
            EZ <p-sortIcon field="initial_registration"></p-sortIcon>
          </th>
          <th pSortableColumn="current_mileage.mileage">
            KM <p-sortIcon field="current_mileage.mileage"></p-sortIcon>
          </th>
          <th style="max-width: 140px" pSortableColumn="open_damage.type_text">
            Schaden
            <p-sortIcon field="open_damage.type_text"></p-sortIcon>
          </th>
          <th style="max-width: 100px" pSortableColumn="group_string">
            Gruppe
            <p-sortIcon field="group_string"></p-sortIcon>
          </th>
          <th pSortableColumn="current_assignment.assignable.last_name">
            Zugewiesen an
            <p-sortIcon
              field="current_assignment.assignable.last_name"
            ></p-sortIcon>
          </th>
          <th pSortableColumn="current_place">
            Standort <p-sortIcon field="current_place"></p-sortIcon>
          </th>
          <th
            pTooltip="Privatnutzung"
            style="max-width: 100px"
            tooltipPosition="left"
            pSortableColumn="private_use_allowed"
          >
            PN <p-sortIcon field="private_use_allowed"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-car>
        <tr>
          <td style="max-width: 100px">
            <div class="text-center-container" *ngIf="car.tuv_status.status">
              <i
                [pTooltip]="car.tuv_status.tooltip"
                [class.color-alert]="car.tuv_status.status === 3"
                [class.color-orange]="car.tuv_status.status === 2"
                [class.color-gray]="car.tuv_status.status === 1"
                class="pi pi-exclamation-triangle"
              ></i>
            </div>
          </td>
          <td style="max-width: 100px">
            <div
              class="text-center-container"
              *ngIf="car.inspection_status.status"
            >
              <i
                [pTooltip]="car.inspection_status.tooltip"
                [class.color-alert]="car.inspection_status.status === 3"
                [class.color-orange]="car.inspection_status.status === 2"
                [class.color-gray]="car.inspection_status.status === 1"
                class="pi pi-exclamation-triangle"
              ></i>

              {{ car.inspection_status.type }}
            </div>
          </td>
          <td style="min-width: 300px">{{ car.brand }} {{ car.model }}</td>
          <td>
            <a class="color-main1" routerLink="/cars/{{ car.id }}">
              {{ car.license_plate }}
            </a>
          </td>
          <td>{{ car.initial_registration_string || "-" }}</td>

          <td *ngIf="car.current_mileage?.mileage">
            {{ car.current_mileage.mileage | number }}
          </td>
          <td *ngIf="!car.current_mileage?.mileage">-</td>

          <td style="max-width: 140px">
            <div
              class="text-center-container"
              [pTooltip]="car.open_damage?.comment"
              tooltipStyleClass="tooltip-wider"
              *ngIf="car.open_damage?.status === 'OPEN'"
            >
              <i
                class="icon-car-crash"
                *ngIf="car.open_damage.type === 'CRASH'"
              ></i>
              <i
                class="icon-car-damage"
                *ngIf="car.open_damage.type !== 'CRASH'"
              ></i>
              {{ car.open_damage.type_text }}
            </div>
          </td>

          <td style="max-width: 100px">
            {{ car.group_string }}
          </td>

          <td *ngIf="!car.current_assignment">-</td>
          <td *ngIf="car.current_assignment">
            <a
              *ngIf="
                car.current_assignment.assignable_type ===
                'App\\Models\\Caregiver'
              "
              routerLink="/caregivers/{{
                car.current_assignment.assignable_id
              }}"
            >
              {{ car.current_assignment.assignable.first_name }}
              {{ car.current_assignment.assignable.last_name }}
            </a>

            <a
              *ngIf="
                car.current_assignment.assignable_type ===
                'App\\Models\\UserSystem'
              "
              routerLink="/administration/users/{{
                car.current_assignment.assignable_id
              }}"
            >
              {{ car.current_assignment.assignable.first_name }}
              {{ car.current_assignment.assignable.last_name }}
            </a>
          </td>

          <td>{{ car.current_place || "-" }}</td>
          <td style="max-width: 100px">
            <i
              class="pi pi-check color-main1"
              *ngIf="car.private_use_allowed === 'Ja'"
            ></i>
            <i
              class="pi pi-times color-alert"
              *ngIf="car.private_use_allowed === 'Nein'"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
