import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DashboardService } from '../../services/dashboard.service'
import { DashboardModel } from '../../models/dashboard/dashboard.model'
import {
  PhoneCallsListItemModel,
  PhoneCallsListModel,
} from '../../models/phone-call/phone-calls-list.model'
import { PhoneCallEditDialogComponent } from '../../components/dialogs/phone-call-edit-dialog/phone-call-edit-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from '../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { TitleService } from '../../services/title.service'
import { PhoneCallService } from '../../services/phone-call.service'
import { TodoModel } from '../../models/todo/todo.model'
import { HistoryListModel } from '../../models/history/history-list.model'
import { HistoryManualDialogComponent } from '../../components/dialogs/history-manual-dialog/history-manual-dialog.component'
import { ShowTodoDialogComponent } from '../../components/dialogs/show-todo-dialog/show-todo-dialog.component'
import { PatientModel } from '../../models/customer-patient/patient.model'
import { CaregiverDetailModel } from '../../models/caregiver/caregiver-detail.model'
import { UserSystemModel } from '../../models/user/user-system.model'
import { VacationIllModel } from '../../models/vacation-ill/vacation-ill.model'
import { AuthService } from '../../services/auth.service'
import { Router } from '@angular/router'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as weekOfYear from 'dayjs/plugin/weekOfYear'
import * as utc from 'dayjs/plugin/utc'
import { debounce } from 'lodash-es'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, OnDestroy {
  // TODO: alle optionen zusammenfassen irgendwie...
  basicOptions: any = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        onClick: () => {
          return
        },
        align: 'end',
        labels: {
          color: '#495057',
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      x: {
        ticks: {
          color: '#495057',
        },
        grid: {
          display: false,
          color: '#ebedef',
        },
      },
      y: {
        ticks: {
          stepSize: 1,
          color: '#495057',
        },
        grid: {
          display: false,
          color: '#ebedef',
        },
      },
    },
  }

  basicOptionsConsulting: any = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        onClick: () => {
          return
        },
        align: 'end',
        labels: {
          color: '#495057',
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      x: {
        ticks: {
          color: '#495057',
        },
        grid: {
          display: false,
          color: '#ebedef',
        },
      },
      y: {
        // min: 0,
        ticks: {
          stepSize: 5,
          color: '#495057',
        },
        grid: {
          display: false,
          color: '#ebedef',
        },
      },
    },
  }

  basicOptionsIll: any = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        onClick: () => {
          return
        },
        align: 'end',
        labels: {
          color: '#495057',
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      x: {
        ticks: {
          color: '#495057',
        },
        grid: {
          display: false,
          color: '#ebedef',
        },
      },
      y: {
        // min: 0,
        ticks: {
          stepSize: 5,
          color: '#495057',
        },
        grid: {
          display: false,
          color: '#ebedef',
        },
      },
    },
  }

  basicOptionsBillableHours: any = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        onClick: () => {
          return
        },
        align: 'end',
        labels: {
          color: '#495057',
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      x: {
        ticks: {
          color: '#495057',
        },
        grid: {
          display: false,
          color: '#ebedef',
        },
      },
      y: {
        // min: 0,
        ticks: {
          stepSize: 100,
          color: '#495057',
        },
        grid: {
          display: false,
          color: '#ebedef',
        },
      },
    },
  }

  basicOptionsUtilisation: any = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        onClick: () => {
          return
        },
        align: 'end',
        labels: {
          color: '#495057',
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      x: {
        ticks: {
          color: '#495057',
        },
        grid: {
          display: false,
          color: '#ebedef',
        },
      },
      y: {
        // min: 0,
        ticks: {
          stepSize: 1,
          color: '#495057',
        },
        grid: {
          display: false,
          color: '#ebedef',
        },
      },
    },
  }

  public amountBirthdaysToday = 0
  public data: DashboardModel = new DashboardModel()
  public changeDashboardVisible = false

  private eventBusSubscription: Subscription = new Subscription()

  public types = [
    'pause_ending',
    'todos',
    'birthdays',
    'vacation_ill',
    'important_histories',
    'new_created',
    'phone_calls',
  ]

  public typeSizes: any = {}

  public typeNames = {
    pause_ending: 'Pausen die bald Enden',
    todos: 'Offene Todos',
    birthdays: 'Geburtstage / Jubiläum',
    vacation_ill: 'Urlaub / AU / Wunschfrei',
    important_histories: 'Wichtige Ereignisse',
    new_created: 'Neu erstellt',
    phone_calls: 'Letzte Telefonate',

    birthdays_weekly: 'Geburtstage Mitarbeiter',
    accounting_returns: 'Rückläufer',
    car_without_assignment: 'PKW ohne Zuweisung',
    consultings: 'Beratungseinsätze',
    invoices: 'Rechnungen',
    budgets: 'Budgets',
    ills: 'AU-Verlauf',
    ills_table: 'Krankmeldungen',
    billable_hours: 'Abrechenbare Stunden',
    utilisation: 'Auslastung',
  }

  public visible = {
    pause_ending: false,
    todos: false,
    birthdays: false,
    vacation_ill: false,
    important_histories: false,
    new_created: false,
    phone_calls: false,

    car_without_assignment: false,
    birthdays_weekly: false,
    accounting_returns: false,
    budgets: false,
    consultings: false,
    ills: false,
    ills_table: false,
    invoices: false,
    billable_hours: false,
    utilisation: false,
  }

  public loadings: any = {}

  // Diese Einstellung wollen wir nicht im LocalStorage speichern.
  public onlyToday = false

  public settings = {
    only_assigned: false,
    invoice_type: 'DATE_SERVICE',
  }

  public currentDate: any = null
  public selectedDateForInvoices: any = null
  public selectedDateForBudgets: any = null
  public selectedDateForBudgetsLastMonth: any = null

  constructor(
    private dashboardService: DashboardService,
    private eventbus: EventBusService,
    private router: Router,
    public authService: AuthService,
    public phoneCallService: PhoneCallService,
    private titleService: TitleService,
    private dialogService: DialogService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(weekOfYear)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    const user = this.authService.getUser()

    this.loadInvoices = debounce(this.loadInvoices, 400)
    this.storeSettings = debounce(this.storeSettings, 1000)

    this.currentDate = dayjs()
    this.selectedDateForInvoices = dayjs().subtract(1, 'month')
    this.selectedDateForBudgets = dayjs().subtract(1, 'month')
    this.selectedDateForBudgetsLastMonth = dayjs().subtract(2, 'month')

    if (this.authService.isSuperAdmin()) {
      this.types.push('birthdays_weekly')
      this.types.push('accounting_returns')
      this.types.push('car_without_assignment')
      this.types.push('consultings')
      this.types.push('invoices')
      this.types.push('budgets')
      this.types.push('ills')
      this.types.push('ills_table')
      this.types.push('billable_hours')
      this.types.push('utilisation')
    }

    // Die Startseite einer Betreuungskraft ist nicht auf /, sondern auf /daten.
    if (user && user.is_caregiver) {
      this.router.navigate(['dienstplan'])

      return
    }

    // Die Startseite eines Multiplikators ist nicht auf /, sondern auf /patienten.
    if (user && user.is_multiplier) {
      this.router.navigate(['patienten'])

      return
    }

    // Die Startseite eines Kunden ist nicht auf /, sondern auf /meine-übersicht.
    if (user && user.is_customer) {
      this.router.navigate(['meine-übersicht'])

      return
    }

    this.titleService.setTitle('Übersicht')

    this.initLocalSizes()
    this.initLocalSettings()
    this.initDragDrop()

    this.loadSettings()

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  openChangeDashboardDialog(): void {
    this.changeDashboardVisible = true
  }

  private initLocalSizes(): void {
    const storedSizes = localStorage.getItem('dashboard-sizes')

    const sizes = storedSizes ? JSON.parse(storedSizes) : {}

    for (const type of this.types) {
      this.typeSizes[type] = sizes[type] || 6
    }
  }

  private initLocalSettings(): void {
    // Falls noch nichts im Speicher vorhanden ist, setze den Default wert.
    if (localStorage.getItem('dashboard-settings') === null) {
      localStorage.setItem('dashboard-settings', JSON.stringify(this.settings))
    } else {
      // Falls aber was vorhanden ist, werden die Settings aus dem Speicher genommen.
      const savedSettings = JSON.parse(
        localStorage.getItem('dashboard-settings') || '{}'
      )

      // Es muss geprüft werden, ob die Menge der gespeicherten Daten übereinstimmt.
      // Falls nicht, wurde bei der Entwicklung ein neues Setting hinzugefügt
      // und die Daten werden nun zurückgesetzt.
      if (
        Object.keys(savedSettings).length !== Object.keys(this.settings).length
      ) {
        localStorage.setItem(
          'dashboard-settings',
          JSON.stringify(this.settings)
        )
      } else {
        this.settings = JSON.parse(
          localStorage.getItem('dashboard-settings') || '{}'
        )
      }
    }
  }

  public onRowReorder(event: any): void {
    this.storeOrders()
  }

  private initDragDrop(): void {
    const storedOrders = localStorage.getItem('dashboard-orders')
    const parsedOrders = storedOrders ? JSON.parse(storedOrders) : null

    // Es muss geprüft werden, ob die Menge der gespeicherten Daten übereinstimmt.
    // Falls nicht, wurde bei der Entwicklung ein neues Dashboard hinzugefügt
    // und die Daten werden nun zurückgesetzt.
    if (!parsedOrders || parsedOrders.length !== this.types.length) {
      this.storeOrders()
    } else {
      this.types = parsedOrders
    }
  }

  private storeOrders(): void {
    localStorage.setItem('dashboard-orders', JSON.stringify(this.types))
  }

  private storeSizes(): void {
    localStorage.setItem('dashboard-sizes', JSON.stringify(this.typeSizes))
  }

  public openManualHistoryDialog(data: any): void {
    if (data.already_wished) {
      return
    }

    this.dialogService.open(HistoryManualDialogComponent, {
      data: {
        edit: false,
        history: new HistoryListModel(),
        type: data.type,
        type_id: data.id,
        is_birthday_wish: true,
      },
      header: 'Neuer Eintrag',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '520px',
    })
  }

  private loadPriceChanges(): void {
    this.dashboardService.loadPriceChanges().subscribe((data: any) => {
      this.data.price_changes = data
    })
  }

  private loadSettings(): void {
    this.loadings = {
      pause_ending: true,
      todos: true,
      birthdays: true,
      vacation_ill: true,
      important_histories: true,
      new_created: true,
      phone_calls: true,
      birthdays_weekly: true,
      accounting_returns: true,
      car_without_assignment: true,
      consultings: true,
      invoices: true,
      budgets: true,
      ills: true,
      ills_table: true,
      utilisation: true,
      billable_hours: true,
    }

    this.dashboardService.settings().subscribe((data: any) => {
      this.visible.phone_calls = data?.visible_phone_calls ?? true
      this.visible.new_created = data?.visible_new_created ?? true
      this.visible.todos = data?.visible_todos ?? true
      this.visible.pause_ending = data?.visible_pause_ending ?? true
      this.visible.important_histories =
        data?.visible_important_histories ?? true
      this.visible.vacation_ill = data?.visible_vacation_ill ?? true
      this.visible.birthdays = data?.visible_birthdays ?? true
      this.visible.birthdays_weekly = data?.visible_birthdays_weekly ?? false
      this.visible.accounting_returns =
        data?.visible_accounting_returns ?? false
      this.visible.car_without_assignment =
        data?.visible_car_without_assignment ?? false
      this.visible.consultings = data?.visible_consultings ?? false
      this.visible.invoices = data?.visible_invoices ?? false
      this.visible.budgets = data?.visible_budgets ?? false
      this.visible.ills = data?.visible_ills ?? false
      this.visible.ills_table = data?.visible_ills_table ?? false
      this.visible.utilisation = data?.visible_utilisation ?? false
      this.visible.billable_hours = data?.visible_billable_hours ?? false

      // this.loadPriceChanges()
      this.loadPauseEnding()
      this.loadTodos()
      this.loadImportantHistories()
      this.loadBirthdays()
      this.loadVacationIll()
      this.loadNewCreated()
      this.loadPhoneCalls()
      this.loadBirthdaysWeekly()
      this.loadAccountingReturns()
      this.loadBudgets()
      this.loadCarWithoutAssignment()
      this.loadConsultings()
      this.loadBillableHours()
      this.loadUtilisation()
      this.loadIlls()
      this.loadIllsTable()
      this.loadInvoices()
    })
  }

  private loadPauseEnding(withLoading: boolean = true): void {
    if (!this.visible.pause_ending) {
      return
    }

    if (withLoading) {
      this.loadings.pause_ending = true
    }

    this.dashboardService
      .loadPauseEnding()
      .subscribe((data: PatientModel[]) => {
        this.data.pause_ending = data

        if (withLoading) {
          this.loadings.pause_ending = false
        }
      })
  }

  public loadTodos(withLoading: boolean = true): void {
    if (!this.visible.todos) {
      return
    }

    localStorage.setItem('dashboard-settings', JSON.stringify(this.settings))

    if (withLoading) {
      this.loadings.todos = true
    }

    this.dashboardService
      .loadTodos(this.settings)
      .subscribe((data: TodoModel[]) => {
        this.data.todos = data

        if (withLoading) {
          this.loadings.todos = false
        }
      })
  }

  public storeSettings(): void {
    this.dashboardService.storeSettings(this.visible).subscribe(() => {
      this.loadSettings()
    })
  }

  public loadBirthdays(withLoading: boolean = true): void {
    if (!this.visible.birthdays) {
      return
    }

    if (withLoading) {
      this.loadings.birthdays = true
    }

    this.dashboardService
      .loadBirthdays(this.onlyToday)
      .subscribe((data: any[]) => {
        this.data.birthdays = data

        this.amountBirthdaysToday = data.filter(
          (item: any) => item.is_today
        ).length

        if (withLoading) {
          this.loadings.birthdays = false
        }
      })
  }

  private loadBirthdaysWeekly(withLoading: boolean = true): void {
    if (!this.visible.birthdays_weekly) {
      return
    }

    if (withLoading) {
      this.loadings.birthdays_weekly = true
    }

    this.dashboardService
      .loadBirthdaysWeekly(this.currentDate.format('DD.MM.YYYY'))
      .subscribe((data: any[]) => {
        this.data.birthdays_weekly = data

        if (withLoading) {
          this.loadings.birthdays_weekly = false
        }
      })
  }

  private loadAccountingReturns(withLoading: boolean = true): void {
    if (!this.visible.accounting_returns) {
      return
    }

    if (withLoading) {
      this.loadings.accounting_returns = true
    }

    this.dashboardService.accountingReturns().subscribe((data: any[]) => {
      if (withLoading) {
        this.loadings.accounting_returns = false
      }

      this.data.accounting_returns = data

      const datasets = this.data.accounting_returns.datasets
      const maxValue =
        Math.max(...datasets.flatMap((dataset: any) => dataset.data)) + 5

      this.basicOptions.scales.y.max = maxValue
      // this.chartAccountingReturn.update()
    })
  }

  private loadBudgets(withLoading: boolean = true): void {
    if (!this.visible.budgets) {
      return
    }

    if (withLoading) {
      this.loadings.budgets = true
    }

    this.dashboardService
      .budgets(this.selectedDateForBudgets.format('DD.MM.YYYY'))
      .subscribe((data: any[]) => {
        this.loadings.budgets = false

        this.data.budgets = data
      })
  }

  public updateWeek(amount: number): void {
    this.currentDate = this.currentDate.add(amount, 'week')

    this.loadBirthdaysWeekly(false)
  }

  public updateInvoiceDate(amount: number): void {
    this.selectedDateForInvoices = this.selectedDateForInvoices.add(
      amount,
      'month'
    )

    this.loadings.invoices = true

    this.loadInvoices()
  }

  public updateBudgetDate(amount: number): void {
    this.selectedDateForBudgets = this.selectedDateForBudgets.add(
      amount,
      'month'
    )

    this.selectedDateForBudgetsLastMonth = this.selectedDateForBudgetsLastMonth.add(
      amount,
      'month'
    )

    this.loadings.budgets = true

    this.loadBudgets()
  }

  private loadConsultings(withLoading: boolean = true): void {
    if (!this.visible.consultings) {
      return
    }

    if (withLoading) {
      this.loadings.consultings = true
    }

    this.dashboardService.consultings().subscribe((data: any[]) => {
      if (withLoading) {
        this.loadings.consultings = false
      }

      this.data.consultings = data

      const datasets = this.data.consultings.datasets
      const maxValue =
        Math.max(...datasets.flatMap((dataset: any) => dataset.data)) + 5
      let minValue =
        Math.min(...datasets.flatMap((dataset: any) => dataset.data)) - 5

      if (minValue < 0) {
        minValue = 0
      }

      this.basicOptionsConsulting.scales.y.max = maxValue
      this.basicOptionsConsulting.scales.y.min = minValue
      // this.chartConsulting.update()
    })
  }

  private loadIlls(withLoading: boolean = true): void {
    if (!this.visible.ills) {
      return
    }

    if (withLoading) {
      this.loadings.ills = true
    }

    this.dashboardService.ills().subscribe((data: any[]) => {
      if (withLoading) {
        this.loadings.ills = false
      }

      this.data.ills = data

      const datasets = this.data.ills.datasets
      const maxValue =
        Math.max(...datasets.flatMap((dataset: any) => dataset.data)) + 5
      let minValue =
        Math.min(...datasets.flatMap((dataset: any) => dataset.data)) - 5

      if (minValue < 0) {
        minValue = 0
      }

      this.basicOptionsIll.scales.y.max = maxValue
      this.basicOptionsIll.scales.y.min = minValue
    })
  }

  private loadBillableHours(withLoading: boolean = true): void {
    if (!this.visible.billable_hours) {
      return
    }

    if (withLoading) {
      this.loadings.billable_hours = true
    }

    this.dashboardService.billableHours().subscribe((data: any[]) => {
      if (withLoading) {
        this.loadings.billable_hours = false
      }

      this.data.billable_hours = data

      const datasets = this.data.billable_hours.datasets
      const maxValue =
        Math.max(...datasets.flatMap((dataset: any) => dataset.data)) + 100
      let minValue =
        Math.min(...datasets.flatMap((dataset: any) => dataset.data)) - 100

      if (minValue < 0) {
        minValue = 0
      }

      this.basicOptionsBillableHours.scales.y.max = maxValue
      this.basicOptionsBillableHours.scales.y.min = minValue
    })
  }

  private loadUtilisation(withLoading: boolean = true): void {
    if (!this.visible.utilisation) {
      return
    }

    if (withLoading) {
      this.loadings.utilisation = true
    }

    this.dashboardService.utilisation().subscribe((data: any[]) => {
      if (withLoading) {
        this.loadings.utilisation = false
      }

      this.data.utilisation = data

      const datasets = this.data.utilisation.datasets
      const maxValue =
        Math.max(...datasets.flatMap((dataset: any) => dataset.data)) + 5
      let minValue =
        Math.min(...datasets.flatMap((dataset: any) => dataset.data)) - 5

      if (minValue < 0) {
        minValue = 0
      }

      this.basicOptionsUtilisation.scales.y.max = maxValue
      this.basicOptionsUtilisation.scales.y.min = minValue
    })
  }

  public loadInvoices(withLoading: boolean = true): void {
    if (!this.visible.invoices) {
      return
    }

    localStorage.setItem('dashboard-settings', JSON.stringify(this.settings))

    if (withLoading) {
      this.loadings.invoices = true
    }

    this.dashboardService
      .invoices(
        this.selectedDateForInvoices.format('DD.MM.YYYY'),
        this.settings.invoice_type
      )
      .subscribe((data: any) => {
        this.data.invoices = data

        this.loadings.invoices = false
      })
  }

  private loadCarWithoutAssignment(withLoading: boolean = true): void {
    if (!this.visible.car_without_assignment) {
      return
    }

    if (withLoading) {
      this.loadings.car_without_assignment = true
    }

    this.dashboardService.carWithoutAssignment().subscribe((data: any[]) => {
      this.data.car_without_assignment = data

      if (withLoading) {
        this.loadings.car_without_assignment = false
      }
    })
  }

  private loadVacationIll(withLoading: boolean = true): void {
    if (!this.visible.vacation_ill) {
      return
    }

    if (withLoading) {
      this.loadings.vacation_ill = true
    }

    this.dashboardService
      .loadVacationIll()
      .subscribe((data: VacationIllModel[]) => {
        this.data.vacation_ill = data

        if (withLoading) {
          this.loadings.vacation_ill = false
        }
      })
  }

  private loadIllsTable(withLoading: boolean = true): void {
    if (!this.visible.ills_table) {
      return
    }

    if (withLoading) {
      this.loadings.ills_table = true
    }

    this.dashboardService.loadIllsTable().subscribe((data: any[]) => {
      this.data.ills_table = data

      if (withLoading) {
        this.loadings.ills_table = false
      }
    })
  }

  private loadNewCreated(withLoading: boolean = true): void {
    if (!this.visible.new_created) {
      return
    }

    if (withLoading) {
      this.loadings.new_created = true
    }

    this.dashboardService.loadNewCreated().subscribe((data: PatientModel[]) => {
      this.data.new_created = data

      if (withLoading) {
        this.loadings.new_created = false
      }
    })
  }

  private loadPhoneCalls(withLoading: boolean = true): void {
    if (!this.visible.phone_calls) {
      return
    }

    if (withLoading) {
      this.loadings.phone_calls = true
    }

    this.dashboardService
      .loadPhoneCalls()
      .subscribe((data: PhoneCallsListItemModel[]) => {
        this.data.phone_calls = data

        if (withLoading) {
          this.loadings.phone_calls = false
        }
      })
  }

  private loadImportantHistories(withLoading: boolean = true): void {
    if (!this.visible.important_histories) {
      return
    }

    if (withLoading) {
      this.loadings.important_histories = true
    }

    this.dashboardService
      .loadImportantHistories()
      .subscribe((data: HistoryListModel[]) => {
        this.data.important_histories = data

        if (withLoading) {
          this.loadings.important_histories = false
        }
      })
  }

  /**
   * Öffnet das Modal zum Bearbeiten eines Phonecalls.
   */
  public openPhonecallEditModal(phonecall: PhoneCallsListModel): void {
    this.dialogService.open(PhoneCallEditDialogComponent, {
      header: 'Telefonat bearbeiten',
      width: '540px',
      styleClass: 'dialog-container',
      dismissableMask: false,
      data: phonecall,
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.PhoneCallListReload:
            this.loadPhoneCalls(false)
            break
          case GlobalEvent.TodoChanged:
            this.loadTodos(false)
            break
          case GlobalEvent.BirthdayWished:
            this.loadBirthdays(false)
            break
        }
      }
    )
  }

  public openShowTodoDialog(todo: TodoModel): void {
    this.dialogService.open(ShowTodoDialogComponent, {
      header: `Todo ansehen #${todo?.id}`,
      width: '820px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        todo,
        todo_id: todo.id,
        user_type_name: todo?.caregiver?.full_name || todo?.patient?.full_name,
      },
    })
  }

  decreaseTypeSize(type: string): void {
    if (this.typeSizes[type] !== 2) {
      this.typeSizes[type]--
    }

    this.storeSizes()
  }

  increaseTypeSize(type: string): void {
    if (this.typeSizes[type] !== 12) {
      this.typeSizes[type]++
    }

    this.storeSizes()
  }
}
