<app-loader [loading]="loading"></app-loader>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header">
    <span> Abwesenheiten </span>

    <div class="content-header-buttons">
      <button
        pButton
        (click)="openDateDialog()"
        label="Neue Abwesenheit"
        type="button"
        class="p-button-outlined"
        icon="pi pi-plus"
        iconPos="left"
      ></button>

      <p-dropdown
        [options]="dateTypes"
        [(ngModel)]="selectedDateTypeFilter"
        inputId="date_type"
        (onChange)="customFilter()"
        name="date_type"
        placeholder="Filter"
        [showClear]="true"
      ></p-dropdown>

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [value]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
  <div class="content-body auto-height" *ngIf="dates.length === 0">
    <span class="content-no-message">Keine Abwesenheiten vorhanden</span>
  </div>
  <div
    class="content-body without-padding auto-height"
    *ngIf="dates.length > 0"
  >
    <p-table
      #dt
      [value]="dates"
      [rowHover]="true"
      sortMode="multiple"
      [globalFilterFields]="[
        'date_type_string',
        'type_string',
        'user.first_name',
        'user.last_name',
        'comment'
      ]"
      stateStorage="local"
      stateKey="state-caregiver-detail-dates"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 40px"></th>
          <th style="width: 200px" pSortableColumn="date_type">
            Termin <p-sortIcon field="date_type"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="type">
            Typ <p-sortIcon field="type"></p-sortIcon>
          </th>
          <th style="min-width: 120px" pSortableColumn="from">
            von <p-sortIcon field="from"></p-sortIcon>
          </th>
          <th style="min-width: 120px" pSortableColumn="to">
            bis <p-sortIcon field="to"></p-sortIcon>
          </th>
          <th pSortableColumn="valid_until">
            Gültigkeit <p-sortIcon field="valid_until"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="user.first_name">
            Erstellt von
            <p-sortIcon field="user.first_name"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="created_at">
            Erstellt am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th>Kommentar</th>
          <th style="min-width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-date>
        <tr class="clickable">
          <td style="width: 40px">
            <i
              class="pi pi-user"
              pTooltip="Durch BK erstellt"
              *ngIf="date.date_request_id"
            ></i>
          </td>
          <td (click)="openDateDialog(date)">
            <span class="p-tag p-tag-rounded p-tag-{{ date.date_type }}">{{
              date.date_type_string
            }}</span>

            <span
              style="margin: 0 0 0 5px"
              *ngIf="date.date_type === 'ILL' && date.child_ill === 'JA'"
              class="p-tag p-tag-rounded p-tag-outline"
              ><i class="pi pi-user"></i> Kind-AU</span
            >

            <i
              class="pi pi-undo color-gray"
              style="font-size: 14px; margin: 0 0 0 5px"
              pTooltip="Folge-AU"
              *ngIf="
                date.follow_up_ill_relations.length > 0 ||
                date.follow_up_ill_relation
              "
            ></i>
          </td>
          <td (click)="openDateDialog(date)">
            <div class="text-center-container">
              {{ date.type ? date.type_string : "-" }}
              <i
                *ngIf="date.address"
                class="pi pi-info-circle color-gray"
                style="font-size: 14px"
                [pTooltip]="date.address"
              ></i>
            </div>
          </td>

          <td (click)="openDateDialog(date)">
            {{ date.from | date: "dd.MM.y" }}
            {{ date.with_time ? date.from_time : "" }}
          </td>

          <td (click)="openDateDialog(date)" *ngIf="date.to">
            {{ date.to | date: "dd.MM.y" }}
            {{ date.with_time ? date.to_time : "" }}
          </td>
          <td (click)="openDateDialog(date)" *ngIf="!date.to">-</td>

          <td
            (click)="openDateDialog(date)"
            *ngIf="date.valid_until && !date.valid_unlimited"
          >
            {{ date.valid_until | date: "dd.MM.y" }}
          </td>
          <td
            (click)="openDateDialog(date)"
            *ngIf="!date.valid_until && !date.valid_unlimited"
          >
            -
          </td>
          <td (click)="openDateDialog(date)" *ngIf="date.valid_unlimited">
            Unbefristet
          </td>

          <td (click)="openDateDialog(date)">
            {{ date.user?.first_name }} {{ date.user?.last_name }}
          </td>
          <td (click)="openDateDialog(date)">
            {{ date.created_at | date: "dd.MM.y, H:mm" }}
          </td>
          <td (click)="openDateDialog(date)">
            <p class="history-text">{{ date.comment }}</p>
          </td>
          <td class="table-action" style="min-width: 100px">
            <i
              (click)="openDateDialog(date)"
              class="pi pi-pencil with-margin-right"
            ></i>
            <a
              *ngIf="date.media"
              [href]="caregiverService.getDocumentDownloadLink(date.media.uuid)"
              target="_blank"
            >
              <i
                tooltipPosition="left"
                pTooltip="Herunterladen"
                class="pi pi-download"
              ></i>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
