import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import * as dayjs from 'dayjs'
import { ConfirmationService } from 'primeng/api'
import { HelperService } from '../../../services/helper.service'
import { CarDamageService } from '../../../services/car-damage.service'
import {
  UserSystemModel,
  UserSystemSimpleModel,
} from '../../../models/user/user-system.model'
import { UserSystemService } from '../../../services/user-system.service'
import { AuthService } from '../../../services/auth.service'
import { CarDamageModel } from '../../../models/car/car-damage.model'
import { DocumentService } from '../../../services/document.service'
import { AddDocumentDialogComponent } from '../add-document-dialog/add-document-dialog.component'
import { MediaModel } from '../../../models/document/media.model'
import { SearchResultModel } from '../../../models/search/search-result.model'
import { SearchService } from '../../../services/search.service'
import { CarService } from '../../../services/car.service'
import { CarsDetailGeneralModel } from '../../../models/car/cars-detail-general.model'
import { UserCaregiverService } from '../../../services/user-caregiver.service'
import { ShowDamageDialogComponent } from '../show-damage-dialog/show-damage-dialog.component'

@Component({
  selector: 'app-add-damage-dialog',
  templateUrl: './add-damage-dialog.component.html',
})
export class AddDamageDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public data: any = {}
  public currentMileage: any = null
  public values: CarDamageModel = new CarDamageModel()
  public userSystemOptions: any[] = []
  public caregivers: SearchResultModel[] = []

  public submitted = false
  public submittedDelete = false

  public guiltOptions = [
    { label: 'Betreuungskraft', value: 'CAREGIVER' },
    { label: 'Unfallgegner', value: 'OPPONENT' },
    { label: 'Sonstiges', value: 'OTHER' },
  ]

  imagePreviews: string[] = []
  files: File[] = []

  currentDate = new Date()

  constructor(
    private ref: DynamicDialogRef,
    private authService: AuthService,
    private dialogService: DialogService,
    private searchService: SearchService,
    private config: DynamicDialogConfig,
    private carDamageService: CarDamageService,
    private carService: CarService,
    private userSystemService: UserSystemService,
    private userCaregiverService: UserCaregiverService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    public documentService: DocumentService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    if (!this.data.from_caregiver) {
      this.loadAllUserSystem()
    } else {
      this.loadCaregiversCarData()
    }
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public openShowDamageDialog(): void {
    this.ref.close()
    this.dialogService.open(ShowDamageDialogComponent, {
      header: 'Schaden ansehen',
      width: '620px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        damage_id: this.data.damage_id,
      },
    })
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement

    if (!input.files) {
      return
    }

    for (const file of Array.from(input.files)) {
      if (!file.type.startsWith('image/')) {
        continue
      }

      const reader = new FileReader()

      reader.onload = () => {
        this.imagePreviews.push(reader.result as string)
        this.files.push(file)
      }

      reader.readAsDataURL(file)
    }

    input.value = ''
  }

  removeImage(index: number) {
    this.imagePreviews.splice(index, 1)
    this.files.splice(index, 1)
  }

  public searchCaregivers(event: any): void {
    this.searchService
      .findCaregivers(event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.caregivers = results
      })
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    // Bei einem neuen Schaden muss der KM stand validiert werden.
    if (!this.data.isEdit) {
      if (this.values.mileage < this.currentMileage) {
        alert('Der KM-Stand ist kleiner als vorher!')

        return
      }
    }

    this.submitted = true

    let payload: any = this.values

    // Bei der eingeloggten BK ist die Struktur etwas anders.
    if (this.data.from_caregiver) {
      const formData = new FormData()

      formData.append('damage_data', JSON.stringify(this.values))

      this.files.forEach((file, index) => {
        formData.append(`images[]`, file)
      })

      payload = formData
    }

    let subscription = this.data.isEdit
      ? this.carDamageService.update(this.data.damage_id, payload)
      : this.carDamageService.store(this.data.car_id, payload)

    if (this.data.from_caregiver) {
      subscription = this.userCaregiverService.storeCarDamage(payload)
    }

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.DamageChanged)
        this.ref.close()
        this.toastService.success(
          'Schaden gespeichert',
          'Der Schaden wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  private loadCaregiversCarData(): void {
    this.userCaregiverService.loadCurrentCar().subscribe((response: any) => {
      this.currentMileage = response.car.current_mileage.mileage

      this.setTabIndexForIconButtons()
    })
  }

  private loadAllUserSystem(): void {
    this.userSystemService
      .listActiveSimple()
      .subscribe((data: UserSystemSimpleModel[]) => {
        for (const userSystem of data) {
          this.userSystemOptions.push({
            id: userSystem.id,
            full_name: userSystem.first_name + ' ' + userSystem.last_name,
          })
        }

        if (this.data.isEdit) {
          this.loadCarDamage()
        } else {
          this.loadCarDetails()

          // Der aktuell eingeloggte Benutzer soll automatisch ausgewählt werden.
          const user = this.authService.getUser()
          this.values.tracked_by_id = user?.id
        }

        this.setTabIndexForIconButtons()
      })
  }

  private setTabIndexForIconButtons(): void {
    setTimeout(() => {
      const elements = document.querySelectorAll('.p-button-icon-only')

      elements.forEach((element) => {
        element.setAttribute('tabindex', '-1')
      })
    }, 200)
  }

  private loadCarDamage(): void {
    this.carDamageService
      .load(this.data.damage_id)
      .subscribe((response: CarDamageModel) => {
        this.values = response
        this.values.date = dayjs(response.date).format('DD.MM.YYYY')
      })
  }

  private loadCarDetails(): void {
    this.carService
      .load(this.data.car_id)
      .subscribe((response: CarsDetailGeneralModel) => {
        if (response.current_mileage) {
          this.currentMileage = response.current_mileage.mileage
        }
      })
  }

  public openAddDocumentDialog(): void {
    const ref = this.dialogService.open(AddDocumentDialogComponent, {
      data: {
        // Standardmäßig wird das Dokument auf die ID 1 gebucht.
        type_id: this.values.id ?? 1,
        car_damage: this.values,
        type: 'car_damage',
      },
      header: 'Dokument hochladen',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
    })

    ref.onClose.subscribe((document: any) => {
      if (document) {
        this.values.media.push(document.media)
      }
    })
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Schaden wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        this.carDamageService.delete(this.data.damage_id).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.DamageChanged)
            this.ref.close()
            this.toastService.success(
              'Schaden gelöscht',
              'Die Schaden wurde erfolgreich gelöscht'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Löschen fehlgeschlagen',
              'Der Eintrag konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }

  onUpload($event: any) {}
}
