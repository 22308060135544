<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i class="pi pi-warehouse"></i>
      Alle Termine
    </span>

    <button
      pButton
      (click)="openCarGarageAppointmentDialog()"
      label="Neuer Termin"
      type="button"
      class="p-button-outlined"
      icon="pi pi-plus"
      iconPos="left"
    ></button>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading">
  <div class="content-body auto-height" *ngIf="garageAppointments.length === 0">
    <span class="content-no-message">Keine Termine vorhanden</span>
  </div>
  <div
    class="content-body without-padding auto-height"
    *ngIf="garageAppointments.length > 0"
  >
    <p-table
      #dt
      [value]="garageAppointments"
      sortMode="multiple"
      [rowHover]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 200px" pSortableColumn="type">
            Typ <p-sortIcon field="type"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="date">
            Datum <p-sortIcon field="date"></p-sortIcon>
          </th>
          <th style="width: 200px">KM</th>
          <th>Bemerkung</th>
          <th style="width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="clickable" (click)="openCarGarageAppointmentDialog(item)">
          <td>
            {{ item.type_text }}
          </td>
          <td>
            {{ item.date | date: "dd.MM.y" }}
          </td>

          <td *ngIf="item.mileage">
            {{ item.mileage | number }}
          </td>
          <td *ngIf="!item.mileage">-</td>

          <td>
            {{ item.comment || "-" }}
          </td>
          <td class="table-action" style="width: 100px">
            <i
              pTooltip="Bearbeiten"
              tooltipPosition="left"
              class="pi pi-pencil with-margin-right"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
