<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body" style="padding-bottom: 0">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <div>
          {{ data.description }}
        </div>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="data.type !== 'KM'"
    >
      <div class="p-field p-col-12">
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Ja"
              name="type"
              [(ngModel)]="values.selected_value"
              value="YES"
              [required]="true"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Nein"
              name="type"
              [(ngModel)]="values.selected_value"
              value="NO"
              [required]="true"
            ></p-radioButton>
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="data.type === 'KM'"
    >
      <div class="p-field p-col-12">
        <label for="mileage">Kilometerstand</label>
        <p-inputNumber
          inputId="mileage"
          name="mileage"
          [min]="0"
          locale="de-DE"
          [(ngModel)]="values.selected_value"
          [required]="true"
          #mileage="ngModel"
          [class.p-invalid]="
            mileage.invalid && (mileage.dirty || mileage.touched)
          "
        ></p-inputNumber>
        <small *ngIf="car.current_mileage"
          >Letzter Stand: {{ car.current_mileage.mileage | number }} KM</small
        >
      </div>
    </div>
  </div>

  <div class="dialog-form-footer">
    <i *ngIf="!data.closeable"></i>

    <button
      *ngIf="data.closeable"
      (click)="close()"
      pButton
      class="p-button-outlined"
      label="Später"
      type="button"
    ></button>

    <button
      (click)="save()"
      pButton
      label="Bestätigen"
      type="button"
      [disabled]="submitted || !values.selected_value"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
