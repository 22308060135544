import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { HelperService } from '../../../services/helper.service'
import { CarDamageService } from '../../../services/car-damage.service'
import { AuthService } from '../../../services/auth.service'
import { CarDamageModel } from '../../../models/car/car-damage.model'
import { AddDamageDialogComponent } from '../add-damage-dialog/add-damage-dialog.component'
import { AuthModel } from '../../../models/user/auth.model'
import { CarDamageCommentModel } from '../../../models/car/car-damage-comment.model'
import { AddDocumentDialogComponent } from '../add-document-dialog/add-document-dialog.component'
import { MediaModel } from '../../../models/document/media.model'
import { DocumentService } from '../../../services/document.service'

@Component({
  selector: 'app-show-damage-dialog',
  templateUrl: './show-damage-dialog.component.html',
})
export class ShowDamageDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public statusOptions = [
    { label: 'OFFEN', value: 'OPEN', backgroundColor: '#3296dc' },
    { label: 'ERLEDIGT', value: 'FINISHED', backgroundColor: '#84bc3c' },
  ]

  public currentUser: AuthModel | null = new AuthModel()
  public data: any = {}
  public values = {
    comment: '',
  }
  public damage: CarDamageModel = new CarDamageModel()
  public editComment: CarDamageCommentModel | null = null

  public submitted = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private authService: AuthService,
    private config: DynamicDialogConfig,
    private helperService: HelperService,
    private dialogService: DialogService,
    private carDamageService: CarDamageService,
    public documentService: DocumentService,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.currentUser = this.authService.getUser()

    this.loadCarDamage()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public changeStatus(): void {
    this.carDamageService
      .changeStatus(this.data.damage_id, this.damage.status)
      .subscribe(
        () => {
          this.loadCarDamage()
          this.eventbus.emit(GlobalEvent.DamageChanged)
          this.toastService.success(
            'Status gespeichert',
            'Der Status wurde erfolgreich geändert'
          )
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
        }
      )
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.carDamageService
      .storeComment(this.data.damage_id, this.values)
      .subscribe(
        () => {
          this.loadCarDamage()
          this.values.comment = ''
          this.submitted = false
          this.eventbus.emit(GlobalEvent.DamageChanged)
          this.toastService.success(
            'Kommentar gespeichert',
            'Der Kommentar wurde erfolgreich gespeichert'
          )
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
          this.submitted = false
        }
      )
  }

  public update(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.carDamageService
      .updateComment(this.editComment?.id || '', this.values)
      .subscribe(
        () => {
          this.loadCarDamage()
          this.values.comment = ''
          this.editComment = null
          this.submitted = false
          this.eventbus.emit(GlobalEvent.DamageChanged)
          this.toastService.success(
            'Kommentar bearbeitet',
            'Der Kommentar wurde erfolgreich bearbeitet'
          )
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
          this.submitted = false
        }
      )
  }

  public openEditMode(comment: CarDamageCommentModel): void {
    this.editComment = comment
    this.values.comment = comment.comment
  }

  public cancelEditMode(): void {
    this.editComment = null
    this.values.comment = ''
  }

  public openDamageDialog(): void {
    this.ref.close()
    this.dialogService.open(AddDamageDialogComponent, {
      header: 'Schaden bearbeiten',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        damage_id: this.data.damage_id,
      },
    })
  }

  public openAddDocumentDialog(): void {
    const ref = this.dialogService.open(AddDocumentDialogComponent, {
      data: {
        type_id: this.damage.id,
        car_damage: this.damage,
        type: 'car_damage',
      },
      header: 'Dokument hochladen',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
    })

    ref.onClose.subscribe((document: any) => {
      if (document) {
        this.damage.media.push(document.media)
      }
    })
  }

  private loadCarDamage(): void {
    this.carDamageService
      .load(this.data.damage_id)
      .subscribe((response: CarDamageModel) => {
        this.damage = response
      })
  }
}
