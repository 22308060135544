<p-sidebar [(visible)]="changeDashboardVisible" position="right">
  <ng-template pTemplate="headless">
    <div class="dialog-body">
      <div class="content-actions">
        <i class="tio icon-large">settings_outlined</i>

        <h2>
          <i class="tio">settings_outlined</i>
          Dashboard anpassen
        </h2>
      </div>
    </div>

    <div class="dialog-body without-padding">
      <p-table
        (onRowReorder)="onRowReorder($event)"
        [scrollable]="true"
        [value]="types"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th>Reihenfolge</th>
            <th style="max-width: 1px"></th>
            <th style="max-width: 120px">Größe</th>
            <th *ngIf="authService.isSuperAdmin()" style="max-width: 60px">
              Aktiv
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
          <tr [pReorderableRow]="index">
            <td [pReorderableRowHandle]="1" style="height: 50px">
              <div class="table-item-container" [pReorderableRowHandle]="1">
                <i
                  class="pi pi-arrows-v color-gray"
                  style="font-size: 18px"
                ></i>
                {{ typeNames[rowData] }}
              </div>
            </td>
            <td style="max-width: 1px"></td>
            <td style="max-width: 120px">
              <div class="dashboard-grid-size-action">
                <i
                  (click)="decreaseTypeSize(rowData)"
                  class="pi pi-minus-circle"
                ></i>
                <span>{{ typeSizes[rowData] }} / 12</span>
                <i
                  (click)="increaseTypeSize(rowData)"
                  class="pi pi-plus-circle"
                ></i>
              </div>
            </td>
            <td style="max-width: 60px" *ngIf="authService.isSuperAdmin()">
              <p-checkbox
                label=""
                (onChange)="storeSettings()"
                [name]="rowData"
                [binary]="true"
                [(ngModel)]="visible[rowData]"
              ></p-checkbox>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-template>
</p-sidebar>

<div class="content-actions">
  <i class="tio icon-large">explore_outlined</i>

  <h2>
    <i class="tio">explore_outlined</i>
    Übersicht

    <!--    <span *ngIf="authService.isSuperAdmin()"-->
    <!--      >{{ data.price_changes?.medias_count }} /-->
    <!--      {{ data.price_changes?.patients_count }} Preisänderungen</span-->
    <!--    >-->

    <!--    <span *ngIf="authService.isSuperAdmin()"-->
    <!--      >{{ data.price_changes?.stornos_count }} Kündigen</span-->
    <!--    >-->
  </h2>

  <button
    (click)="openChangeDashboardDialog()"
    class="p-button-text"
    icon="pi pi-cog"
    iconPos="right"
    label="Dashboard anpassen"
    pButton
    type="button"
  ></button>
</div>

<div class="dashboard-admin-grid new-content-container">
  <!-- Pause ende -->
  <div
    *ngIf="visible.pause_ending"
    class="content"
    [style.order]="types.indexOf('pause_ending')"
    [style.gridColumn]="'span ' + typeSizes['pause_ending']"
  >
    <header class="content-header">
      <span
        >{{ typeNames["pause_ending"] }}
        <i
          class="pi pi-info-circle color-gray"
          pTooltip="Alle Kunden in der die Pausierung vor 3 Tagen oder in den nächsten 7 Tagen beendet sind."
        ></i
      ></span>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo"
      *ngIf="loadings.pause_ending"
    >
      <i class="loading-logo"></i>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.pause_ending.length === 0 && !loadings.pause_ending"
    >
      <span class="content-no-message">Keine Einträge vorhanden</span>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.pause_ending.length > 0 && !loadings.pause_ending"
    >
      <p-table
        [value]="data.pause_ending"
        [rowHover]="true"
        [scrollable]="true"
        scrollHeight="100%"
        stateStorage="local"
        stateKey="state-dashboard-pause-ending"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th style="min-width: 300px" pSortableColumn="first_name">
              Name <p-sortIcon field="first_name"></p-sortIcon>
            </th>
            <th pSortableColumn="pause_that_will_end.pause_from">
              von
              <p-sortIcon field="pause_that_will_end.pause_from"></p-sortIcon>
            </th>
            <th pSortableColumn="pause_that_will_end.pause_to">
              bis
              <p-sortIcon field="pause_that_will_end.pause_to"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-patient>
          <tr>
            <td style="min-width: 300px">
              <a routerLink="/patients/{{ patient.id }}"
                >{{ patient.first_name }} {{ patient.last_name }}</a
              >
            </td>
            <td>{{ patient.pause_that_will_end.pause_from_string }}</td>
            <td>{{ patient.pause_that_will_end.pause_to_string }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <!-- Wichtige Ereignisse -->
  <div
    *ngIf="visible.important_histories"
    class="content"
    [style.order]="types.indexOf('important_histories')"
    [style.gridColumn]="'span ' + typeSizes['important_histories']"
  >
    <header class="content-header">
      <span
        >{{ typeNames["important_histories"] }}
        <i class="pi pi-info-circle color-gray" pTooltip="Die letzten 100"></i
      ></span>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo"
      *ngIf="loadings.important_histories"
    >
      <i class="loading-logo"></i>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="
        data.important_histories.length === 0 && !loadings.important_histories
      "
    >
      <span class="content-no-message"
        >Keine wichtigen Ereignisse vorhanden</span
      >
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="
        data.important_histories.length > 0 && !loadings.important_histories
      "
    >
      <p-table
        #dt
        [value]="data.important_histories"
        [rowHover]="true"
        [scrollable]="true"
        scrollHeight="100%"
        stateStorage="local"
        stateKey="state-dashboard-important-histories"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th>Name</th>
            <!--            <th pSortableColumn="created_at">-->
            <!--              am <p-sortIcon field="created_at"></p-sortIcon>-->
            <!--            </th>-->
            <!--            <th pSortableColumn="user_system.first_name">-->
            <!--              von <p-sortIcon field="user_system.first_name"></p-sortIcon>-->
            <!--            </th>-->
            <th style="max-width: 40px"></th>
            <th>Informationen</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-history>
          <tr>
            <td>
              <span
                *ngIf="
                  history.type === 'PHONE_CALL' &&
                  history.historiable_type.includes('Customer')
                "
                >{{ history.phone_call.phone_caller_name }}</span
              >

              <a
                routerLink="/patients/{{ history.historiable_id }}"
                *ngIf="history.historiable_type.includes('Patient')"
                >{{ history.historiable?.first_name }}
                {{ history.historiable?.last_name }}</a
              >

              <a
                routerLink="/caregivers/{{ history.historiable_id }}"
                *ngIf="history.historiable_type.includes('Caregiver')"
                >{{ history.historiable?.first_name }}
                {{ history.historiable?.last_name }}</a
              >

              <a
                routerLink="/multipliers/{{ history.historiable_id }}"
                *ngIf="history.historiable_type.includes('Multiplier')"
                >{{ history.historiable.name }}</a
              >

              <a
                routerLink="/administration/users/{{ history.historiable_id }}"
                *ngIf="history.historiable_type.includes('UserSystem')"
                >{{ history.historiable.first_name }}
                {{ history.historiable.last_name }}</a
              >

              <a
                routerLink="/cars/{{ history.historiable_id }}"
                *ngIf="
                  history.historiable_type.includes('Car') &&
                  !history.historiable_type.includes('Caregiver')
                "
                >{{ history.historiable.brand }}
                {{ history.historiable.model }}</a
              >
            </td>

            <td style="max-width: 40px">
              <i
                tooltipPosition="left"
                [pTooltip]="history.manual_description"
                style="font-size: 16px"
                class="pi pi-info-circle color-gray"
                *ngIf="history.manual_description"
              ></i>
              <i
                tooltipPosition="left"
                [pTooltip]="history.phone_call.comment"
                class="pi pi-info-circle color-gray"
                style="font-size: 16px"
                *ngIf="
                  !history.manual_description &&
                  history.type === 'PHONE_CALL' &&
                  history.phone_call.comment
                "
              ></i>
            </td>
            <td>
              <span class="history-information">
                <i
                  *ngIf="history.type === 'PHONE_CALL' && !history.manual_title"
                  >Telefonat</i
                >
                <i *ngIf="history.manual_title">{{ history.manual_title }}</i>
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <!-- PKW ohne Zuweisung -->
  <div
    *ngIf="visible.car_without_assignment"
    class="content"
    [style.order]="types.indexOf('car_without_assignment')"
    [style.gridColumn]="'span ' + typeSizes['car_without_assignment']"
  >
    <header class="content-header">
      <span>{{ typeNames["car_without_assignment"] }} </span>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo"
      *ngIf="loadings.car_without_assignment"
    >
      <i class="loading-logo"></i>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="
        data.car_without_assignment.length === 0 &&
        !loadings.car_without_assignment
      "
    >
      <span class="content-no-message">Keine PKW vorhanden</span>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="
        data.car_without_assignment.length > 0 &&
        !loadings.car_without_assignment
      "
    >
      <p-table
        #dt
        [value]="data.car_without_assignment"
        [rowHover]="true"
        [scrollable]="true"
        scrollHeight="100%"
        stateStorage="local"
        stateKey="state-dashboard-car-without-assignment"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th pSortableColumn="brand">
              Auto <p-sortIcon field="brand"></p-sortIcon>
            </th>
            <th pSortableColumn="license_plate">
              Kennzeichen <p-sortIcon field="license_plate"></p-sortIcon>
            </th>
            <th pSortableColumn="initial_registration">
              EZ <p-sortIcon field="initial_registration"></p-sortIcon>
            </th>
            <th pSortableColumn="current_mileage.mileage">
              KM <p-sortIcon field="current_mileage.mileage"></p-sortIcon>
            </th>
            <th style="width: 100px" pSortableColumn="open_damage">
              Schaden
              <p-sortIcon field="open_damage"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-car>
          <tr>
            <td>{{ car.brand }} {{ car.model }}</td>
            <td>
              <a routerLink="/cars/{{ car.id }}">
                {{ car.license_plate }}
              </a>
            </td>
            <td>{{ car.initial_registration_string || "-" }}</td>

            <td *ngIf="car.current_mileage?.mileage">
              {{ car.current_mileage?.mileage | number }}
            </td>
            <td *ngIf="!car.current_mileage?.mileage">-</td>

            <td>
              <div
                class="text-center-container"
                [pTooltip]="car.open_damage?.comment"
                tooltipStyleClass="tooltip-wider"
                *ngIf="car.open_damage?.status === 'OPEN'"
              >
                <i
                  class="icon-car-crash"
                  *ngIf="car.open_damage.type === 'CRASH'"
                ></i>
                <i
                  class="icon-car-damage"
                  *ngIf="car.open_damage.type !== 'CRASH'"
                ></i>
                {{ car.open_damage.type_text }}
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <!-- Rechnungen -->
  <div
    *ngIf="visible.invoices"
    class="content"
    [style.order]="types.indexOf('invoices')"
    [style.gridColumn]="'span ' + typeSizes['invoices']"
  >
    <header class="content-header">
      <span>{{ typeNames["invoices"] }} </span>

      <div class="content-header-buttons" style="gap: 5px">
        <div class="p-formgroup-inline" style="flex-wrap: nowrap">
          <div class="p-field-radiobutton" style="margin-bottom: 0 !important">
            <p-radioButton
              (onClick)="loadInvoices()"
              label="Rechnungsdatum"
              name="invoices_type"
              [(ngModel)]="settings.invoice_type"
              value="DATE_INVOICE"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton" style="margin-bottom: 0 !important">
            <p-radioButton
              (onClick)="loadInvoices()"
              label="Leistungsdatum"
              name="invoices_type"
              [(ngModel)]="settings.invoice_type"
              value="DATE_SERVICE"
            ></p-radioButton>
          </div>
        </div>

        <i
          style="padding: 4px 10px"
          (click)="updateInvoiceDate(-1)"
          class="pi pi-chevron-left color-gray"
        ></i>
        <span style="font-size: 14px; width: 120px; text-align: center">{{
          selectedDateForInvoices.format("MMMM YYYY")
        }}</span>
        <i
          style="padding: 4px 10px"
          (click)="updateInvoiceDate(1)"
          class="pi pi-chevron-right color-gray"
        ></i>
      </div>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo"
      *ngIf="loadings.invoices"
    >
      <i class="loading-logo"></i>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="!data.invoices && !loadings.invoices"
    >
      <span class="content-no-message">Keine Rechnungen vorhanden</span>
    </div>

    <div
      class="content-body content-base-height without-padding dashboard-content-with-footer-table"
      *ngIf="data.invoices && !loadings.invoices"
    >
      <p-table
        stateStorage="local"
        stateKey="state-dashboard-invoices"
        [value]="[1]"
        [rowHover]="true"
        [scrollable]="true"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th></th>
            <th style="max-width: 70px"></th>
            <th>Insgesamt</th>
            <th>Davon offen</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td><strong>Einsätze</strong></td>
            <td
              style="max-width: 70px"
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="
                data.invoices.last_month.total_appointments +
                ' (Diff: ' +
                data.invoices.differences.total_appointments_difference +
                ')'
              "
            >
              <small
                [class.color-alert]="
                  data.invoices.differences
                    .total_appointments_cents_difference < 0
                "
                [class.color-main1]="
                  data.invoices.differences
                    .total_appointments_cents_difference > 0
                "
              >
                <strong>{{
                  data.invoices.differences.total_appointments_cents_percentage
                }}</strong>
              </small>
            </td>
            <td>
              <strong>{{
                data.invoices.selected_month.total_appointments
              }}</strong>
            </td>
            <td>
              <strong>{{
                data.invoices.selected_month.opened_appointments
              }}</strong>
            </td>
          </tr>

          <tr>
            <td>Leistungsnehmer</td>
            <td
              style="max-width: 70px"
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="
                data.invoices.last_month.total_appointments_patient +
                ' (Diff: ' +
                data.invoices.differences
                  .total_appointments_patient_difference +
                ')'
              "
            >
              <small
                [class.color-alert]="
                  data.invoices.differences
                    .total_appointments_patient_cents_difference < 0
                "
                [class.color-main1]="
                  data.invoices.differences
                    .total_appointments_patient_cents_difference > 0
                "
              >
                {{
                  data.invoices.differences
                    .total_appointments_patient_cents_percentage
                }}
              </small>
            </td>
            <td>
              {{ data.invoices.selected_month.total_appointments_patient }}
            </td>
            <td>
              {{ data.invoices.selected_month.opened_appointments_patient }}
            </td>
          </tr>

          <tr>
            <td>Leistungsnehmer SEPA</td>
            <td
              style="max-width: 70px"
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="
                data.invoices.last_month.total_appointments_sepa +
                ' (Diff: ' +
                data.invoices.differences.total_appointments_sepa_difference +
                ')'
              "
            >
              <small
                [class.color-alert]="
                  data.invoices.differences
                    .total_appointments_sepa_cents_difference < 0
                "
                [class.color-main1]="
                  data.invoices.differences
                    .total_appointments_sepa_cents_difference > 0
                "
              >
                {{
                  data.invoices.differences
                    .total_appointments_sepa_cents_percentage
                }}
              </small>
            </td>
            <td>{{ data.invoices.selected_month.total_appointments_sepa }}</td>
            <td>{{ data.invoices.selected_month.opened_appointments_sepa }}</td>
          </tr>

          <tr>
            <td>Abrechnungszentrum</td>
            <td
              style="max-width: 70px"
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="
                data.invoices.last_month.total_appointments_accounting_center +
                ' (Diff: ' +
                data.invoices.differences
                  .total_appointments_accounting_center_difference +
                ')'
              "
            >
              <small
                [class.color-alert]="
                  data.invoices.differences
                    .total_appointments_accounting_center_cents_difference < 0
                "
                [class.color-main1]="
                  data.invoices.differences
                    .total_appointments_accounting_center_cents_difference > 0
                "
              >
                {{
                  data.invoices.differences
                    .total_appointments_accounting_center_cents_percentage
                }}
              </small>
            </td>
            <td>
              {{
                data.invoices.selected_month
                  .total_appointments_accounting_center
              }}
            </td>
            <td>
              {{
                data.invoices.selected_month
                  .opened_appointments_accounting_center
              }}
            </td>
          </tr>

          <tr>
            <td>Kostenträger</td>
            <td
              style="max-width: 70px"
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="
                data.invoices.last_month.total_appointments_cost_unit +
                ' (Diff: ' +
                data.invoices.differences
                  .total_appointments_cost_unit_difference +
                ')'
              "
            >
              <small
                [class.color-alert]="
                  data.invoices.differences
                    .total_appointments_cost_unit_cents_difference < 0
                "
                [class.color-main1]="
                  data.invoices.differences
                    .total_appointments_cost_unit_cents_difference > 0
                "
              >
                {{
                  data.invoices.differences
                    .total_appointments_cost_unit_cents_percentage
                }}
              </small>
            </td>
            <td>
              {{ data.invoices.selected_month.total_appointments_cost_unit }}
            </td>
            <td>
              {{ data.invoices.selected_month.opened_appointments_cost_unit }}
            </td>
          </tr>

          <tr class="table-footer-end">
            <td><strong>Beratungseinsätze</strong></td>
            <td
              style="max-width: 70px"
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="
                data.invoices.last_month.total_consultings +
                ' (Diff: ' +
                data.invoices.differences.total_consultings_difference +
                ')'
              "
            >
              <small
                [class.color-alert]="
                  data.invoices.differences.total_consultings_cents_difference <
                  0
                "
                [class.color-main1]="
                  data.invoices.differences.total_consultings_cents_difference >
                  0
                "
              >
                {{
                  data.invoices.differences.total_consultings_cents_percentage
                }}
              </small>
            </td>
            <td>{{ data.invoices.selected_month.total_consultings }}</td>
            <td>{{ data.invoices.selected_month.opened_consultings }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <!-- Budgets -->
  <div
    *ngIf="visible.budgets"
    class="content"
    [style.order]="types.indexOf('budgets')"
    [style.gridColumn]="'span ' + typeSizes['budgets']"
  >
    <header class="content-header">
      <span>{{ typeNames["budgets"] }} </span>

      <div class="content-header-buttons" style="gap: 5px">
        <i
          style="padding: 4px 10px"
          (click)="updateBudgetDate(-1)"
          class="pi pi-chevron-left color-gray"
        ></i>
        <span style="font-size: 14px; width: 240px; text-align: center">
          {{ selectedDateForBudgetsLastMonth.format("MMMM YYYY") }} -
          {{ selectedDateForBudgets.format("MMMM YYYY") }}
        </span>
        <i
          style="padding: 4px 10px"
          (click)="updateBudgetDate(1)"
          class="pi pi-chevron-right color-gray"
        ></i>
      </div>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo"
      *ngIf="loadings.budgets"
    >
      <i class="loading-logo"></i>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="!data.budgets && !loadings.budgets"
    >
      <span class="content-no-message">Keine Budgets vorhanden</span>
    </div>

    <div
      class="content-body content-base-height without-padding dashboard-content-with-footer-table"
      *ngIf="data.budgets && !loadings.budgets"
    >
      <p-table
        stateStorage="local"
        stateKey="state-dashboard-budgets"
        [value]="[1]"
        [rowHover]="true"
        [scrollable]="true"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th></th>
            <th>{{ data.budgets.last_month.date }}</th>
            <th style="max-width: 70px"></th>
            <th>{{ data.budgets.selected_month.date }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td><strong>Summe</strong></td>
            <td>
              <strong>{{ data.budgets.last_month.total_formatted }}</strong>
            </td>
            <td
              style="max-width: 70px"
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="'Diff: ' + data.budgets.differences.total_difference"
            >
              <small
                [class.color-alert]="
                  data.budgets.differences.total_cents_difference < 0
                "
                [class.color-main1]="
                  data.budgets.differences.total_cents_difference > 0
                "
              >
                <strong>{{
                  data.budgets.differences.total_cents_percentage
                }}</strong>
              </small>
            </td>
            <td>
              <strong>{{ data.budgets.selected_month.total_formatted }}</strong>
            </td>
          </tr>

          <tr>
            <td>Selbstzahler</td>
            <td>
              {{ data.budgets.last_month.selbstzahler_formatted }}
            </td>
            <td
              style="max-width: 70px"
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="
                'Diff: ' + data.budgets.differences.selbstzahler_difference
              "
            >
              <small
                [class.color-alert]="
                  data.budgets.differences.selbstzahler_cents_difference < 0
                "
                [class.color-main1]="
                  data.budgets.differences.selbstzahler_cents_difference > 0
                "
              >
                {{ data.budgets.differences.selbstzahler_cents_percentage }}
              </small>
            </td>
            <td>
              {{ data.budgets.selected_month.selbstzahler_formatted }}
            </td>
          </tr>

          <tr>
            <td>Unfallkasse</td>
            <td>
              {{ data.budgets.last_month.unfallkasse_formatted }}
            </td>
            <td
              style="max-width: 70px"
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="
                'Diff: ' + data.budgets.differences.unfallkasse_difference
              "
            >
              <small
                [class.color-alert]="
                  data.budgets.differences.unfallkasse_cents_difference < 0
                "
                [class.color-main1]="
                  data.budgets.differences.unfallkasse_cents_difference > 0
                "
              >
                {{ data.budgets.differences.unfallkasse_cents_percentage }}
              </small>
            </td>
            <td>
              {{ data.budgets.selected_month.unfallkasse_formatted }}
            </td>
          </tr>

          <tr>
            <td>Kostenträger</td>
            <td>
              {{ data.budgets.last_month.kostentraeger_formatted }}
            </td>
            <td
              style="max-width: 70px"
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="
                'Diff: ' + data.budgets.differences.kostentraeger_difference
              "
            >
              <small
                [class.color-alert]="
                  data.budgets.differences.kostentraeger_cents_difference < 0
                "
                [class.color-main1]="
                  data.budgets.differences.kostentraeger_cents_difference > 0
                "
              >
                {{ data.budgets.differences.kostentraeger_cents_percentage }}
              </small>
            </td>
            <td>
              {{ data.budgets.selected_month.kostentraeger_formatted }}
            </td>
          </tr>

          <tr>
            <td>§45a</td>
            <td>
              {{ data.budgets.last_month.type_45a_formatted }}
            </td>
            <td
              style="max-width: 70px"
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="
                'Diff: ' + data.budgets.differences.type_45a_difference
              "
            >
              <small
                [class.color-alert]="
                  data.budgets.differences.type_45a_cents_difference < 0
                "
                [class.color-main1]="
                  data.budgets.differences.type_45a_cents_difference > 0
                "
              >
                {{ data.budgets.differences.type_45a_cents_percentage }}
              </small>
            </td>
            <td>
              {{ data.budgets.selected_month.type_45a_formatted }}
            </td>
          </tr>

          <tr>
            <td>§45b</td>
            <td>
              {{ data.budgets.last_month.type_45b_formatted }}
            </td>
            <td
              style="max-width: 70px"
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="
                'Diff: ' + data.budgets.differences.type_45b_difference
              "
            >
              <small
                [class.color-alert]="
                  data.budgets.differences.type_45b_cents_difference < 0
                "
                [class.color-main1]="
                  data.budgets.differences.type_45b_cents_difference > 0
                "
              >
                {{ data.budgets.differences.type_45b_cents_percentage }}
              </small>
            </td>
            <td>
              {{ data.budgets.selected_month.type_45b_formatted }}
            </td>
          </tr>

          <tr>
            <td>§39</td>
            <td>
              {{ data.budgets.last_month.type_39_formatted }}
            </td>
            <td
              style="max-width: 70px"
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="
                'Diff: ' + data.budgets.differences.type_39_difference
              "
            >
              <small
                [class.color-alert]="
                  data.budgets.differences.type_39_cents_difference < 0
                "
                [class.color-main1]="
                  data.budgets.differences.type_39_cents_difference > 0
                "
              >
                {{ data.budgets.differences.type_39_cents_percentage }}
              </small>
            </td>
            <td>
              {{ data.budgets.selected_month.type_39_formatted }}
            </td>
          </tr>

          <tr class="without-border">
            <td>§39 plus</td>
            <td>
              {{ data.budgets.last_month.type_39_plus_formatted }}
            </td>
            <td
              style="max-width: 70px"
              tooltipPosition="left"
              tooltipStyleClass="tooltip-wider"
              [pTooltip]="
                'Diff: ' + data.budgets.differences.type_39_plus_difference
              "
            >
              <small
                [class.color-alert]="
                  data.budgets.differences.type_39_plus_cents_difference < 0
                "
                [class.color-main1]="
                  data.budgets.differences.type_39_plus_cents_difference > 0
                "
              >
                {{ data.budgets.differences.type_39_plus_cents_percentage }}
              </small>
            </td>
            <td>
              {{ data.budgets.selected_month.type_39_plus_formatted }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <!-- Neu erstellt -->
  <div
    *ngIf="visible.new_created"
    class="content"
    [style.order]="types.indexOf('new_created')"
    [style.gridColumn]="'span ' + typeSizes['new_created']"
  >
    <header class="content-header">
      <span
        >{{ typeNames["new_created"] }}
        <i
          class="pi pi-info-circle color-gray"
          pTooltip="Alle Kunden die noch qualifiziert werden müssen"
        ></i
      ></span>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo"
      *ngIf="loadings.new_created"
    >
      <i class="loading-logo"></i>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.new_created.length === 0 && !loadings.new_created"
    >
      <span class="content-no-message">Keine Qualifizierungen offen</span>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.new_created.length > 0 && !loadings.new_created"
    >
      <p-table
        [value]="data.new_created"
        [rowHover]="true"
        [scrollable]="true"
        scrollHeight="100%"
        stateStorage="local"
        stateKey="state-dashboard-new-created"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th pSortableColumn="first_name">
              Name <p-sortIcon field="first_name"></p-sortIcon>
            </th>
            <th pSortableColumn="customer.qm.initial_consultation_type">
              Beratung
              <p-sortIcon
                field="customer.qm.initial_consultation_type"
              ></p-sortIcon>
            </th>
            <th
              style="max-width: 150px"
              pSortableColumn="customer.qm.initial_consultation_date"
            >
              Beratung am
              <p-sortIcon
                field="customer.qm.initial_consultation_date"
              ></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-patient>
          <tr>
            <td>
              <a routerLink="/patients/{{ patient.id }}"
                >{{ patient.first_name }} {{ patient.last_name }}</a
              >
            </td>
            <td *ngIf="!patient.customer.qm.initial_consultation_type">-</td>
            <td *ngIf="patient.customer.qm.initial_consultation_type">
              {{ patient.customer.qm.initial_consultation_type }} von
              {{ patient.customer.qm.initial_consultation_from || "?" }}
            </td>

            <td style="max-width: 150px">
              {{ patient.customer.qm.initial_consultation_date_string || "-" }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <!-- Rückläufer -->
  <div
    *ngIf="visible.accounting_returns"
    class="content"
    [style.order]="types.indexOf('accounting_returns')"
    [style.gridColumn]="'span ' + typeSizes['accounting_returns']"
  >
    <header class="content-header without-border">
      <span>{{ typeNames["accounting_returns"] }}</span>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo transparent"
      *ngIf="loadings.accounting_returns"
    >
      <i class="loading-logo"></i>
    </div>

    <!--    <div-->
    <!--      class="content-body content-base-height without-padding"-->
    <!--      *ngIf="-->
    <!--        data.accounting_returns?.datasets?.length === 0 &&-->
    <!--        !loadings.accounting_returns-->
    <!--      "-->
    <!--    >-->
    <!--      <span class="content-no-message">Keine Rückläufer offen</span>-->
    <!--    </div>-->

    <div
      class="content-body content-base-height without-padding transparent"
      *ngIf="!loadings.accounting_returns"
    >
      <p-chart
        type="bar"
        height="240px"
        [data]="data.accounting_returns"
        [options]="basicOptions"
      ></p-chart>
    </div>
  </div>

  <!-- Beratungseinsätze -->
  <div
    *ngIf="visible.consultings"
    class="content"
    [style.order]="types.indexOf('consultings')"
    [style.gridColumn]="'span ' + typeSizes['consultings']"
  >
    <header class="content-header without-border">
      <span>{{ typeNames["consultings"] }}</span>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo transparent"
      *ngIf="loadings.consultings"
    >
      <i class="loading-logo"></i>
    </div>

    <!--    <div-->
    <!--      class="content-body content-base-height without-padding"-->
    <!--      *ngIf="data.consultings?.datasets?.length === 0 && !loadings.consultings"-->
    <!--    >-->
    <!--      <span class="content-no-message">Keine Rückläufer offen</span>-->
    <!--    </div>-->

    <div
      class="content-body content-base-height without-padding transparent"
      *ngIf="!loadings.consultings"
    >
      <p-chart
        type="line"
        height="240px"
        [data]="data.consultings"
        [options]="basicOptionsConsulting"
      ></p-chart>
    </div>
  </div>

  <!-- Abrechenbare Stunden -->
  <div
    *ngIf="visible.billable_hours"
    class="content"
    [style.order]="types.indexOf('billable_hours')"
    [style.gridColumn]="'span ' + typeSizes['billable_hours']"
  >
    <header class="content-header without-border">
      <span>{{ typeNames["billable_hours"] }}</span>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo transparent"
      *ngIf="loadings.billable_hours"
    >
      <i class="loading-logo"></i>
    </div>

    <div
      class="content-body content-base-height without-padding transparent"
      *ngIf="!loadings.billable_hours"
    >
      <p-chart
        type="line"
        height="240px"
        [data]="data.billable_hours"
        [options]="basicOptionsBillableHours"
      ></p-chart>
    </div>
  </div>

  <!-- Auslastung -->
  <div
    *ngIf="visible.utilisation"
    class="content"
    [style.order]="types.indexOf('utilisation')"
    [style.gridColumn]="'span ' + typeSizes['utilisation']"
  >
    <header class="content-header without-border">
      <span>{{ typeNames["utilisation"] }}</span>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo transparent"
      *ngIf="loadings.utilisation"
    >
      <i class="loading-logo"></i>
    </div>

    <div
      class="content-body content-base-height without-padding transparent"
      *ngIf="!loadings.utilisation"
    >
      <p-chart
        type="line"
        height="240px"
        [data]="data.utilisation"
        [options]="basicOptionsUtilisation"
      ></p-chart>
    </div>
  </div>

  <!-- AU-Verlauf -->
  <div
    *ngIf="visible.ills"
    class="content"
    [style.order]="types.indexOf('ills')"
    [style.gridColumn]="'span ' + typeSizes['ills']"
  >
    <header class="content-header without-border">
      <span>{{ typeNames["ills"] }}</span>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo transparent"
      *ngIf="loadings.ills"
    >
      <i class="loading-logo"></i>
    </div>

    <!--    <div-->
    <!--      class="content-body content-base-height without-padding"-->
    <!--      *ngIf="data.ills?.datasets?.length === 0 && !loadings.ills"-->
    <!--    >-->
    <!--      <span class="content-no-message">Keine Rückläufer offen</span>-->
    <!--    </div>-->

    <div
      class="content-body content-base-height without-padding transparent"
      *ngIf="!loadings.ills"
    >
      <p-chart
        type="line"
        height="240px"
        [data]="data.ills"
        [options]="basicOptionsIll"
      ></p-chart>
    </div>
  </div>

  <!-- Krankmeldungen -->
  <div
    *ngIf="visible.ills_table"
    class="content"
    [style.order]="types.indexOf('ills_table')"
    [style.gridColumn]="'span ' + typeSizes['ills_table']"
  >
    <header class="content-header">
      <span>{{ typeNames["ills_table"] }} </span>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo"
      *ngIf="loadings.ills_table"
    >
      <i class="loading-logo"></i>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.ills_table.length === 0 && !loadings.ills_table"
    >
      <span class="content-no-message">Keine Einträge vorhanden</span>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.ills_table.length > 0 && !loadings.ills_table"
    >
      <p-table
        [value]="data.ills_table"
        [rowHover]="true"
        [scrollable]="true"
        scrollHeight="100%"
        stateStorage="local"
        stateKey="state-dashboard-ill-table"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th style="max-width: 50px"></th>
            <th pSortableColumn="caregiver.first_name">
              Betreuungskraft
              <p-sortIcon field="caregiver.first_name"></p-sortIcon>
            </th>
            <th pSortableColumn="from">
              von <p-sortIcon field="from"></p-sortIcon>
            </th>
            <th pSortableColumn="to">
              bis <p-sortIcon field="to"></p-sortIcon>
            </th>
            <th>
              <div class="text-center-container">
                Tage
                <i
                  class="pi pi-info-circle"
                  style="font-size: 14px"
                  pTooltip="Ohne Sonn- und Feiertage"
                ></i>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-ill>
          <tr>
            <td
              [pTooltip]="ill.is_long_term ? 'Langzeit' : ''"
              style="max-width: 50px"
            >
              <i class="pi pi-heart color-alert" *ngIf="ill.is_long_term"></i>
            </td>
            <td>
              <a routerLink="/caregivers/{{ ill.caregiver.id }}"
                >{{ ill.caregiver.first_name }} {{ ill.caregiver.last_name }}</a
              >
            </td>

            <td *ngIf="!ill.is_today && !ill.is_yesterday">
              {{ ill.from | date: "dd.MM.y" }}
            </td>
            <td *ngIf="ill.is_today || ill.is_yesterday">
              <div class="text-center-container">
                <strong>{{ ill.from | date: "dd.MM.y" }} </strong>
                <small *ngIf="ill.is_today">HEUTE</small>
                <small *ngIf="ill.is_yesterday">GESTERN</small>
              </div>
            </td>

            <td>{{ ill.to | date: "dd.MM.y" }}</td>
            <td>{{ ill.amount_days }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <!-- Urlaub / AU -->
  <div
    *ngIf="visible.vacation_ill"
    class="content"
    [style.order]="types.indexOf('vacation_ill')"
    [style.gridColumn]="'span ' + typeSizes['vacation_ill']"
  >
    <header class="content-header">
      <span
        >{{ typeNames["vacation_ill"] }}
        <i
          class="pi pi-info-circle color-gray"
          pTooltip="Die nächsten 60 Tage"
        ></i>
      </span>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo"
      *ngIf="loadings.vacation_ill"
    >
      <i class="loading-logo"></i>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.vacation_ill.length === 0 && !loadings.vacation_ill"
    >
      <span class="content-no-message">Keine Einträge vorhanden</span>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.vacation_ill.length > 0 && !loadings.vacation_ill"
    >
      <p-table
        [value]="data.vacation_ill"
        [rowHover]="true"
        [scrollable]="true"
        scrollHeight="100%"
        stateStorage="local"
        stateKey="state-dashboard-vacation-ill"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th style="max-width: 150px" pSortableColumn="date_type">
              Typ <p-sortIcon field="date_type"></p-sortIcon>
            </th>
            <th pSortableColumn="caregiver.first_name">
              Name <p-sortIcon field="caregiver.first_name"></p-sortIcon>
            </th>
            <th pSortableColumn="from">
              von <p-sortIcon field="from"></p-sortIcon>
            </th>
            <th pSortableColumn="to">
              bis <p-sortIcon field="to"></p-sortIcon>
            </th>
            <th style="max-width: 50px"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-vacationIll>
          <tr>
            <td style="max-width: 150px">
              <span
                class="p-tag p-tag-rounded p-tag-{{ vacationIll.date_type }}"
                >{{ vacationIll.date_type_string }}</span
              >
            </td>
            <td>
              <a routerLink="/caregivers/{{ vacationIll.caregiver_id }}"
                >{{ vacationIll.caregiver.first_name }}
                {{ vacationIll.caregiver.last_name }}</a
              >
            </td>
            <td>{{ vacationIll.from | date: "dd.MM.y" }}</td>
            <td>{{ vacationIll.to | date: "dd.MM.y" }}</td>
            <td style="max-width: 50px">
              <i
                tooltipPosition="left"
                [pTooltip]="vacationIll.comment"
                style="font-size: 16px"
                class="pi pi-info-circle color-gray"
                *ngIf="vacationIll.comment"
              ></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <!-- Telefonate -->
  <div
    *ngIf="visible.phone_calls"
    class="content"
    [style.order]="types.indexOf('phone_calls')"
    [style.gridColumn]="'span ' + typeSizes['phone_calls']"
  >
    <header class="content-header">
      <span
        >{{ typeNames["phone_calls"] }}
        <i class="pi pi-info-circle color-gray" pTooltip="Die letzten 100"></i
      ></span>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo"
      *ngIf="loadings.phone_calls"
    >
      <i class="loading-logo"></i>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.phone_calls.length === 0 && !loadings.phone_calls"
    >
      <span class="content-no-message">Keine Telefonate vorhanden</span>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.phone_calls.length > 0 && !loadings.phone_calls"
    >
      <p-table
        [value]="data.phone_calls"
        [rowHover]="true"
        [scrollable]="true"
        scrollHeight="100%"
        stateStorage="local"
        stateKey="state-dashboard-phone-calls"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th style="max-width: 120px">Anruf</th>
            <th>Nummer</th>
            <th>Name</th>
            <th pSortableColumn="created_at">
              um <p-sortIcon field="created_at"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-call>
          <tr>
            <td style="max-width: 120px">
              <span class="color-gray" *ngIf="!call.we_called">
                <i class="pi pi-arrow-right" style="font-size: 12px"></i>
                an uns
              </span>

              <span class="color-gray" *ngIf="call.we_called">
                von uns
                <i class="pi pi-arrow-right" style="font-size: 12px"></i>
              </span>
            </td>

            <td *ngIf="call.number">
              <a
                (click)="phoneCallService.createCalling(call.number)"
                href="tel:{{ call.number }}"
                >{{ call.number }}</a
              >
            </td>
            <td *ngIf="!call.number">Unbekannt</td>

            <td>{{ call.phone_caller_name || "Unbekannt" }}</td>
            <td>{{ call.created_at | date: "dd.MM.y, H:mm" }} Uhr</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <!-- Geburtstage -->
  <div
    *ngIf="visible.birthdays"
    class="content"
    [style.order]="types.indexOf('birthdays')"
    [style.gridColumn]="'span ' + typeSizes['birthdays']"
  >
    <header class="content-header">
      <span
        >{{ typeNames["birthdays"] }}
        <i
          class="pi pi-info-circle color-gray"
          pTooltip="In den letzten 3 und nächsten 7 Tagen"
        ></i
      ></span>

      <div class="content-header-buttons">
        <span>
          <p-checkbox
            label="nur heute ({{ amountBirthdaysToday }})"
            [(ngModel)]="onlyToday"
            (onChange)="loadBirthdays(false)"
            [binary]="true"
            name="only_today"
          ></p-checkbox>
        </span>
      </div>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo"
      *ngIf="loadings.birthdays"
    >
      <i class="loading-logo"></i>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.birthdays.length === 0 && !loadings.birthdays"
    >
      <span class="content-no-message">Keine anstehenden Geburtstage</span>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.birthdays.length > 0 && !loadings.birthdays"
    >
      <p-table
        [value]="data.birthdays"
        [rowHover]="true"
        [scrollable]="true"
        scrollHeight="100%"
        stateStorage="local"
        stateKey="state-dashboard-birthdays"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th style="max-width: 150px">Status</th>
            <th>Name</th>
            <th pSortableColumn="birthday_day_month_format">
              Datum
              <p-sortIcon field="birthday_day_month_format"></p-sortIcon>
            </th>
            <th style="max-width: 50px"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
          <tr [class.finished]="data.already_wished">
            <td style="max-width: 150px">
              <span
                *ngIf="
                  data.status && data.type === 'patient' && !data.is_paused
                "
                class="p-tag p-tag-rounded p-tag-{{ data.status }}"
                >{{ data.status_string }}</span
              >
              <span
                *ngIf="data.status && data.type === 'patient' && data.is_paused"
                class="p-tag p-tag-rounded p-tag-PAUSED"
                >Pausiert</span
              >

              <span
                *ngIf="data.type === 'caregiver' && data.anniversary"
                class="p-tag p-tag-rounded p-tag-GRAY"
                >BK: Jubiläum</span
              >
              <span
                *ngIf="data.type === 'caregiver' && !data.anniversary"
                class="p-tag p-tag-rounded p-tag-GRAY"
                >BK: Geburtstag</span
              >

              <span
                *ngIf="data.type === 'user_system'"
                class="p-tag p-tag-rounded p-tag-GRAY"
                >Benutzer</span
              >
            </td>
            <td>
              <a
                routerLink="/patients/{{ data.id }}"
                *ngIf="data.type === 'patient'"
                >{{ data.first_name }} {{ data.last_name }}</a
              >

              <a
                routerLink="/caregivers/{{ data.id }}"
                *ngIf="data.type === 'caregiver'"
                >{{ data.first_name }} {{ data.last_name }}</a
              >

              <a
                routerLink="/administration/users/{{ data.id }}"
                *ngIf="data.type === 'user_system'"
                >{{ data.first_name }} {{ data.last_name }}</a
              >
            </td>
            <td>
              {{ data.birthday_date_string || data.anniversary_date_string }}
            </td>

            <td
              [pTooltip]="
                data.already_wished
                  ? data.already_wished.manual_description +
                    ' von ' +
                    data.already_wished.created_from_name
                  : 'Eintrag erstellen'
              "
              [class.color-main1]="data.already_wished"
              [class.clickable]="!data.already_wished"
              (click)="openManualHistoryDialog(data)"
              tooltipPosition="left"
              style="max-width: 50px"
            >
              <i class="pi pi-check"></i>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <!-- Geburtstage Mitarbeiter -->
  <div
    *ngIf="visible.birthdays_weekly"
    class="content"
    [style.order]="types.indexOf('birthdays_weekly')"
    [style.gridColumn]="'span ' + typeSizes['birthdays_weekly']"
  >
    <header class="content-header">
      <span>{{ typeNames["birthdays_weekly"] }} </span>

      <div class="content-header-buttons" style="gap: 5px">
        <i
          style="padding: 4px 10px"
          (click)="updateWeek(-1)"
          class="pi pi-chevron-left color-gray"
        ></i>
        <span style="font-size: 14px"
          >KW {{ currentDate.week() }} {{ currentDate.year() }}</span
        >
        <i
          style="padding: 4px 10px"
          (click)="updateWeek(1)"
          class="pi pi-chevron-right color-gray"
        ></i>
      </div>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo"
      *ngIf="loadings.birthdays_weekly"
    >
      <i class="loading-logo"></i>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.birthdays_weekly.length === 0 && !loadings.birthdays_weekly"
    >
      <span class="content-no-message">Keine Geburtstage diese KW</span>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.birthdays_weekly.length > 0 && !loadings.birthdays_weekly"
    >
      <p-table
        [value]="data.birthdays_weekly"
        [rowHover]="true"
        [scrollable]="true"
        scrollHeight="100%"
        stateStorage="local"
        stateKey="state-dashboard-birthdays-weekly"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th style="max-width: 150px">Status</th>
            <th>Name</th>
            <th pSortableColumn="birthday_day_month_format">
              Datum
              <p-sortIcon field="birthday_day_month_format"></p-sortIcon>
            </th>
            <!--            <th style="max-width: 50px"></th>-->
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
          <tr [class.finished]="data.already_wished">
            <td style="max-width: 150px">
              <span
                *ngIf="data.type === 'caregiver'"
                class="p-tag p-tag-rounded p-tag-GRAY"
                >Betreuungskraft</span
              >

              <span
                *ngIf="data.type === 'user_system'"
                class="p-tag p-tag-rounded p-tag-GRAY"
                >Benutzer</span
              >
            </td>
            <td>
              <a
                routerLink="/caregivers/{{ data.id }}"
                *ngIf="data.type === 'caregiver'"
                >{{ data.first_name }} {{ data.last_name }}</a
              >

              <a
                routerLink="/administration/users/{{ data.id }}"
                *ngIf="data.type === 'user_system'"
                >{{ data.first_name }} {{ data.last_name }}</a
              >
            </td>
            <td>
              {{ data.birthday_date_string || data.anniversary_date_string }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <!-- Todos -->
  <div
    class="content"
    *ngIf="visible.todos"
    [style.order]="types.indexOf('todos')"
    [style.gridColumn]="'span ' + typeSizes['todos']"
  >
    <header class="content-header">
      <span
        >{{ typeNames["todos"]
        }}<i
          class="pi pi-info-circle color-gray"
          tooltipStyleClass="tooltip-wider"
          pTooltip="Allgemeine, dir zugewiesene oder keinem zugewiesen"
        ></i
      ></span>

      <div class="content-header-buttons">
        <span>
          <p-checkbox
            label="nur mir zugewiesene"
            [(ngModel)]="settings.only_assigned"
            (onChange)="loadTodos(false)"
            [binary]="true"
            name="only_assigned"
          ></p-checkbox>
        </span>
      </div>
    </header>

    <div
      class="content-body content-base-height without-padding has-loading-logo"
      *ngIf="loadings.todos"
    >
      <i class="loading-logo"></i>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.todos.length === 0 && !loadings.todos"
    >
      <span class="content-no-message">Keine offenen Todos vorhanden</span>
    </div>

    <div
      class="content-body content-base-height without-padding"
      *ngIf="data.todos.length > 0 && !loadings.todos"
    >
      <p-table
        [value]="data.todos"
        [rowHover]="true"
        [scrollable]="true"
        scrollHeight="100%"
        stateStorage="local"
        stateKey="state-dashboard-todos"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th style="max-width: 50px"></th>
            <th style="max-width: 50px" pSortableColumn="calculated_priority">
              <i
                style="font-size: 15px"
                class="pi pi-flag"
                pTooltip="Nach Priorität"
              ></i>
              <p-sortIcon field="calculated_priority"></p-sortIcon>
            </th>
            <th style="max-width: 120px" pSortableColumn="name">
              Name <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th style="max-width: 40px"></th>
            <th>Titel</th>
            <th style="max-width: 140px" pSortableColumn="for_when">
              Zu erledigen <p-sortIcon field="for_when"></p-sortIcon>
            </th>
            <th style="max-width: 50px" pTooltip="Kommentare">
              <i class="pi pi-comment"></i>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-todo>
          <tr
            class="clickable"
            [class.background-hightlight-yellow]="todo.calculated_priority == 1"
          >
            <td style="max-width: 50px" (click)="openShowTodoDialog(todo)">
              <i
                style="font-size: 15px"
                class="pi pi-clock color-alert"
                *ngIf="todo.exceeded"
                pTooltip="Datum überschritten"
              ></i>
            </td>
            <td style="max-width: 50px" (click)="openShowTodoDialog(todo)">
              <i
                style="font-size: 15px"
                class="pi pi-flag color-alert"
                *ngIf="todo.calculated_priority == 1"
                pTooltip="Hohe Priorität"
              ></i>

              <i
                style="font-size: 15px"
                class="pi pi-arrow-down color-gray"
                *ngIf="todo.calculated_priority == 3"
                pTooltip="Niedrige Priorität"
              ></i>
            </td>
            <td
              (click)="openShowTodoDialog(todo)"
              style="max-width: 120px"
              *ngIf="todo.assignment_type === 'PATIENT'"
            >
              <a
                (click)="$event.stopPropagation()"
                routerLink="/patients/{{ todo.patient.id }}"
                >{{ todo.name }}</a
              >
            </td>
            <td
              (click)="openShowTodoDialog(todo)"
              style="max-width: 120px"
              *ngIf="todo.assignment_type === 'CAREGIVER'"
            >
              <a
                (click)="$event.stopPropagation()"
                routerLink="/caregivers/{{ todo.caregiver.id }}"
                >{{ todo.name }}</a
              >
            </td>
            <td style="max-width: 120px" *ngIf="todo.assignment_type === 'CAR'">
              <a
                (click)="$event.stopPropagation()"
                routerLink="/cars/{{ todo.car.id }}"
                >{{ todo.name }}</a
              >
            </td>
            <td
              style="max-width: 120px"
              (click)="openShowTodoDialog(todo)"
              *ngIf="todo.assignment_type === 'GENERAL'"
            >
              {{ todo.name }}
            </td>

            <td (click)="openShowTodoDialog(todo)" style="max-width: 40px">
              <i
                tooltipPosition="left"
                [pTooltip]="todo.comment"
                style="font-size: 16px"
                class="pi pi-info-circle color-gray"
                *ngIf="todo.comment"
              ></i>
            </td>
            <td (click)="openShowTodoDialog(todo)">
              {{ todo.title }}
            </td>
            <td style="max-width: 140px" (click)="openShowTodoDialog(todo)">
              {{ todo.for_when_type === "FINISH_AT" ? "am" : "bis" }}
              {{ todo.for_when_string }}
            </td>
            <td style="max-width: 50px" (click)="openShowTodoDialog(todo)">
              {{ todo.comments_count }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
