import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { CaregiverService } from '../../../services/caregivers.service'
import { CarService } from '../../../services/car.service'
import { UserCaregiverService } from '../../../services/user-caregiver.service'
import { CarFormModel } from '../../../models/car/car-form.model'

@Component({
  selector: 'app-add-car-mileage-dialog',
  templateUrl: './add-car-mileage-dialog.component.html',
})
export class AddCarMileageDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public data: any = {}
  public values = {
    comment: '',
    mileage: null as any,
    since: '',
  }
  currentDate = new Date()

  car = new CarFormModel()

  public submitted = false
  public submittedDelete = false

  constructor(
    private userCaregiverService: UserCaregiverService,
    private ref: DynamicDialogRef,
    private caregiverService: CaregiverService,
    private carService: CarService,
    private config: DynamicDialogConfig,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    if (this.data.from_caregiver) {
      this.loadCaregiversCarData()
    } else {
      this.car = this.data.car
    }

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public store(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    // Validieren vom KM-Stand.
    if (this.car.current_mileage) {
      if (this.values.mileage < this.car.current_mileage.mileage) {
        alert('Der KM-Stand ist kleiner als vorher!')

        return
      }
    }

    this.submitted = true

    let subscription = this.carService.storeMileage(this.car.id, this.values)

    if (this.data.from_caregiver) {
      subscription = this.userCaregiverService.storeCarMileage(this.values)
    }

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.CarDetailReload)
        this.ref.close()
        this.toastService.success(
          'Kilometerstand gespeichert',
          'Der Kilometerstand wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  private loadCaregiversCarData(): void {
    this.userCaregiverService.loadCurrentCar().subscribe((response: any) => {
      this.car = response.car

      this.setTabIndexForIconButtons()
    })
  }

  private setTabIndexForIconButtons(): void {
    setTimeout(() => {
      const elements = document.querySelectorAll('.p-button-icon-only')

      elements.forEach((element) => {
        element.setAttribute('tabindex', '-1')
      })
    }, 200)
  }
}
