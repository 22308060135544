import { CarsDetailModel } from './cars-detail.model'
import { CaregiverDetailModel } from '../caregiver/caregiver-detail.model'
import { UserModel } from '../user/user.model'
import { CarDamageCommentModel } from './car-damage-comment.model'
import { MediaModel } from '../document/media.model'

export class CarDamageModel {
  id: number | null = null
  car_id: number | null = null
  tracked_by_id: number | undefined | null = null
  caregiver_id: number | null = null
  mileage: any = null
  comment = ''
  description = ''
  deductible = ''
  deductible_from_caregiver = ''
  deductible_from_caregiver_text = ''
  deductible_text = ''
  deductible_comment = ''
  deductible_amount = null
  deductible_amount_formatted = ''
  deductible_at: Date | null = null
  deductible_at_string = ''
  type = ''
  drive_type = ''
  from_caregiver = false
  drive_type_text = ''
  police_contacted = ''
  police_contacted_text = ''
  police_comment = ''
  type_text = ''
  guilt = ''
  guilt_text = ''
  created_at: Date | null = null
  updated_at: Date | null = null
  deleted_at: Date | null = null
  date: Date | string = ''
  comments_count = 0
  status: null | string = null

  media: MediaModel[] = []
  tracked_by: UserModel = new UserModel()
  car: CarsDetailModel = new CarsDetailModel()
  caregiver: CaregiverDetailModel = new CaregiverDetailModel()
  comments: CarDamageCommentModel[] = []
}
