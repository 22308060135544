<div class="content-actions">
  <i class="tio icon-large">file_text_outlined</i>

  <h2>
    <i class="tio">file_text_outlined</i>
    Verhinderungspflege
  </h2>
</div>

<header class="content-header-tabs">
  <a
    routerLink="/vhp"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Offen
  </a>
  <a
    routerLink="/vhp/edited"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    In Bearbeitung
  </a>
  <a
    routerLink="/vhp/finished"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Abgeschlossen
  </a>
</header>

<router-outlet></router-outlet>
