import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { CaregiverService } from '../../../services/caregivers.service'
import { CarService } from '../../../services/car.service'
import { HelperService } from '../../../services/helper.service'
import { CaregiverDetailModel } from '../../../models/caregiver/caregiver-detail.model'

@Component({
  selector: 'app-add-car-status-dialog',
  templateUrl: './add-car-status-dialog.component.html',
})
export class AddCarStatusDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  caregivers: CaregiverDetailModel[] = []

  public data: any = {}
  public values = {
    status: '',
    comment: '',
    caregiver_id: null,
    mileage: null as any,
    since: '',
  }

  public statusOptions = [
    { label: 'Aktiv', value: 'ACTIVE' },
    { label: 'Parkt', value: 'PARKING' },
    { label: 'Werkstatt', value: 'SHOP' },
  ]

  public submitted = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private caregiverService: CaregiverService,
    private carService: CarService,
    private helperService: HelperService,
    private config: DynamicDialogConfig,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.loadCaregivers()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public store(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    // Validieren vom KM-Stand.
    if (this.data.car.current_mileage) {
      if (this.values.mileage < this.data.car.current_mileage.mileage) {
        alert('Der KM-Stand ist kleiner als vorher!')

        return
      }
    }

    this.submitted = true

    this.carService.storeStatus(this.data.car.id, this.values).subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.CarDetailReload)
        this.ref.close()
        this.toastService.success(
          'Status gespeichert',
          'Der Status wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  private loadCaregivers(): void {
    this.helperService.dependencies$.subscribe((data: any) => {
      this.caregivers.push(...data['caregivers_active'])
      this.caregivers.push(...data['caregivers_deactivated'])
    })
  }

  public remove(): void {
    // this.submittedDelete = true
    //
    // this.carService.deleteForCaregiver(this.data.car.id).subscribe(
    //   () => {
    //     this.submittedDelete = false
    //     this.eventbus.emit(GlobalEvent.CaregiverDetailReload)
    //     this.ref.close()
    //     this.toastService.success(
    //       'PKW gelöscht',
    //       'PKW wurde erfolgreich gelöscht'
    //     )
    //   },
    //   () => {
    //     this.submittedDelete = false
    //     this.toastService.error(
    //       'Löschen fehlgeschlagen',
    //       'Der Eintrag konnte nicht gelöscht werden'
    //     )
    //   }
    // )
  }
}
