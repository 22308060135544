<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i class="pi pi-exclamation-triangle"></i>
      Alle Schäden
    </span>

    <button
      pButton
      (click)="openDamageDialog()"
      label="Neuer Schaden"
      type="button"
      class="p-button-outlined"
      icon="pi pi-plus"
      iconPos="left"
    ></button>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading">
  <div class="content-body auto-height" *ngIf="damages.length === 0">
    <span class="content-no-message">Keine Schäden vorhanden</span>
  </div>
  <div
    class="content-body without-padding auto-height"
    *ngIf="damages.length > 0"
  >
    <p-table #dt [value]="damages" [rowHover]="true">
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="width: 80px" pSortableColumn="from_caregiver">
            <i pTooltip="Durch BK erstellt" class="pi pi-user"></i>
            <p-sortIcon field="from_caregiver"></p-sortIcon>
          </th>
          <th style="width: 100px" pSortableColumn="status">
            Offen <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th style="width: 140px" pSortableColumn="type">
            Typ <p-sortIcon field="type"></p-sortIcon>
          </th>
          <th style="width: 100px" pSortableColumn="date">
            Am <p-sortIcon field="date"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="guilt_text">
            Schuld <p-sortIcon field="guilt_text"></p-sortIcon>
          </th>
          <th style="width: 200px" pSortableColumn="caregiver.last_name">
            Betreuungskraft
            <p-sortIcon field="caregiver.last_name"></p-sortIcon>
          </th>
          <th style="width: 80px" pSortableColumn="mileage">
            KM <p-sortIcon field="mileage"></p-sortIcon>
          </th>
          <th>Schadensbild</th>
          <th>Bemerkung</th>
          <th style="width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-damage>
        <tr class="clickable">
          <td style="width: 80px" (click)="openShowDamageDialog(damage)">
            <i
              *ngIf="damage.from_caregiver"
              pTooltip="Durch BK erstellt"
              class="pi pi-user color-main1"
            ></i>
          </td>
          <td style="width: 100px" (click)="openShowDamageDialog(damage)">
            <div class="text-center-container">
              <i
                *ngIf="damage.status === 'OPEN'"
                class="pi pi-exclamation-triangle color-alert"
              ></i>
            </div>
          </td>
          <td (click)="openShowDamageDialog(damage)">
            <div class="text-center-container">
              <i class="icon-car-crash" *ngIf="damage.type === 'CRASH'"></i>
              <i class="icon-car-damage" *ngIf="damage.type !== 'CRASH'"></i>
              {{ damage.type_text }}
            </div>
          </td>
          <td (click)="openShowDamageDialog(damage)">
            {{ damage.date | date: "dd.MM.y" }}
          </td>
          <td (click)="openShowDamageDialog(damage)">
            {{ damage.guilt_text }}
          </td>

          <td (click)="openShowDamageDialog(damage)" *ngIf="damage.caregiver">
            <a
              (click)="$event.stopPropagation()"
              routerLink="/caregivers/{{ damage.caregiver_id }}"
            >
              {{ damage.caregiver.first_name }} {{ damage.caregiver.last_name }}
            </a>
          </td>
          <td (click)="openShowDamageDialog(damage)" *ngIf="!damage.caregiver">
            -
          </td>

          <td *ngIf="damage.mileage" (click)="openShowDamageDialog(damage)">
            {{ damage.mileage | number }}
          </td>
          <td *ngIf="!damage.mileage" (click)="openShowDamageDialog(damage)">
            -
          </td>

          <td (click)="openShowDamageDialog(damage)">
            {{ damage.description || "-" }}
          </td>
          <td (click)="openShowDamageDialog(damage)">
            {{ damage.comment || "-" }}
          </td>
          <td class="table-action" style="width: 100px">
            <i
              (click)="openDamageDialog(damage)"
              pTooltip="Bearbeiten"
              tooltipPosition="left"
              class="pi pi-pencil with-margin-right"
            ></i>

            <i
              (click)="openShowDamageDialog(damage)"
              pTooltip="Ansehen"
              tooltipPosition="left"
              class="pi pi-eye"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
