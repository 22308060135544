import { CarsDetailModel } from './cars-detail.model'
import { MediaModel } from '../document/media.model'

export class CarGarageAppointmentModel {
  id: number | null = null
  car_id: number | null = null
  type = ''
  type_text = ''
  comment = ''
  from = ''
  mileage: any = null
  created_at: Date | null = null
  updated_at: Date | null = null
  deleted_at: Date | null = null
  date: Date | string = ''
  date_string: Date | string = ''
  car: CarsDetailModel = new CarsDetailModel()

  media: MediaModel[] = []
}
