<form #form="ngForm" class="dialog-form">
  <div class="dialog-sidebar space-between">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <div class="dialog-text-link">
          <span (click)="openAddDocumentDialog()">Dokument hochladen</span>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label class="highlight-label">Hochgeladene Dokumente</label>
        <div class="dialog-no-message" *ngIf="damage.media.length === 0">
          Keine Dokumente hochgeladen
        </div>

        <div class="document-list" *ngFor="let document of damage.media">
          <span
            >{{ document.name }}
            <a
              [href]="documentService.getDocumentDownloadLink(document.uuid)"
              target="_blank"
              >(ansehen)</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div class="dialog-detail-information-body">
      <div class="dialog-detail-information">
        <div class="dialog-detail-key p-align-center"><span>Status</span></div>
        <div class="dialog-detail-value selectbutton-small">
          <p-selectButton
            [options]="statusOptions"
            [(ngModel)]="damage.status"
            name="status"
            (onChange)="changeStatus()"
          >
            <ng-template let-item>
              <span [style]="{ backgroundColor: item.backgroundColor }">{{
                item.label
              }}</span>
            </ng-template>
          </p-selectButton>
        </div>
      </div>

      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Schaden am</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ damage.date | date: "dd.MM.y" }}
            {{
              damage.mileage ? " (" + (damage.mileage | number) + " KM)" : ""
            }}
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Aufgenommen am</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ damage.created_at | date: "dd.MM.y" }} von
            {{ damage.tracked_by.name }}
          </div>
        </div>
      </div>
      <div class="dialog-detail-information" *ngIf="damage.type">
        <div class="dialog-detail-key"><span>Typ</span></div>
        <div class="dialog-detail-value">
          <div class="text-center-container">
            <i class="icon-car-crash" *ngIf="damage.type === 'CRASH'"></i>
            <i class="icon-car-damage" *ngIf="damage.type !== 'CRASH'"></i>
            {{ damage.type_text }}
          </div>
        </div>
      </div>
      <div class="dialog-detail-information" *ngIf="damage.drive_type">
        <div class="dialog-detail-key"><span>Fahrt</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ damage.drive_type_text }}
          </div>
        </div>
      </div>
      <div class="dialog-detail-information" *ngIf="damage.police_contacted">
        <div class="dialog-detail-key"><span>Polizei hinzugezogen?</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ damage.police_contacted_text }} ({{ damage.police_comment }})
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Schuld</span></div>
        <div class="dialog-detail-value">
          <div>{{ damage.guilt_text }}</div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Betreuungskraft</span></div>
        <div class="dialog-detail-value">
          <div>{{ damage.caregiver?.full_name || "-" }}</div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Schadensbild</span></div>
        <div class="dialog-detail-value">
          <strong style="white-space: pre-wrap">{{
            damage.description || "-"
          }}</strong>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Bemerkung</span></div>
        <div class="dialog-detail-value">
          <strong style="white-space: pre-wrap">{{
            damage.comment || "-"
          }}</strong>
        </div>
      </div>

      <div
        class="dialog-detail-information"
        *ngIf="damage.guilt == 'OTHER' || damage.guilt == 'CAREGIVER'"
      >
        <div class="dialog-detail-key">
          <span>SB einbehalten?</span>
        </div>
        <div class="dialog-detail-value" style="flex-direction: column">
          <strong *ngIf="damage.from_caregiver">
            {{ damage.deductible_from_caregiver_text }}
          </strong>
          <strong>
            {{ damage.deductible_text }}

            <ng-container *ngIf="damage.deductible === 'YES'">
              ({{ damage.deductible_at_string }},
              {{ damage.deductible_amount_formatted }})
            </ng-container>
          </strong>
          <strong style="white-space: pre-wrap">{{
            damage.deductible_comment || "-"
          }}</strong>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form-body without-padding">
    <div class="dialog-comments">
      <div
        class="dialog-comment dialog-no-comment"
        *ngIf="damage.comments.length === 0"
      >
        Noch keine Kommentare
      </div>
      <div class="dialog-comment" *ngFor="let comment of damage.comments">
        <!-- Den Kommentar darf nur der Ersteller bearbeiten -->
        <i
          *ngIf="currentUser?.id === comment.user_id"
          class="pi pi-pencil"
          pTooltip="Bearbeiten"
          (click)="openEditMode(comment)"
        ></i>
        <strong>{{ comment.comment }}</strong>
        <span
          >{{ comment.created_from.name }} am
          {{ comment.created_at | date: "dd.MM.y, H:mm" }} Uhr</span
        >
      </div>
    </div>
  </div>
  <div class="dialog-form-footer dialog-form-footer-textarea">
    <textarea
      *ngIf="!editComment"
      id="comment"
      name="comment"
      [(ngModel)]="values.comment"
      [rows]="2"
      placeholder="Kommentar..."
      pInputTextarea
    ></textarea>

    <textarea
      *ngIf="editComment"
      id="comment_edit"
      name="comment_edit"
      [(ngModel)]="values.comment"
      [rows]="2"
      pInputTextarea
    ></textarea>

    <div class="dialog-form-footer-actions">
      <button
        *ngIf="!editComment"
        pButton
        tabindex="-1"
        label="Bearbeiten"
        class="p-button-text button-without-padding"
        type="button"
        (click)="openDamageDialog()"
        icon="pi pi-pencil"
      ></button>

      <button
        *ngIf="editComment"
        pButton
        label="Abbrechen"
        class="p-button-text button-without-padding"
        type="button"
        (click)="cancelEditMode()"
        icon="pi pi-times"
      ></button>

      <button
        *ngIf="!editComment"
        (click)="save()"
        pButton
        label="Kommentar erstellen"
        type="button"
        [disabled]="submitted || !values.comment"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>

      <button
        *ngIf="editComment"
        (click)="update()"
        pButton
        label="Kommentar bearbeiten"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>
    </div>
  </div>
</form>
