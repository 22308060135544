import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { CarGarageAppointmentModel } from '../../../../models/car/car-garage-appointment.model'
import { CarGarageAppointmentService } from '../../../../services/car-garage-appointment.service'
import { AddCarGarageAppointmentDialogComponent } from '../../../../components/dialogs/add-car-garage-appointment-dialog/add-car-garage-appointment-dialog.component'

@Component({
  selector: 'app-cars-detail-garage',
  templateUrl: './cars-detail-garage.component.html',
  providers: [DialogService],
})
export class CarsDetailGarageComponent implements OnInit, OnDestroy {
  public loading = true
  public garageAppointments: CarGarageAppointmentModel[] = []
  public carId: string | null = null

  private routeSubscription: any = null
  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private carGarageAppointmentService: CarGarageAppointmentService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    if (this.route.parent) {
      this.routeSubscription = this.route.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.carId = params.get('id')

          this.loadData()
        }
      )
    }

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.GarageAppointmentChanged:
            this.loadData(false)
        }
      }
    )
  }

  public openCarGarageAppointmentDialog(
    garageAppointment: CarGarageAppointmentModel | null = null
  ): void {
    this.dialogService.open(AddCarGarageAppointmentDialogComponent, {
      header: garageAppointment
        ? 'Werkstatttermin bearbeiten'
        : 'Neuer Werkstatttermin',
      width: '450px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        isEdit: garageAppointment !== null,
        garage_appointment_id: garageAppointment?.id,
        car_id: this.carId,
      },
    })
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.carGarageAppointmentService
      .list(this.carId)
      .subscribe((data: CarGarageAppointmentModel[]) => {
        this.garageAppointments = data

        this.loading = false
      })
  }
}
