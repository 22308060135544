import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { CarDamageModel } from '../../../../models/car/car-damage.model'
import { ShowDamageDialogComponent } from '../../../../components/dialogs/show-damage-dialog/show-damage-dialog.component'
import { AddCarCostDialogComponent } from '../../../../components/dialogs/add-car-cost-dialog/add-car-cost-dialog.component'
import { CarCostService } from '../../../../services/car-cost.service'
import { CarCostModel } from '../../../../models/car/car-cost.model'

@Component({
  selector: 'app-cars-detail-costs',
  templateUrl: './cars-detail-costs.component.html',
  providers: [DialogService],
})
export class CarsDetailCostsComponent implements OnInit, OnDestroy {
  public loading = true
  public costs: CarCostModel[] = []
  public carId: string | null = null

  private routeSubscription: any = null
  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private carCostService: CarCostService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    if (this.route.parent) {
      this.routeSubscription = this.route.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.carId = params.get('id')

          this.loadCosts()
        }
      )
    }

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CarCostChanged:
            this.loadCosts(false)
        }
      }
    )
  }

  public openCarCostDialog(cost: CarDamageModel | null = null): void {
    this.dialogService.open(AddCarCostDialogComponent, {
      header: cost ? 'Faktor bearbeiten' : 'Neuer Faktor',
      width: '450px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        isEdit: cost !== null,
        cost_id: cost?.id,
        car_id: this.carId,
      },
    })
  }

  public loadCosts(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.carCostService.list(this.carId).subscribe((data: CarCostModel[]) => {
      this.costs = data

      this.loading = false
    })
  }
}
