<form #form="ngForm" class="dialog-form">
  <div class="dialog-sidebar space-between" *ngIf="!data.from_caregiver">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <div class="dialog-text-link">
          <span (click)="openAddDocumentDialog()">Dokument hochladen</span>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label class="highlight-label">Hochgeladene Dokumente</label>
        <div class="dialog-no-message" *ngIf="values.media.length === 0">
          Keine Dokumente hochgeladen
        </div>
        <div class="document-list" *ngFor="let document of values.media">
          <span
            >{{ document.name }}
            <a
              [href]="documentService.getDocumentDownloadLink(document.uuid)"
              target="_blank"
              >(ansehen)</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid" *ngIf="data.from_caregiver">
      <div class="p-field p-col-12">
        <label for="file">Bilder hochladen</label>
        <input
          type="file"
          (change)="onFileSelected($event)"
          multiple
          accept="image/*"
        />
        <div class="preview-container" *ngIf="imagePreviews.length > 0">
          <div
            *ngFor="let image of imagePreviews; let i = index"
            class="preview-item"
          >
            <img [src]="image" class="preview-img" />
            <button (click)="removeImage(i)">✖</button>
          </div>
        </div>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Schaden"
              name="type"
              [(ngModel)]="values.type"
              value="DAMAGE"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Unfall"
              name="type"
              [(ngModel)]="values.type"
              value="CRASH"
            ></p-radioButton>
          </div>
        </div>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-6">
        <label for="date"
          >{{ values.type === "CRASH" ? "Unfall" : "Schaden" }} am *</label
        >
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          [maxDate]="currentDate"
          inputId="date"
          name="date"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.date"
          dataType="string"
          [showIcon]="true"
          [required]="true"
          #date="ngModel"
          [showButtonBar]="true"
          [class.p-invalid]="date.invalid && (date.dirty || date.touched)"
        ></p-calendar>
      </div>

      <div class="p-field p-col-6">
        <label for="mileage">Kilometerstand *</label>
        <p-inputNumber
          inputId="mileage"
          [min]="0"
          name="mileage"
          locale="de-DE"
          [required]="true"
          #mileage="ngModel"
          [class.p-invalid]="
            mileage.invalid && (mileage.dirty || mileage.touched)
          "
          [(ngModel)]="values.mileage"
        ></p-inputNumber>
        <small *ngIf="currentMileage"
          >Letzter Stand: {{ currentMileage | number }} KM</small
        >
      </div>

      <div class="p-field p-col-12">
        <label for="guilt"
          >Schuld am
          {{ values.type === "CRASH" ? "Unfall" : "Schaden" }}
          *</label
        >
        <p-dropdown
          name="guilt"
          inputId="guilt"
          placeholder="Bitte auswählen"
          [(ngModel)]="values.guilt"
          [options]="guiltOptions"
          #guilt="ngModel"
          [required]="true"
          [class.p-invalid]="guilt.invalid && (guilt.dirty || guilt.touched)"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-12" *ngIf="!data.from_caregiver">
        <label for="caregivers"
          >Beteiligte Betreuungskraft
          {{ values.guilt === "CAREGIVER" ? "*" : "" }}</label
        >

        <p-autoComplete
          field="full_name"
          inputId="caregiver"
          name="caregiver"
          appendTo="body"
          [forceSelection]="true"
          [(ngModel)]="values.caregiver"
          [suggestions]="caregivers"
          (completeMethod)="searchCaregivers($event)"
          [required]="values.guilt === 'CAREGIVER'"
          #caregiver="ngModel"
          [class.p-invalid]="
            caregiver.invalid && (caregiver.dirty || caregiver.touched)
          "
        >
          <ng-template let-caregiver pTemplate="item">
            <div>{{ caregiver.full_name }} ({{ caregiver.status_string }})</div>
          </ng-template>
        </p-autoComplete>
      </div>

      <div class="p-field p-col-12">
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Dienstliche Fahrt"
              name="drive_type"
              [(ngModel)]="values.drive_type"
              value="OFFICIAL"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Private Fahrt"
              name="drive_type"
              [(ngModel)]="values.drive_type"
              value="PRIVATE"
            ></p-radioButton>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12">
        <label for="description">Schadensbild</label>
        <textarea
          id="description"
          name="description"
          [(ngModel)]="values.description"
          [rows]="3"
          pInputTextarea
        ></textarea>
      </div>

      <div class="p-field p-col-12">
        <label>Polizei hinzugezogen?</label>
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Ja"
              name="police_contacted"
              [(ngModel)]="values.police_contacted"
              value="YES"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Nein"
              name="police_contacted"
              [(ngModel)]="values.police_contacted"
              value="NO"
            ></p-radioButton>
          </div>
        </div>
      </div>

      <div class="p-field p-col-12" *ngIf="values.police_contacted">
        <label for="comment">{{
          values.police_contacted === "NO"
            ? "Wieso keine Polizei?"
            : "Welche Dienststelle, Name Polizeibeamter, etc."
        }}</label>
        <textarea
          id="police_comment"
          name="police_comment"
          [(ngModel)]="values.police_comment"
          [rows]="2"
          pInputTextarea
        ></textarea>
      </div>

      <div class="p-field p-col-12">
        <label for="comment">Bemerkung</label>
        <textarea
          id="comment"
          name="comment"
          [(ngModel)]="values.comment"
          [rows]="3"
          pInputTextarea
        ></textarea>
      </div>

      <div
        class="p-field p-col-12"
        *ngIf="!data.from_caregiver && !values.from_caregiver"
      >
        <label for="tracked_by">Aufgenommen von *</label>
        <p-dropdown
          [filter]="true"
          scrollHeight="360px"
          inputId="tracked_by"
          name="tracked_by"
          [(ngModel)]="values.tracked_by_id"
          appendTo="body"
          placeholder="Bitte auswählen"
          [resetFilterOnHide]="true"
          [options]="userSystemOptions"
          optionLabel="full_name"
          optionValue="id"
          [required]="true"
          #tracked_by="ngModel"
          [class.p-invalid]="
            tracked_by.invalid && (tracked_by.dirty || tracked_by.touched)
          "
        ></p-dropdown>
      </div>

      <ng-container
        *ngIf="
          (values.guilt == 'OTHER' || values.guilt == 'CAREGIVER') &&
          !data.from_caregiver
        "
      >
        <div class="p-field p-col-12">
          <label>SB einbehalten?</label>
          <div class="p-formgroup-inline">
            <div class="p-field-radiobutton">
              <p-radioButton
                label="Ja"
                name="deductible"
                [(ngModel)]="values.deductible"
                value="YES"
              ></p-radioButton>
            </div>
            <div class="p-field-radiobutton">
              <p-radioButton
                label="Nein"
                name="deductible"
                [(ngModel)]="values.deductible"
                value="NO"
              ></p-radioButton>
            </div>
          </div>
        </div>

        <div class="p-field p-col-6" *ngIf="values.deductible === 'YES'">
          <label for="deductible_at_string">Zeitpunkt</label>
          <p-calendar
            [firstDayOfWeek]="1"
            [showWeek]="true"
            inputId="deductible_at_string"
            name="deductible_at_string"
            dateFormat="dd.mm.yy"
            appendTo="body"
            [(ngModel)]="values.deductible_at_string"
            dataType="string"
            [showIcon]="true"
            [showButtonBar]="true"
          ></p-calendar>
        </div>

        <div class="p-field p-col-6" *ngIf="values.deductible === 'YES'">
          <label for="deductible_amount">Höhe</label>
          <p-inputNumber
            mode="currency"
            currency="EUR"
            locale="de-DE"
            inputId="deductible_amount"
            name="deductible_amount"
            [(ngModel)]="values.deductible_amount"
          ></p-inputNumber>
        </div>

        <div class="p-field p-col-12" *ngIf="values.deductible">
          <label for="deductible_comment">Kommentar zur SB</label>
          <textarea
            id="deductible_comment"
            name="deductible_comment"
            [(ngModel)]="values.deductible_comment"
            [rows]="2"
            pInputTextarea
          ></textarea>
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          (values.guilt == 'OTHER' || values.guilt == 'CAREGIVER') &&
          data.from_caregiver
        "
      >
        <div class="p-field p-col-12">
          <label>Wie soll die SB einbehalten werden? *</label>
          <div class="p-formgroup-inline">
            <div class="p-field-radiobutton">
              <p-radioButton
                label="In zwei Raten"
                name="deductible_from_caregiver"
                [(ngModel)]="values.deductible_from_caregiver"
                value="PAYMENT"
                required
                #deductible_from_caregiver="ngModel"
              ></p-radioButton>
            </div>
            <div class="p-field-radiobutton">
              <p-radioButton
                label="Nächstes Gehalt vollständig"
                name="deductible_from_caregiver"
                [(ngModel)]="values.deductible_from_caregiver"
                value="FULL"
                required
                #deductible_from_caregiver="ngModel"
              ></p-radioButton>
            </div>
          </div>

          <div
            *ngIf="
              deductible_from_caregiver.invalid &&
              (deductible_from_caregiver.dirty ||
                deductible_from_caregiver.touched)
            "
          >
            <div *ngIf="deductible_from_caregiver.errors?.required">
              <small class="p-invalid">Bitte wählen Sie etwas aus</small>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="dialog-form-footer">
    <i *ngIf="!data.isEdit"></i>

    <div
      *ngIf="data.isEdit"
      style="display: flex; align-items: center; gap: 20px"
    >
      <i
        class="pi pi-trash"
        [class.pi-trash]="!submittedDelete"
        [class.pi-spin]="submittedDelete"
        [class.pi-spinner]="submittedDelete"
        tooltipPosition="left"
        tooltipZIndex="20000"
        [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Schaden löschen'"
        (click)="remove($event)"
      ></i>
      <button
        pButton
        label="Ansehen"
        class="p-button-text button-without-padding"
        type="button"
        (click)="openShowDamageDialog()"
        icon="pi pi-eye"
      ></button>
    </div>

    <button
      (click)="save()"
      pButton
      [label]="data.isEdit ? 'Speichern' : 'Erstellen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
