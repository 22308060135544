<p-confirmPopup></p-confirmPopup>
<app-loader [loading]="loading"></app-loader>

<div class="content-actions limit-width" *ngIf="!loading">
  <h2>
    <i class="tio">car</i>
    {{ isEditMode ? "PKW bearbeiten" : "Neuer PKW" }}
  </h2>
  <button
    *ngIf="
      isEditMode && !data.deleted_at && authService.can('PKW.PKW deaktivieren')
    "
    pButton
    (click)="deactivate($event)"
    label="Deaktivieren"
    type="button"
    icon="pi pi-times"
    class="p-button-danger"
  ></button>
  <button
    *ngIf="isEditMode && data.deleted_at"
    pButton
    (click)="activate($event)"
    label="Aktivieren"
    type="button"
    icon="pi pi-check"
    class="p-button"
  ></button>
</div>

<form #form="ngForm">
  <div class="content limit-width" *ngIf="!loading">
    <header class="content-header">
      <span>Daten</span>
    </header>

    <div class="content-body">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-3">
          <label>Gruppe</label>
          <div class="p-formgroup-inline">
            <div class="p-field-radiobutton">
              <p-radioButton
                label="Außendienst"
                name="group"
                [(ngModel)]="data.group"
                value="FIELD_WORK"
              ></p-radioButton>
            </div>
            <div class="p-field-radiobutton">
              <p-radioButton
                label="Sonstiges"
                name="group"
                [(ngModel)]="data.group"
                value="OTHER"
              ></p-radioButton>
            </div>
          </div>
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-4">
          <label for="brand">Marke *</label>
          <input
            id="brand"
            name="brand"
            [(ngModel)]="data.brand"
            type="text"
            pInputText
            #brand="ngModel"
            required
            [class.p-invalid]="brand.invalid && (brand.dirty || brand.touched)"
          />
          <div
            *ngIf="brand.invalid && (brand.dirty || brand.touched)"
            class="p-invalid"
          >
            <div *ngIf="brand.errors?.required">
              <small>Bitte geben Sie eine Marke ein</small>
            </div>
          </div>
        </div>

        <div class="p-field p-col-4">
          <label for="model">Modell *</label>
          <input
            id="model"
            name="model"
            [(ngModel)]="data.model"
            type="text"
            pInputText
            #model="ngModel"
            required
            [class.p-invalid]="model.invalid && (model.dirty || model.touched)"
          />
          <div
            *ngIf="model.invalid && (model.dirty || model.touched)"
            class="p-invalid"
          >
            <div *ngIf="model.errors?.required">
              <small>Bitte geben Sie eine Modell ein</small>
            </div>
          </div>
        </div>

        <div class="p-field p-col-4">
          <label for="license_plate">Kennzeichen *</label>
          <input
            id="license_plate"
            name="license_plate"
            [(ngModel)]="data.license_plate"
            type="text"
            pInputText
            oninput="this.value = this.value.toUpperCase()"
            pattern="^[A-ZÄÖÜ]{1,3} - [A-Z]{1,2} \d{1,4}$"
            #license_plate="ngModel"
            required
            [class.p-invalid]="
              license_plate.invalid &&
              (license_plate.dirty || license_plate.touched)
            "
          />

          <div
            *ngIf="
              license_plate.invalid &&
              (license_plate.dirty || license_plate.touched)
            "
            class="p-invalid"
          >
            <div *ngIf="license_plate.errors?.pattern">
              <small>Ungültiges Kennzeichen! Format: ABC - XY 123</small>
            </div>

            <div *ngIf="license_plate.errors?.required">
              <small>Bitte geben Sie ein Kennzeichen ein</small>
            </div>
          </div>
        </div>
      </div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-4">
          <label for="initial_registration">Erstzulassung</label>
          <p-calendar
            [firstDayOfWeek]="1"
            [showWeek]="true"
            inputId="initial_registration"
            name="initial_registration"
            [showButtonBar]="true"
            [(ngModel)]="data.initial_registration_string"
            dateFormat="dd.mm.yy"
            [showIcon]="true"
            dataType="string"
          ></p-calendar>
        </div>
        <div class="p-field p-col-4" *ngIf="!isEditMode">
          <label for="mileage">Kilometerstand</label>
          <p-inputNumber
            inputId="mileage"
            name="mileage"
            locale="de-DE"
            [(ngModel)]="data.mileage"
          ></p-inputNumber>
        </div>
        <div class="p-field p-col-4" *ngIf="!isEditMode">
          <label for="mileage_at">Kilometerstand seit</label>
          <p-calendar
            [firstDayOfWeek]="1"
            [showWeek]="true"
            inputId="mileage_at"
            name="mileage_at"
            [showButtonBar]="true"
            [(ngModel)]="data.mileage_at_string"
            dateFormat="dd.mm.yy"
            [showIcon]="true"
            dataType="string"
          ></p-calendar>
        </div>
      </div>
    </div>
  </div>

  <div class="content limit-width" *ngIf="!loading">
    <header class="content-header">
      <span>Zusatz</span>
    </header>

    <div class="content-body">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-3">
          <label>Privatnutzung erlaubt?</label>
          <div class="p-formgroup-inline">
            <div class="p-field-radiobutton">
              <p-radioButton
                label="Nein"
                name="private_use_allowed"
                [(ngModel)]="data.private_use_allowed"
                value="Nein"
              ></p-radioButton>
            </div>
            <div class="p-field-radiobutton">
              <p-radioButton
                label="Ja"
                name="private_use_allowed"
                [(ngModel)]="data.private_use_allowed"
                value="Ja"
              ></p-radioButton>
            </div>
          </div>
        </div>

        <div class="p-field p-col-3">
          <label>Navi?</label>
          <div class="p-formgroup-inline">
            <div class="p-field-radiobutton">
              <p-radioButton
                label="Nein"
                name="navigation"
                [(ngModel)]="data.navigation"
                value="Nein"
              ></p-radioButton>
            </div>
            <div class="p-field-radiobutton">
              <p-radioButton
                label="Ja"
                name="navigation"
                [(ngModel)]="data.navigation"
                value="Ja"
              ></p-radioButton>
            </div>
          </div>
        </div>

        <div class="p-field p-col-3">
          <label>Portables Navi?</label>
          <div class="p-formgroup-inline">
            <div class="p-field-radiobutton">
              <p-radioButton
                label="Nein"
                name="portable_navigation"
                [(ngModel)]="data.portable_navigation"
                value="Nein"
              ></p-radioButton>
            </div>
            <div class="p-field-radiobutton">
              <p-radioButton
                label="Ja"
                name="portable_navigation"
                [(ngModel)]="data.portable_navigation"
                value="Ja"
              ></p-radioButton>
            </div>
          </div>
        </div>

        <div class="p-field p-col-3" *ngIf="data.portable_navigation === 'Ja'">
          <label for="navigation_number">Navi Nummer</label>
          <input
            id="navigation_number"
            name="navigation_number"
            [(ngModel)]="data.navigation_number"
            type="text"
            pInputText
          />
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-3">
          <label>Tankkarte?</label>
          <div class="p-formgroup-inline">
            <div class="p-field-radiobutton">
              <p-radioButton
                label="Nein"
                name="fuel_card"
                [(ngModel)]="data.fuel_card"
                value="Nein"
              ></p-radioButton>
            </div>
            <div class="p-field-radiobutton">
              <p-radioButton
                label="Ja"
                name="fuel_card"
                [(ngModel)]="data.fuel_card"
                value="Ja"
              ></p-radioButton>
            </div>
          </div>
        </div>

        <div class="p-field p-col-3" *ngIf="data.fuel_card === 'Ja'">
          <label for="fuel_card_number">Tankkartennummer</label>
          <input
            id="fuel_card_number"
            name="fuel_card_number"
            [(ngModel)]="data.fuel_card_number"
            type="text"
            pInputText
          />
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-6">
          <label for="summer_tires_warehouse">Sommerreifen Lager</label>
          <input
            id="summer_tires_warehouse"
            name="summer_tires_warehouse"
            [(ngModel)]="data.summer_tires_warehouse"
            type="text"
            pInputText
          />
        </div>
        <div class="p-field p-col-6">
          <label for="winter_tires_warehouse">Winterreifen Lager</label>
          <input
            id="winter_tires_warehouse"
            name="winter_tires_warehouse"
            [(ngModel)]="data.winter_tires_warehouse"
            type="text"
            pInputText
          />
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-6">
          <label for="current_place">Aktueller Standort</label>
          <input
            id="current_place"
            name="current_place"
            [(ngModel)]="data.current_place"
            type="text"
            pInputText
          />
        </div>
      </div>
    </div>
  </div>

  <div class="content-footer limit-width sticky" *ngIf="!loading">
    <span></span>

    <button
      (click)="save()"
      pButton
      [label]="isEditMode ? 'Änderungen speichern' : 'PKW erstellen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
