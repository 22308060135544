<form #form="ngForm" class="dialog-form">
  <div class="dialog-sidebar space-between">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <div class="dialog-text-link">
          <span (click)="openAddDocumentDialog()">Dokument hochladen</span>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label class="highlight-label">Hochgeladene Dokumente</label>
        <div class="dialog-no-message" *ngIf="values.media.length === 0">
          Keine Dokumente hochgeladen
        </div>
        <div class="document-list" *ngFor="let document of values.media">
          <span
            >{{ document.name }}
            <a
              [href]="documentService.getDocumentDownloadLink(document.uuid)"
              target="_blank"
              >(ansehen)</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="TÜV"
              name="type"
              [(ngModel)]="values.type"
              value="TUV"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Inspektion"
              name="type"
              [(ngModel)]="values.type"
              value="INSPECTION"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Sonstiges"
              name="type"
              [(ngModel)]="values.type"
              value="OTHER"
            ></p-radioButton>
          </div>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-6">
        <label for="date">Termin am *</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="date"
          name="date"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.date"
          dataType="string"
          [showIcon]="true"
          [required]="true"
          #date="ngModel"
          [showButtonBar]="true"
          [class.p-invalid]="date.invalid && (date.dirty || date.touched)"
        ></p-calendar>
      </div>

      <div class="p-field p-col-6">
        <label for="mileage">Kilometerstand</label>
        <p-inputNumber
          inputId="mileage"
          name="mileage"
          locale="de-DE"
          [(ngModel)]="values.mileage"
        ></p-inputNumber>
        <small *ngIf="currentMileage"
          >Letzter Stand: {{ currentMileage | number }} KM</small
        >
      </div>

      <div class="p-field p-col-12">
        <label for="comment">Bemerkung</label>
        <textarea
          id="comment"
          name="comment"
          [(ngModel)]="values.comment"
          [rows]="3"
          pInputTextarea
        ></textarea>
      </div>
    </div>
  </div>

  <div class="dialog-form-footer">
    <i *ngIf="!data.isEdit"></i>
    <i
      class="pi pi-trash"
      *ngIf="data.isEdit"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Termin löschen'"
      (click)="remove($event)"
    ></i>

    <button
      (click)="save()"
      pButton
      [label]="data.isEdit ? 'Speichern' : 'Erstellen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
