<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <h2>
    <i class="tio">car</i>
    PKW
  </h2>

  <div class="content-actions-buttons">
    <button
      pButton
      class="p-button p-button-info"
      (click)="openAddCarMileageDialog()"
      label="Kilometer eintragen"
      type="button"
      icon="pi pi-car"
      iconPos="left"
    ></button>

    <button
      pButton
      class="p-button p-button-info"
      (click)="openDamageDialog()"
      label="Schaden melden"
      type="button"
      icon="pi pi-wrench"
      iconPos="left"
    ></button>
  </div>
</div>

<div class="content new-content-container" *ngIf="!loading">
  <header class="content-header" *ngIf="data.length > 0">
    <span> Meine PKW-Einträge </span>
  </header>

  <ng-container>
    <div class="content-body without-padding auto-height">
      <span class="content-no-message with-padding" *ngIf="data.length === 0">
        Keine Einträge vorhanden
      </span>

      <p-table
        [style]="{ minWidth: '800px' }"
        responsiveLayout="scroll"
        [value]="data"
        [rowHover]="true"
        *ngIf="data.length > 0"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th>Eintrag</th>
            <th>Erstellt am</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-history>
          <tr>
            <td>{{ history.manual_title }}</td>
            <td>{{ history.occurred_at | date: "dd.MM.y, H:mm" }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
</div>
