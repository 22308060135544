<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span class="with-normal-checkbox">
      <p-checkbox
        label="PG I"
        value="I"
        (onChange)="loadData(false)"
        [(ngModel)]="filters.degrees_of_care"
        name="degrees_of_care"
      ></p-checkbox>
      <p-checkbox
        label="PG II"
        value="II"
        (onChange)="loadData(false)"
        [(ngModel)]="filters.degrees_of_care"
        name="degrees_of_care"
      ></p-checkbox>
      <p-checkbox
        label="PG III"
        value="III"
        (onChange)="loadData(false)"
        [(ngModel)]="filters.degrees_of_care"
        name="degrees_of_care"
      ></p-checkbox>
      <p-checkbox
        label="PG IV"
        value="IV"
        (onChange)="loadData(false)"
        [(ngModel)]="filters.degrees_of_care"
        name="degrees_of_care"
      ></p-checkbox>
      <p-checkbox
        label="PG V"
        value="V"
        (onChange)="loadData(false)"
        [(ngModel)]="filters.degrees_of_care"
        name="degrees_of_care"
      ></p-checkbox>
    </span>

    <div class="content-header-buttons">
      <p-treeSelect
        [filter]="true"
        [filterInputAutoFocus]="true"
        (onNodeSelect)="loadData(false)"
        (onNodeUnselect)="loadData(false)"
        (onClear)="loadData(false)"
        class="proofs-treeselect"
        [style]="{ margin: '0 0 0 -1px' }"
        selectionMode="checkbox"
        [showClear]="true"
        inputId="cities"
        name="cities"
        placeholder="Bezirke"
        [(ngModel)]="selectedCities"
        [options]="counties"
        scrollHeight="500px"
      ></p-treeSelect>

      <p-multiSelect
        inputId="month"
        [options]="monthOptions"
        [showHeader]="false"
        name="month"
        placeholder="Monat"
        [(ngModel)]="filters.months"
        (onChange)="loadData(false)"
        scrollHeight="500px"
        [style]="{ width: '150px', zIndex: '10', margin: '0 0 0 -1px' }"
      ></p-multiSelect>

      <p-dropdown
        inputId="year"
        name="year"
        placeholder="Jahr"
        [showClear]="true"
        (onChange)="loadData(false)"
        [options]="yearOptions"
        [(ngModel)]="filters.year"
        [style]="{ width: '120px', margin: '0 0 0 -1px' }"
      ></p-dropdown>

      <div
        class="table-search-container"
        [class.searchfield-active]="globalFilterValue != ''"
      >
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [(ngModel)]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading">
  <div
    class="content-body"
    [class.content-loading]="contentLoading"
    *ngIf="consultings.length == 0"
  >
    <span class="content-no-message"
      >Keine offenen Beratungseinsätze vorhanden</span
    >
  </div>

  <div
    [class.content-loading]="contentLoading"
    class="content-body content-body-full-height-with-tabs without-padding"
    *ngIf="consultings.length > 0"
  >
    <p-table
      #dt
      [value]="consultings"
      [globalFilterFields]="[
        'patient.id',
        'patient.first_name',
        'patient.last_name',
        'patient.full_name',
        'patient.zipcode',
        'invoice.invoice_number',
      ]"
      [(first)]="firstPage"
      [rowHover]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Beratungseinsätze"
      [rows]="50"
      stateStorage="local"
      stateKey="state-consultings-list-appointments"
      [paginator]="true"
      scrollHeight="100%"
      [scrollable]="true"
      sortMode="multiple"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <!--          <th style="min-width: 200px" pSortableColumn="status">-->
          <!--            Status <p-sortIcon field="status"></p-sortIcon>-->
          <!--          </th>-->
          <th pSortableColumn="patient.last_name">
            Patient <p-sortIcon field="patient.last_name"></p-sortIcon>
          </th>
          <th style="max-width: 100px" pSortableColumn="patient.degree_of_care">
            PG <p-sortIcon field="patient.degree_of_care"></p-sortIcon>
          </th>
          <th>Telefon</th>
          <th style="max-width: 100px" pSortableColumn="patient.zipcode">
            PLZ <p-sortIcon field="patient.zipcode"></p-sortIcon>
          </th>
          <th style="min-width: 200px" pSortableColumn="patient.city">
            Ort <p-sortIcon field="patient.city"></p-sortIcon>
          </th>
          <th
            style="min-width: 200px"
            pSortableColumn="patient.care_insurance.name"
          >
            Pflegekasse
            <p-sortIcon field="patient.care_insurance.name"></p-sortIcon>
          </th>
          <th pSortableColumn="date">
            für <p-sortIcon field="date"></p-sortIcon>
          </th>
          <th pSortableColumn="sent_type">
            Übergabe per <p-sortIcon field="sent_type"></p-sortIcon>
          </th>
          <th style="max-width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-consulting>
        <tr>
          <!--          <td style="min-width: 200px">-->
          <!--            <span class="p-tag p-tag-rounded p-tag-{{ consulting.status }}">{{-->
          <!--              consulting.status_value-->
          <!--            }}</span>-->

          <!--            <span-->
          <!--              (click)="openMediaFromUuid(consulting.media[0].uuid)"-->
          <!--              style="margin: 0 0 0 5px"-->
          <!--              *ngIf="consulting.media[0]"-->
          <!--              class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"-->
          <!--              ><i class="pi pi-file-pdf"></i>Nachweis</span-->
          <!--            >-->

          <!--            <span-->
          <!--              (click)="openInvoiceDialog(consulting.invoice)"-->
          <!--              style="margin: 0 0 0 5px"-->
          <!--              *ngIf="consulting.invoice && !consulting.invoice.is_storno"-->
          <!--              class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"-->
          <!--              ><i class="pi pi-euro"></i>Rechnung:-->
          <!--              {{ consulting.invoice.status_text }}</span-->
          <!--            >-->

          <!--            <span-->
          <!--              (click)="openInvoiceDialog(consulting.invoice)"-->
          <!--              style="margin: 0 0 0 5px"-->
          <!--              [pTooltip]="consulting.invoice.storno_history?.manual_description"-->
          <!--              *ngIf="consulting.invoice && consulting.invoice.is_storno"-->
          <!--              class="p-tag p-tag-rounded p-tag-outline p-tag-clickable"-->
          <!--              ><i class="pi pi-euro"></i>Rechnung: Storno</span-->
          <!--            >-->
          <!--          </td>-->
          <td>
            <a routerLink="/patients/{{ consulting.patient_id }}"
              >{{ consulting.patient.last_name }},
              {{ consulting.patient.first_name }}</a
            >
          </td>
          <td style="max-width: 100px">
            {{ consulting.patient.degree_of_care }}
          </td>
          <td>
            <div style="display: flex; flex-direction: column">
              <span *ngIf="consulting.patient.phone_1">
                <a
                  (click)="
                    phoneCallService.createCalling(consulting.patient.phone_1)
                  "
                  href="tel:{{ consulting.patient.phone_1 }}"
                  >{{ consulting.patient.phone_1 }}</a
                >
                <i
                  style="margin: 0 0 0 5px; font-size: 13px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="consulting.patient.phone_1_info"
                  [pTooltip]="consulting.patient.phone_1_info"
                ></i>
                <br />
              </span>
              <span *ngIf="consulting.patient.phone_2">
                <a
                  (click)="
                    phoneCallService.createCalling(consulting.patient.phone_2)
                  "
                  href="tel:{{ consulting.patient.phone_2 }}"
                  >{{ consulting.patient.phone_2 }}</a
                >
                <i
                  style="margin: 0 0 0 5px; font-size: 13px"
                  class="pi pi-info-circle color-gray"
                  *ngIf="consulting.patient.phone_2_info"
                  [pTooltip]="consulting.patient.phone_2_info"
                ></i>
                <br />
              </span>
            </div>
          </td>
          <td style="max-width: 100px">
            {{ consulting.patient.zipcode }}
          </td>
          <td style="min-width: 200px">
            <div class="text-center-container">
              <i
                style="font-size: 14px"
                class="pi pi-info-circle color-gray"
                [pTooltip]="consulting.patient.full_address"
              ></i>
              {{ consulting.patient.city }}
            </div>
          </td>
          <td style="min-width: 200px">
            {{ consulting.patient.care_insurance.name }}
          </td>
          <td>
            <div style="display: flex; align-items: center; gap: 5px">
              {{ consulting.date_string }}
              <i
                class="pi pi-history color-gray"
                *ngIf="consulting.is_catch_up"
                pTooltip="Nachholtermin"
              ></i>
            </div>
          </td>
          <td>
            {{ consulting.sent_type_string }}
          </td>
          <td class="table-action" style="max-width: 100px">
            <i
              *ngIf="
                consulting.status !== 'DELETED' &&
                authService.can('Kunde.Beratungseinsätze erstellen')
              "
              pTooltip="Bearbeiten"
              (click)="openConsultingEditDialog(consulting)"
              tooltipPosition="left"
              class="pi pi-pencil with-margin-right"
            ></i>

            <i
              *ngIf="
                consulting.status !== 'DELETED' &&
                consulting.status !== 'OPEN' &&
                authService.can('Kunde.Beratungseinsätze erstellen')
              "
              (click)="openConsultingConfirmDialog(consulting)"
              pTooltip="Bestätigen"
              tooltipPosition="left"
              class="pi pi-check"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
