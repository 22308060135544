import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { CarService } from '../../../../services/car.service'
import { CarListModel } from '../../../../models/car/car-list.model'
import { AuthService } from '../../../../services/auth.service'

@Component({
  selector: 'app-cars-list',
  templateUrl: './cars-list-active.component.html',
})
export class CarsListActiveComponent implements OnInit {
  @ViewChild('dt') table: ElementRef | any = null

  public globalFilterValue = ''
  public contentLoading = true
  public loading = false
  public firstPage = 0

  public withoutOthers = false

  public cars: CarListModel[] = []

  constructor(
    public authService: AuthService,
    private carService: CarService
  ) {}

  ngOnInit(): void {
    this.initFilters()

    this.loadCars()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  private initFilters(): void {
    if (localStorage.getItem('cars-list-without-others') !== null) {
      this.withoutOthers = JSON.parse(
        localStorage.getItem('cars-list-without-others') || 'false'
      )
    }
  }

  public loadCars(withLoading = true): void {
    localStorage.setItem(
      'cars-list-without-others',
      this.withoutOthers.toString()
    )

    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.carService
      .list(this.withoutOthers)
      .subscribe((data: CarListModel[]) => {
        this.cars = data

        this.loading = false
        this.contentLoading = false

        setTimeout(() => {
          if (this.table) {
            const totalItems = this.table._totalRecords

            this.globalFilterValue = this.table.filters.global?.value ?? ''

            if (
              this.table._first > totalItems ||
              this.table._rows > totalItems
            ) {
              this.firstPage = 0
            }
          }
        })
      })
  }
}
