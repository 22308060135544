<div *ngIf="car.notice" class="notice-wrap">
  <i class="pi pi-paperclip notice-wrap-drag-handle notice-icon"></i>
  <div (click)="openNoticeDialog()" class="notice-text">
    {{ car.notice }}
  </div>
</div>

<div class="content-actions">
  <h2>
    {{ car.brand }} {{ car.model }} {{ car.license_plate }}
    <i *ngIf="car.deleted_at" class="p-tag p-tag-danger p-tag-rounded"
      >Deaktiviert</i
    >
  </h2>

  <a routerLink="/cars/{{ car.id }}/edit" *ngIf="car.id">
    <button
      pButton
      label="Bearbeiten"
      class="p-button-text"
      type="button"
      icon="pi pi-pencil"
      iconPos="right"
    ></button>
  </a>
</div>

<header class="content-header-tabs" [class.disabled]="!car.id">
  <div class="arrow-navigation">
    <i
      tooltipStyleClass="tooltip-wider"
      style="padding: 10px 15px"
      class="pi pi-chevron-left"
      [pTooltip]="getNextCarName('previous') + '\n(ALT + LINKS)'"
      tooltipPosition="left"
      (click)="goToNextCar('previous')"
    ></i>
    <i
      tooltipStyleClass="tooltip-wider"
      style="padding: 10px 15px"
      class="pi pi-chevron-right"
      [pTooltip]="getNextCarName('next') + '\n(ALT + RECHTS)'"
      tooltipPosition="left"
      (click)="goToNextCar('next')"
    ></i>

    <p-dropdown
      [style]="{ width: '240px' }"
      scrollHeight="400px"
      [filter]="true"
      inputId="cars"
      name="cars"
      placeholder="Bitte auswählen"
      [resetFilterOnHide]="true"
      [options]="cars"
      [(ngModel)]="currentCarId"
      optionValue="id"
      appendTo="body"
      optionLabel="full_name"
      (onChange)="navigateToCar($event)"
    ></p-dropdown>
  </div>

  <a
    routerLink="/cars/{{ car.id }}"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Allgemeines</a
  >

  <a
    *ngIf="authService.can('PKW.Werkstatttermine verwalten')"
    routerLink="/cars/{{ car.id }}/garage"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Werkstatt</a
  >

  <a
    *ngIf="authService.can('PKW.Kostenfaktor verwalten')"
    routerLink="/cars/{{ car.id }}/costs"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Kostenfaktor</a
  >

  <a
    routerLink="/cars/{{ car.id }}/damage"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Schadensdokumentation</a
  >

  <a
    routerLink="/cars/{{ car.id }}/documents"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Dokumente</a
  >

  <a
    routerLink="/cars/{{ car.id }}/history"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    >Historie</a
  >
</header>

<router-outlet></router-outlet>
