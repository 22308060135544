<app-loader [loading]="loading"></app-loader>

<div class="content-actions limit-width">
  <h2>
    <i class="tio">security_warning_outlined</i>
    {{ isEditMode ? "Rolle bearbeiten" : "Neue Rolle" }}
  </h2>
</div>

<form #form="ngForm">
  <ng-container *ngIf="!loading">
    <div class="content new-content-container limit-width">
      <header class="content-header">
        <span>Rolle</span>
      </header>

      <div class="content-body">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="name">Name *</label>
            <input
              id="name"
              name="name"
              [(ngModel)]="values.name"
              type="text"
              pInputText
              #name="ngModel"
              required
              [class.p-invalid]="name.invalid && (name.dirty || name.touched)"
            />
            <div
              *ngIf="name.invalid && (name.dirty || name.touched)"
              class="p-invalid"
            >
              <div *ngIf="name.errors?.required">
                <small>Bitte geben Sie einen Namen ein</small>
              </div>
            </div>
          </div>
        </div>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12">
            <label for="comment">Bemerkung</label>
            <textarea
              id="comment"
              name="comment"
              [(ngModel)]="values.comment"
              pInputTextarea
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div
      class="content new-content-container limit-width"
      *ngFor="let item of permissions | keyvalue"
    >
      <header class="content-header">
        <span>{{ item.key }}</span>
      </header>
      <div class="content-body">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-4" *ngFor="let permission of item.value">
            <div class="p-formgroup-inline">
              <div class="p-field-checkbox">
                <p-checkbox
                  [label]="permission.name"
                  [value]="permission.id"
                  [(ngModel)]="values.permission_ids"
                  [name]="permission.name + permission.id"
                ></p-checkbox>

                <i
                  *ngIf="permission.comment"
                  class="pi pi-info-circle"
                  [pTooltip]="permission.comment"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content-footer limit-width sticky" *ngIf="!loading">
      <span></span>

      <button
        (click)="save()"
        pButton
        [label]="isEditMode ? 'Änderungen speichern' : 'Rolle erstellen'"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>
    </div>
  </ng-container>
</form>
