<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body" *ngIf="!loading">
    <div class="dialog-detail-information-body">
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Tag</span></div>
        <div class="dialog-detail-value">
          <div>{{ data.date }} ({{ data.day }})</div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Einsatz</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ data.data.real_from_h }} - {{ data.data.real_to_h }} ({{
              data.data.real_hours_as_float
            }})
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key"><span>Patient</span></div>
        <div class="dialog-detail-value">
          <div>
            {{ data.data.patient.first_name }} {{ data.data.patient.last_name }}
          </div>
        </div>
      </div>
      <div class="dialog-detail-information">
        <div class="dialog-detail-key" style="align-items: center">
          <span>Zusätzliche KM</span>
        </div>
        <div class="dialog-detail-value">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <div *ngIf="data.readonly">{{ values.mileage }}</div>
              <p-inputNumber
                *ngIf="!data.readonly"
                inputId="mileage"
                name="mileage"
                locale="de-DE"
                mode="decimal"
                [minFractionDigits]="1"
                [maxFractionDigits]="1"
                [step]="0.1"
                [min]="0"
                buttonLayout="horizontal"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
                [showButtons]="true"
                [(ngModel)]="values.mileage"
              ></p-inputNumber>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form-body without-padding" *ngIf="!loading">
    <div class="dialog-comments">
      <div
        class="dialog-comment"
        *ngFor="let comment of data.data.caregiver_appointment_trip?.comments"
      >
        <!-- Den Kommentar darf nur der Ersteller bearbeiten bzw. ein Super Admin -->
        <!--            <i-->
        <!--              *ngIf="-->
        <!--                currentUser?.id === comment.user_id || authService.isSuperAdmin-->
        <!--              "-->
        <!--              class="pi pi-pencil"-->
        <!--              pTooltip="Bearbeiten"-->
        <!--              (click)="openEditMode(comment)"-->
        <!--            ></i>-->
        <strong>{{ comment.comment }}</strong>
        <span *ngIf="comment.created_from.caregiver"
          >{{ comment.created_from.caregiver.first_name }}
          {{ comment.created_from.caregiver.last_name }} am
          {{ comment.created_at | date: "dd.MM.y, H:mm" }} Uhr</span
        >

        <span *ngIf="comment.created_from.user_system"
          >{{ comment.created_from.user_system.first_name }}
          {{ comment.created_from.user_system.last_name }} am
          {{ comment.created_at | date: "dd.MM.y, H:mm" }} Uhr</span
        >
      </div>
    </div>
  </div>
  <div
    class="dialog-form-footer dialog-form-footer-textarea"
    *ngIf="!loading && !data.readonly"
  >
    <textarea
      *ngIf="!editComment"
      id="comment"
      name="comment"
      placeholder="Kommentar..."
      [(ngModel)]="values.comment"
      [rows]="2"
      [autofocus]="true"
      [required]="true"
      pInputTextarea
    ></textarea>

    <!--    <textarea-->
    <!--      *ngIf="editComment"-->
    <!--      id="comment_edit"-->
    <!--      name="comment_edit"-->
    <!--      [(ngModel)]="values.comment"-->
    <!--      [rows]="2"-->
    <!--      [required]="true"-->
    <!--      pInputTextarea-->
    <!--      #comment_edit="ngModel"-->
    <!--      [class.p-invalid]="-->
    <!--        comment_edit.invalid && (comment_edit.dirty || comment_edit.touched)-->
    <!--      "-->
    <!--    ></textarea>-->

    <div class="dialog-form-footer-actions">
      <i></i>
      <!--      <button-->
      <!--        *ngIf="editComment"-->
      <!--        pButton-->
      <!--        label="Abbrechen"-->
      <!--        class="p-button-text button-without-padding"-->
      <!--        type="button"-->
      <!--        (click)="cancelEditMode()"-->
      <!--        icon="pi pi-times"-->
      <!--      ></button>-->

      <button
        *ngIf="!editComment"
        (click)="save()"
        pButton
        label="KM eintragen"
        type="button"
        [disabled]="submitted || !values.comment"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>

      <!--      <button-->
      <!--        *ngIf="editComment"-->
      <!--        (click)="update()"-->
      <!--        pButton-->
      <!--        label="Kommentar bearbeiten"-->
      <!--        type="button"-->
      <!--        [disabled]="submitted"-->
      <!--        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"-->
      <!--      ></button>-->
    </div>
  </div>
</form>
